import React from 'react';
import appTheme from "../../../assets/appTheme";
import {isEmpty} from "../../../utils/validations";
import DLText from "../../../core/components/DLText/DLText";

const RenderKYCStatusBadge=(props)=> {
    const  {status}=props;
    let assignColor='';
    let icon='';
    let selectedStatus = status;
    let type = props?.type;

    if(type === "DOT"){
        if (selectedStatus==="KRA Validated" || selectedStatus==="KRA Verified" || selectedStatus === 'Modification Validated')
        {
            assignColor="status-dot success";
        }
        else if (selectedStatus==="Rejected")
        {
            assignColor="status-dot danger";
        }
        else {
            assignColor="status-dot grayDark";
            selectedStatus = (!isEmpty(selectedStatus)) ? selectedStatus : 'Not Found';
            return (
                <div className="d-flex align-items-center">
                    <span className={`${assignColor} my-1 ms-0 pe-2`}/>
                    <span>
                        <DLText id={'status'}
                                fontWeight={"semi-bold"}
                                marginBottom={"none"}
                                text={selectedStatus}/>
                    </span>
                </div>
            );
        }
        return (
            <div className="d-flex align-items-center">
                <span className={`${assignColor} my-1 ms-0 pe-2`}/>
                <span>
                        <DLText id={'status'}
                                fontWeight={"semi-bold"}
                                marginBottom={"none"}
                                text={selectedStatus}/>
                </span>
            </div>
        );
    }else{
        if (selectedStatus==="KRA Validated" || selectedStatus==="KRA Verified" || selectedStatus === 'Modification Validated')
        {
            icon=<i className="fa-solid fa-check ps-2" style={{color: appTheme.appColor.white}}/>;
            assignColor="badge-green";
        }
        else if (selectedStatus==="Rejected")
        {
            assignColor="badge-red";
            icon=<i className="fa-solid fa-xmark px-2" style={{color: appTheme.appColor.white}}/>;
        }
        else {
            assignColor="badge-grayDark";
            icon='';
            selectedStatus = (!isEmpty(selectedStatus)) ? selectedStatus : 'Not Found';
            return (
                <span style={{maxWidth: "max-content"} } className={`${assignColor} my-1 mx-0`}>{selectedStatus} {icon}</span>
            );
        }
        return (
            <span style={{maxWidth: 'max-content', minWidth: 130}} className={`${assignColor} my-1 mx-0`}>{selectedStatus} {icon}</span>
        );
    }
};

export default RenderKYCStatusBadge;