import React from 'react';
import moment from "moment";

import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {getFullName} from "../../../../ClientsHelper";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {isCorporateUser} from "../../../../../utils/helper";
import {cellConfig, cellConfigMobile, headingData} from "../../clientListingModel";

import DLText from "../../../../../core/components/DLText/DLText";
import DLInputTextField from "../../../../../core/components/DLInputTextField/DLInputTextField";
import DLTable from "../../../../../core/components/DLTable/DLTable";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLDropDown from "../../../../../core/components/DLDropdown/DLDropDown";

import emptyFilterFilled from "../../../../../assets/img/appIcons/emptyFilterFilled.png";
import emptyFilter from "../../../../../assets/img/appIcons/emptyFilter.png";
import filterDot from "../../../../../assets/img/appIcons/filterDot.png";
import emptyLeads from "../../../../../assets/img/empty-leads.png";
import FilterBadgeView from "../../../../../leads/components/filterBadge/FilterBadgeView";

const ListingForCRMView = (props) => {
    const {
        UIState, clientList, isShowLoader, isXSView, clientsFilter, onChangeFilter, isMobileView, listingCount,
        rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, getPaginationNextButtonStatus, skipCount,
        resetClientListing, getCategory, accountTypesDropdownValues, getAccountTypeById, isSMView, isApiInProgress
    } = props;

    const {keyword, type} = clientsFilter;

    function isFiltersApplied(filters) {
        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (Array.isArray(filters[key])) {
                    if (filters[key].length > 0) {
                        return true;
                    }
                } else if (filters[key] !== '' && key !== 'skipCount' && key !== 'limitCount' && key !== 'page' && key !== 'rowsPerPage') {
                    return true;
                }
            }
        }
        return false;
    }

    const getNames = (fields, categoryId) => {
        let applicantNames;
        let isCorporateClient = isCorporateUser(fields?.accountType)

        if (isCorporateClient) {
            applicantNames = fields?.corporate?.name
        } else {
            const names = fields?.accountHolders?.reduce((acc, accHolder) => {
                if (accHolder?.holderType !== 'GUARDIAN') {
                    acc.push(getFullName(accHolder?.person?.firstName, accHolder?.person?.middleName, accHolder?.person?.lastName));
                }
                return acc;
            }, []);

            applicantNames = names?.map((name) => {
                return name
            }).join(', ');
        }

        return <div
            className={isXSView ? '' : 'pt-2 pb-1'}
            style={{
                width: isXSView ? "" : isSMView ? "250px" : "400px",
            }}
        >
            <DLText id={''}
                    text={applicantNames}
                    fontSize={"xs"}
                    fontWeight={"normal"}
            />
            <DLText id={''}
                    text={getAccountTypeCustomUI(fields)}
                    fontWeight={"normal"}
                    isInline={true}
                    fontSize={"xs"}
                    fontColor={"grayDark"}
            />
            <DLText id={''}
                    text={' - ' + fields?.accountIINDetails?.IIN}
                    fontWeight={"normal"}
                    isInline={true}
                    fontSize={"xs"}
                    fontColor={"grayDark"}
            />
            {(isXSView && !isEmpty(categoryId)) && (categoryId !== '-') &&
                <DLText id={''}
                        text={' - ' + categoryId}
                        isInline={true}
                        fontSize={'xs'}
                        fontWeight={'normal'}
                        fontColor={"grayDark"}
                />
            }
        </div>;
    };

    const getClientListingDetailsMobile = (fields) => {
        const categoryId = isEmpty(fields?.accountCategorization?.categoryId) ? '-' : getCategory(fields?.accountCategorization?.categoryId);
        return (<div className="py-3">
            {getNames(fields, categoryId)}
        </div>)
    };

    const getAccountTypeCustomUI = (fields) => {
        if (isEmpty(fields?.accountType)) {
            return '-';
        }
        return getAccountTypeById(fields?.accountType) + (isXSView ? ' A/c' : ' Account');
    };

    const getFormattedTableData = (clientList) => {
        let tableData = [];
        const {isXSView} = props;
        if (isEmpty(clientList)) {
            return tableData
        }
        clientList.map((fields) => {
            let clientsListingFields = [];
            let clientsListingDetails = {};
            if (!isXSView) {
                clientsListingFields.push({
                    isCustomUI: true,
                    customUI: getNames(fields)
                });
                clientsListingFields.push(isEmpty(fields?.accountIINDetails?.activationDate) ? "-" : moment(fields?.accountIINDetails?.activationDate).format("DD-MM-YYYY"));

            } else {
                clientsListingFields.push({
                    isCustomUI: true,
                    customUI: getClientListingDetailsMobile(fields)
                });
                clientsListingFields.push(isEmpty(fields?.accountIINDetails?.activationDate) ? "-" : moment(fields?.accountIINDetails?.activationDate).format("DD-MM-YYYY"));
            }

            clientsListingDetails.id = fields.id;
            clientsListingDetails.data = clientsListingFields;
            tableData.push(clientsListingDetails);
        });

        return tableData

    };

    const renderDesktopTable = () => {
        const {isShowFilterMobile, redirectToDetailsScreen} = props;
        let tableData = getFormattedTableData(clientList);
        if (isEmpty(clientList)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center panel'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 360px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 345px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'auto'}
                    isRowClickable={true}
                    onRowClick={(id) => {
                        redirectToDetailsScreen(id)
                    }}
                    isDynamicHeight={true}
                    cellConfig={cellConfig}
                    headerData={headingData}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderCount = () => {
        const {isXSView, handleRefreshButton} = props;

        if (isXSView) {
            return (
                <div className='page-container mt-3 border-bottom'>
                    {!isEmpty(listingCount) &&
                        <div className={'d-flex align-items-center pb-3'}>
                            <DLText id={''}
                                    isInline={true}
                                    fontColor={'grayDark'}
                                    fontSize={'sm'}
                                    marginBottom={'none'}
                                    fontWeight={'semi-bold'}
                                    text={'Showing ' + (listingCount) + ' records'}/>
                            <div className={'d-flex align-items-center mx-2'}
                                 onClick={() => {
                                     handleRefreshButton()
                                 }}>
                                <i className="fa-solid fa-rotate-right cursor"/>
                            </div>
                        </div>}
                </div>)
        }
        return (
            <div className={isMobileView? 'd-flex align-items-center py-3' : 'd-flex align-items-center pb-3'}>
                {!isEmpty(listingCount) &&
                <div className={'d-flex align-items-center '}>
                    <DLText id={''}
                            isInline={true}
                            fontColor={'grayDark'}
                            fontSize={'sm'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            text={'Showing ' + (listingCount) + ' records'}/>
                    <div className={'d-flex align-items-center mx-2'}
                         onClick={() => {
                             handleRefreshButton()
                         }}>
                        <i className="fa-solid fa-rotate-right cursor"/>
                    </div>
                </div>}
            </div>)

    };

    const renderMobileTable = () => {
        const {isShowFilterMobile, redirectToDetailsScreen, badgeData} = props;
        let tableData = getFormattedTableData(clientList);

        if (isEmpty(clientList)) {
            return (
                <div className='d-flex align-items-center justify-content-center empty-records'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100 pagination-container">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? !isEmpty(badgeData.data) && (badgeData.data !== '-') ? 'calc(100dvh - 460px)' : 'calc(100dvh - 432px)' : 'calc(100dvh - 332px)'}
                    tableMinHeight={isShowFilterMobile ? !isEmpty(badgeData.data) && (badgeData.data !== '-') ? 'calc(100dvh - 460px)' : 'calc(100dvh - 432px)' : 'calc(100dvh - 332px)'}
                    cellConfig={cellConfigMobile}
                    headerData={headingData}
                    tableData={tableData}
                    isRowClickable={true}
                    onRowClick={(id) => {
                        redirectToDetailsScreen(id)
                    }}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderClientsTable = () => {
        return (
            <div className={'w-100' + ((isMobileView && !isXSView) ? '  page-container' : '')}>
                {renderCount()}
                {isXSView ?
                    renderMobileTable()
                    :
                    <div>
                        {renderDesktopTable()}
                    </div>
                }
            </div>
        )
    };

    const renderCategoryAndAccountTypeFilter = () => {
        return (
            <div className=''>
                <div className={"w-100"} >
                    <DLDropDown
                        id={"accountType-filter"}
                        label={'Account Type'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={type[0]}
                        minWidth={"100%"}
                        options={accountTypesDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('type', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader  || isApiInProgress}/>);
    };

    const renderFilter = () => {
        const {badgeData} = props;
        return (
            <div>
                <div className='d-flex'>
                    <div className='search-input me-3 mb-3' style={{flex: 1.8}}>
                        <DLInputTextField
                            id={'lead-list-search-filter'}
                            label={'Search'}
                            labelFontSize={'sm'}
                            labelFontColor={'grayDark'}
                            marginBottom={"none"}
                            rows={1}
                            styleType={"search"}
                            onChangeValue={(e) => {
                                onChangeFilter('keyword', e)
                            }}
                            value={isEmpty(keyword) ? '' : keyword}
                            placeholder={'Search by Name or IIN'}
                            isSearchable={true}
                            size="small"
                            inputHeight={'sm'}
                            inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                    <div className='select-filter me-3 mb-3' style={{flex: 1}}>
                        <DLDropDown
                            id={"accountType-filter"}
                            label={'Account Type'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={type[0]}
                            minWidth={"100%"}
                            options={accountTypesDropdownValues}
                            onSelect={(e) => {
                                onChangeFilter('type', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                    {!isEmpty(badgeData.data) && (badgeData.data !== '-') &&
                    <div className='d-flex align-items-center mt-1'>
                        <FilterBadgeView
                            badgeData={badgeData}
                            onClose={() => {
                                onChangeFilter(badgeData.filterKey, (badgeData.filterKey === 'title' ? '' : 'ALL'))
                                onChangeFilter('title', '')
                            }}
                        />
                    </div>}
                </div>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between pb-1'>
                <div className='page-header pt-3'>
                    Clients
                </div>
            </div>
        )
    };

    const renderUi = () => {
        return <div className={'h-100 d-flex justify-content-center bg-light'}
                    style={{minHeight: 'calc(100dvh - 75px)'}}>
            <div className={'w-100'}>
                {renderHeader()}
                <div className={'page-container'}>
                    {renderFilter()}
                </div>
                <div className='page-container'
                     style={{minHeight: 'calc(100dvh - 196px)'}}>
                    {renderClientsTable()}
                </div>
            </div>
        </div>
    };

    const renderFilterIcon = () => {
        const {isShowFilterMobile} = props;
        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        if (isFiltersApplied(clientsFilter)) {
            return (
                <div className='icon-container'>
                    <img src={filterIcon} alt={''} height={14} width={14}/>
                    <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
                </div>
            );
        }

        return <img src={filterIcon} alt={''} height={14} width={14}/>;
    };

    const renderFilterMobile = () => {
        const {isShowFilterMobile, handleToggleMobileFilter, badgeData} = props;

        return <div>
            <div className='d-flex align-items-center justify-content-between gap-3 page-container '>
                <div className='search-input' style={{flex: 8}}>
                    <DLInputTextField id={'leads-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      styleType={"search"}
                                      onChangeValue={(e) => {
                                          onChangeFilter('keyword', e)
                                      }}
                                      value={keyword}
                                      placeholder={'Search by Name or IIN'}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"text"}
                                      disableHelperMessageHeight={true}

                    />
                </div>
                <div className="d-flex align-items-center" style={{marginBottom: '5px'}}>
                    <DLButton
                        id={'filter-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        startIcon={renderFilterIcon()}/>

                    {isFiltersApplied(clientsFilter) &&
                    <div>
                        <i className="fa-solid fa-xmark cursor ms-2 "
                           onClick={() => resetClientListing()}
                           style={{fontSize: 18}}/>
                    </div>
                    }
                </div>
            </div>
            {isShowFilterMobile &&
            <div className='bg-light mt-2' style={{paddingTop: 10, paddingBottom: 10}}>
                <div className='page-container'>
                    <div className='my-1'>{renderCategoryAndAccountTypeFilter()}</div>
                    {!isEmpty(badgeData.data) && (badgeData.data !== '-') &&
                        <div className='d-flex align-items-center' style={{marginTop: '6px'}}>
                            <FilterBadgeView
                                badgeData={badgeData}
                                onClose={() => {
                                    onChangeFilter(badgeData.filterKey, (badgeData.filterKey === 'title' ? '' : 'ALL'))
                                    onChangeFilter('title', '')
                                }}
                            />
                        </div>}
                </div>
            </div>
            }
        </div>
    };

    const emptyList = () => {
        return <div className='d-flex justify-content-center align-items-center gray-lg-text'
                    style={{minHeight: 'calc(100dvh - 132px)', overflow: 'hidden', fontSize: '16px'}}>
            <div className='text-center'>
                <img src={emptyLeads} alt={''}/>
                <DLText id={'create-first-lead'}
                        text={'There are no clients available.'}
                        marginBottom={'xs'}
                />
                <DLText id={'create-first-lead'}
                        text={'This data will be available once an onboarding is completed.'}
                        marginBottom={'md'}
                />
            </div>
        </div>
    };

    if (isEmpty(UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    if (UIState === APP_CONST.CONTENT_UNAVAILABLE) {
        return (
            <div className='page-container'>
                {emptyList()}
            </div>
        );
    }

    if (isMobileView) {
        return (
            <div>
                <div className={'w-100'} >
                    {renderHeader()}
                    {renderFilterMobile()}
                    {renderClientsTable()}
                    {renderLoader()}
                </div>
            </div>
        );
    }

    return (
        <div>
            {renderUi()}
            {renderLoader()}
        </div>

    );
};

export default ListingForCRMView;