import React from 'react';
import DLText from "../../../../../core/components/DLText/DLText";
import Kyc from "../KYC/KYC"
import {isEmpty} from "../../../../../utils/validations";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import appTheme from "../../../../../assets/appTheme";

const AccountDetailsView = (props) => {

    const {isMobileView, isShowCompanyDetails, accountDetailsUIState, isXSView} = props;

    const renderComapnyInformation = () => {

        const {primaryEmail, primaryPhoneNumber, address, getStateName, companyCity,
            companyDistrict, companyPinCode, companyState, companyCountry, getCountryName} = props;

        return (
            <div className={isMobileView ? 'page-container' : 'border border-rounded bg-white my-4'}>
                <div className={isMobileView ? '' : 'p-3'}>
                    <div className={isMobileView ? 'row m-0 p-0 pt-4 mt-3' : 'row m-0 p-0 pt-3'}>
                        <div className={isXSView ? 'col-12' : 'col-5'}>
                            <div style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                <DLText
                                    id={'company-address'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Corporate Address'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'lead-details-assigned'}
                                    type={'normal'}
                                    text={address}
                                />
                            </div>
                        </div>
                        <div className={isXSView ? 'col-12' : 'col-4'}>
                            <div>
                                <DLText
                                    id={'city'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'City'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'city-value'}
                                    type={'normal'}
                                    text={companyCity}
                                />
                            </div>
                        </div>
                        <div className={isXSView ? 'col-12' : 'col-3'}>
                            <div>
                                <DLText
                                    id={'district'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'District'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'district-value'}
                                    type={'normal'}
                                    text={companyDistrict}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row m-0 p-0 pt-3 pb-3'>
                        <div className={isXSView ? 'col-12' : 'col-5'}>
                            <div>
                                <DLText
                                    id={'pinCode-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'PinCode'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'pinCode-value'}
                                    type={'normal'}
                                    text={companyPinCode}
                                />
                            </div>
                        </div>
                        <div className={isXSView ? 'col-12' : 'col-4'}>
                            <div>
                                <DLText
                                    id={'state-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'State'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'state-value'}
                                    type={'normal'}
                                    text={getStateName(companyState)}
                                />
                            </div>
                        </div>
                        <div className={isXSView ? 'col-12' : 'col-3'}>
                            <div>
                                <DLText
                                    id={'country-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Country'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'country-value'}
                                    type={'normal'}
                                    text={getCountryName(companyCountry)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={isMobileView ? 'row m-0 p-0 pt-3 pb-4 mb-3' : 'row m-0 p-0 pt-3 pb-3'}>
                        <div className={isXSView ? 'col-12' : 'col-5'}>
                            <div>
                                <DLText
                                    id={'email-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Email'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'primaryEmail'}
                                    type={'normal'}
                                    text={
                                        isEmpty(primaryEmail) ? '-' :
                                            <a href={"mailto:" + primaryEmail}
                                               className='link-primary'
                                               style={{
                                                   fontSize: '14px',
                                                   wordBreak: 'break-word',
                                                   overflowWrap: 'break-word'
                                               }}>{primaryEmail}</a>
                                    }
                                    isInline={true}
                                />
                            </div>
                        </div>
                        <div className={isXSView ? 'col-12' : 'col-4'}>
                            <div>
                                <DLText
                                    id={'primary-phone-number'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Primary Mobile'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'primaryPhoneNumber'}
                                    type={'normal'}
                                    text={isEmpty(primaryPhoneNumber) ? '-' :
                                        <a href={"tel:" + primaryPhoneNumber}
                                           className='link-primary '
                                           style={{fontSize: '14px'}}>{primaryPhoneNumber}</a>}
                                    isInline={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderMobileCompanyInformation = () => {
        const {
            primaryEmail,
            primaryPhoneNumber,
            address,
            getStateName,
            companyCity,
            companyDistrict,
            companyPinCode,
            companyState,
            companyCountry,
            getCountryName,
        } = props;

        return (
            <div className="container p-3">
                <div className={"row"}>
                    <div className="col-12">
                        <div style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                            <DLText
                                id={"company-address"}
                                type={"normal"}
                                fontSize={"xs"}
                                text={"Corporate Address"}
                                fontColor={"grayDark"}
                            />
                            <DLText
                                id={"lead-details-assigned"}
                                type={"normal"}
                                text={address}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 pt-3">
                        <DLText
                            id={"city"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"City"}
                            fontColor={"grayDark"}
                        />
                        <DLText
                            id={"city-value"}
                            type={"normal"}
                            text={companyCity}
                        />
                    </div>
                    <div className="col-6 pt-3">
                        <DLText
                            id={"district"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"District"}
                            fontColor={"grayDark"}
                        />
                        <DLText
                            id={"district-value"}
                            type={"normal"}
                            text={companyDistrict}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 pt-3">
                        <DLText
                            id={"pinCode-id"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"PinCode"}
                            fontColor={"grayDark"}
                        />
                        <DLText
                            id={"pinCode-value"}
                            type={"normal"}
                            text={companyPinCode}
                        />
                    </div>
                    <div className="col-6 pt-3">
                        <DLText
                            id={"state-id"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"State"}
                            fontColor={"grayDark"}
                        />
                        <DLText
                            id={"state-value"}
                            type={"normal"}
                            text={getStateName(companyState)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 pt-3">
                        <DLText
                            id={"country-id"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Country"}
                            fontColor={"grayDark"}
                        />
                        <DLText
                            id={"country-value"}
                            type={"normal"}
                            text={getCountryName(companyCountry)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 pt-3">
                        <DLText
                            id={"email-id"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Email"}
                            fontColor={"grayDark"}
                        />
                        <DLText
                            id={"primaryEmail"}
                            type={"normal"}
                            text={
                                isEmpty(primaryEmail) ? '-' :
                                    <a href={`mailto:${primaryEmail}`} className="link-primary" style={{ fontSize: appTheme.fontSizes.sm, wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                        {primaryEmail}
                                    </a>
                            }
                            isInline={true}
                        />
                    </div>
                    <div className="col-6 pt-3">
                        <DLText
                            id={"primary-phone-number"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Primary Mobile"}
                            fontColor={"grayDark"}
                        />
                        <DLText
                            id={"primaryPhoneNumber"}
                            type={"normal"}
                            text={
                                isEmpty(primaryPhoneNumber) ? '-' :
                                    <a href={`tel:${primaryPhoneNumber}`} className="link-primary" style={{ fontSize: appTheme.fontSizes.sm, }}>
                                        {primaryPhoneNumber}
                                    </a>
                            }
                            isInline={true}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderCorporateAccountDetails = () => {
        const {IIN, activationDate, accountType, category, getCategory, taxStatus, isShowCompanyDetails,
            dateOfIncorporation, occupation, companyName} = props;

        return (
            <div>
                <div className={isMobileView ? 'page-container' : 'border border-rounded bg-white'}>
                    <div className={isMobileView ? '' : 'p-3'}>
                        <div className={isMobileView ? 'row m-0 p-0 pt-4 mt-3' : 'row m-0 p-0 pt-3' }>
                            <div className={isXSView ? 'col-12' : isShowCompanyDetails ? 'col-5' : 'col-6'}>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Organization Name'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={companyName}
                                    />
                                </div>
                            </div>

                            <div className={isXSView ? 'col-12 pt-3' : isShowCompanyDetails ? 'col-4' : 'col-6'}>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'date-of-incorporation'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Date of Incorporation'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={dateOfIncorporation}
                                    />
                                </div>
                            </div>

                            {(!isEmpty(taxStatus) && isShowCompanyDetails) &&
                            <div className={isXSView ? 'col-12 pt-3' : isShowCompanyDetails ? 'col-3' : 'col-6'}>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'comapny-address'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Tax Status'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={taxStatus}
                                    />
                                </div>
                            </div>
                            }
                        </div>
                        <div className='row m-0 p-0 pt-3'>
                            <div className={isXSView ? 'col-12' : isShowCompanyDetails ? 'col-5' : 'col-6'}>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'IIN'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={IIN}
                                    />
                                </div>
                            </div>

                            <div className={isXSView ? 'col-12 pt-3' : isShowCompanyDetails ? 'col-4' : 'col-6'}>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Account Type'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={accountType}
                                    />
                                </div>
                            </div>

                            <div className={isXSView ? 'col-12 pt-3' : isShowCompanyDetails ? 'col-3' : 'col-6'}>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Account Activation Date'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={activationDate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={isMobileView ? 'row m-0 p-0 pb-4 mb-3' : 'row m-0 p-0 pb-3'}>
                            <div className={isXSView ? 'col-12 pt-3' : isShowCompanyDetails ? 'col-5' : 'col-6'}>
                                <div className='pt-4'
                                     style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Category'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={getCategory(category)}
                                    />
                                </div>
                            </div>

                            <div className={isXSView ? 'col-12 pt-3' : isShowCompanyDetails ? 'col-4' : 'col-6'}>
                                <div className='pt-4'
                                     style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'occupation-id'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Occupation'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={occupation}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    };

    const  renderMobileCorporateAccountDetails = () => {
        const {
            IIN, activationDate, accountType, category, getCategory, taxStatus,
            isShowCompanyDetails, dateOfIncorporation, occupation, companyName
        } = props;

        return (
            <div className={'container p-3'}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <div>
                            <DLText
                                id={'organization-name-label'}
                                type={'normal'}
                                fontSize={'xs'}
                                text={'Organization Name'}
                                fontColor={'grayDark'}
                            />
                            <DLText
                                id={'organization-name'}
                                type={'normal'}
                                text={companyName}
                            />
                        </div>
                    </div>
                    <div className={'col-6 pt-3 mt-1'}>
                        <div>
                            <DLText
                                id={'date-of-incorporation'}
                                type={'normal'}
                                fontSize={'xs'}
                                text={'Date of Incorporation'}
                                fontColor={'grayDark'}
                            />
                            <DLText
                                id={'date-of-incorporation-value'}
                                type={'normal'}
                                text={dateOfIncorporation}
                            />
                        </div>
                    </div>

                    {!isEmpty(taxStatus) && isShowCompanyDetails && (
                        <div className={'col-6 pt-3 mt-1'}>
                            <div>
                                <DLText
                                    id={'tax-status-label'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Tax Status'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'tax-status-value'}
                                    type={'normal'}
                                    text={taxStatus}
                                />
                            </div>
                        </div>
                    )}

                    <div className={'col-6 pt-3 mt-1'}>
                        <div>
                            <DLText
                                id={'iin-label'}
                                type={'normal'}
                                fontSize={'xs'}
                                text={'IIN'}
                                fontColor={'grayDark'}
                            />
                            <DLText
                                id={'iin-value'}
                                type={'normal'}
                                text={IIN}
                            />
                        </div>
                    </div>

                    <div className={'col-6 pt-3 mt-1'}>
                        <div>
                            <DLText
                                id={'account-type-label'}
                                type={'normal'}
                                fontSize={'xs'}
                                text={'Account Type'}
                                fontColor={'grayDark'}
                            />
                            <DLText
                                id={'account-type-value'}
                                type={'normal'}
                                text={accountType}
                            />
                        </div>
                    </div>

                    <div className={'col-6 pt-3 mt-1'}>
                        <div>
                            <DLText
                                id={'activation-date-label'}
                                type={'normal'}
                                fontSize={'xs'}
                                text={'Account Activation Date'}
                                fontColor={'grayDark'}
                            />
                            <DLText
                                id={'activation-date-value'}
                                type={'normal'}
                                text={activationDate}
                            />
                        </div>
                    </div>

                    <div className={'col-6 pt-3 mt-1'}>
                        <div>
                            <DLText
                                id={'category-label'}
                                type={'normal'}
                                fontSize={'xs'}
                                text={'Category'}
                                fontColor={'grayDark'}
                            />
                            <DLText
                                id={'category-value'}
                                type={'normal'}
                                text={getCategory(category)}
                            />
                        </div>
                    </div>

                    <div className={'col-6 pt-3 mt-1'}>
                        <div>
                            <DLText
                                id={'occupation-label'}
                                type={'normal'}
                                fontSize={'xs'}
                                text={'Occupation'}
                                fontColor={'grayDark'}
                            />
                            <DLText
                                id={'occupation-value'}
                                type={'normal'}
                                text={occupation}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderMobileAccountDetails = () => {
        const {
            IIN,
            activationDate,
            accountType,
            category,
            getCategory,
            taxStatus,
            isShowCompanyDetails
        } = props;

        return (
            <div className={'container p-3'}>
                <div className="row">
                    <div className={'col-6'}>
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'IIN'}
                            fontColor={'grayDark'}
                        />
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={IIN}
                        />
                    </div>
                    <div className={'col-6'}>
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'Account Type'}
                            fontColor={'grayDark'}
                        />
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={accountType}
                        />
                    </div>

                    {(!isEmpty(taxStatus) && isShowCompanyDetails) && (
                        <div className={'col-6 pt-3 mt-1'}>
                            <DLText
                                id={'comapny-address'}
                                type={'normal'}
                                fontSize={'xs'}
                                text={'Tax Status'}
                                fontColor={'grayDark'}
                            />
                            <DLText
                                id={'lead-details-assigned'}
                                type={'normal'}
                                text={taxStatus}
                            />
                        </div>
                    )}

                    <div className={'col-6 pt-3 mt-1'}>
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'Category'}
                            fontColor={'grayDark'}
                        />
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={getCategory(category)}
                        />
                    </div>

                    <div className={'col-6 pt-3 mt-1'}>
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            fontSize={'xs'}
                            text={'Account Activation Date'}
                            fontColor={'grayDark'}
                        />
                        <DLText
                            id={'lead-details-assigned'}
                            type={'normal'}
                            text={activationDate}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderAccountDetails = () => {
        const {IIN, activationDate, accountType, category, getCategory, taxStatus, isShowCompanyDetails} = props;

        return (
            <div>
                <div className={isMobileView ? 'page-container' : 'border border-rounded bg-white'}>
                    <div className={isMobileView ? '' : 'p-3'}>
                        <div className={isMobileView ? 'row m-0 p-0 pt-4 mt-3' : 'row m-0 p-0 pt-3'}>
                            <div className={isXSView ? 'col-12' : isShowCompanyDetails ? 'col-4' : 'col-6'}>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'IIN'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={IIN}
                                    />
                                </div>
                            </div>

                            <div className={isXSView ? 'col-12 pt-3 mt-1' : isShowCompanyDetails ? 'col-4' : 'col-6'}>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Account Type'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={accountType}
                                    />
                                </div>
                            </div>

                            {(!isEmpty(taxStatus) && isShowCompanyDetails) &&
                                <div className={isXSView ? 'col-12 pt-3 mt-1' : isShowCompanyDetails ? 'col-4' : 'col-6'}>
                                    <div style={{minWidth: '120px'}}>
                                        <DLText
                                            id={'comapny-address'}
                                            type={'normal'}
                                            fontSize={'xs'}
                                            text={'Tax Status'}
                                            fontColor={'grayDark'}
                                        />
                                        <DLText
                                            id={'lead-details-assigned'}
                                            type={'normal'}
                                            text={taxStatus}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={isMobileView ? 'row m-0 p-0 pb-4 mb-3' : 'row m-0 p-0 pb-3'}>
                            <div className={isXSView ? 'col-12 pt-3 mt-1' : isShowCompanyDetails ? 'col-4' : 'col-6'}>
                                <div className='pt-4'
                                     style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Category'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={getCategory(category)}
                                    />
                                </div>
                            </div>

                            <div className={isXSView ? 'col-12 pt-3 mt-1' : isShowCompanyDetails ? 'col-4' : 'col-6'}>
                                <div className='pt-4'
                                     style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={'Account Activation Date'}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'lead-details-assigned'}
                                        type={'normal'}
                                        text={activationDate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    };

    const renderKYCBlockDetails = () => {
        return (
            <Kyc {...props}/>
        )
    };

    if (isEmpty(accountDetailsUIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <div className={isMobileView ? "" : "page-container"}>
            <div className={isMobileView ? "" : "row pt-3 mt-1"}>
                <div className={isMobileView ? 'col-12' : 'col-8'}>
                    {isShowCompanyDetails
                        ? (isXSView
                            ? renderMobileCorporateAccountDetails()
                            : renderCorporateAccountDetails())
                        :
                        (isXSView
                            ? renderMobileAccountDetails()
                            : renderAccountDetails())
                    }
                    {isXSView && isShowCompanyDetails ? (
                        <div style={{ height: '5px', backgroundColor: appTheme.appColor.gray }} />
                    ) : (
                        isMobileView && isShowCompanyDetails && (
                            <div style={{ height: '5px', backgroundColor: appTheme.appColor.gray }} />
                        )
                    )}
                    {isShowCompanyDetails
                        ? isXSView
                            ? renderMobileCompanyInformation()
                            : renderComapnyInformation()
                        : null}
                </div>
                {isXSView ? (
                    <div style={{ height: '5px', backgroundColor: appTheme.appColor.gray }} />
                ) : (
                    isMobileView && <div style={{ height: '5px', backgroundColor: appTheme.appColor.gray }} />
                )}
                <div className={isMobileView ? 'col-12' : 'col-4'}>
                    {renderKYCBlockDetails()}
                </div>
            </div>
        </div>
    );
};

export default AccountDetailsView;