import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PurchaseTransactionView from "./PurchaseTransactionView";
import {isEmpty} from "../../../../../../utils/validations";
import {
    submitFundRequest,
    serachPurchaseProducts,
    selectProduct,
    addPurchaseScheme,
    getTransactionDetails
} from "../../../../FTAction";
import {applyPortfolio} from "../../../../../../portfolio/portfolioAction";
import {APP_CONST} from "../../../../../../AppComponents/AppConstants";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";

const PurchaseTransaction = (props) => {

    const [purchaseTransactionState, setPurchaseTransactionState] = useState({
        funds: [],
        investmentAmount: "",
        totalAllocationAmount: "",
        totalAllocationPercentage: "",
        warningMessage: '',
        totalSelectedAmount: '',
        responseErrors: '',
        isOpenAddSchemeModal: false,
        isOpenDiscardModal: false,
        isShowLoader: false,
        isShowInlineLoader: false,
        searchFundError: '',
        searchKeyword: '',
        addedFund: '',
        productModalError: '',
        selectedProductId: '',
        isReinvested: false,
        addedProductAmount: '',
        folioNumbers: [
            {
                label: 'Create New Folio',
                value: 'null'
            }
        ],
        addedFolioNumber: '',
        productMinValue: '',
        productMaxValue: '',
        productMultipleValue: '',
        addedProductAmountError: '',
        selectedScheme: [],
        UIState: ''
    });

    useEffect(() => {
        let mode =  props?.location?.state?.mode;
        if (mode === 'ADD') {
            getSelectedPortfolioData()
        } else {
            getTransactionData(mode)
        }
    }, []);

    useEffect(() => {
        if(purchaseTransactionState?.isOpenAddSchemeModal){
            let limits = getProductMinAndMaxValue(purchaseTransactionState?.addedFolioNumber, purchaseTransactionState?.selectedScheme?.productLimits)
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                ...limits,
            }))
        }
    }, [purchaseTransactionState?.addedFolioNumber]);

    const getSelectedPortfolioData = () => {
        let portfolioId =  props?.location?.state?.portfolioId;
        let transactionRequestId =  getTransactionRequestId();

        let payload = {
            portfolioId: isEmpty(portfolioId)? null: portfolioId,
            transactionRequestId: transactionRequestId
        };

        applyPortfolio(payload, true)
            .then(res => {
                if (res?.success) {
                    setTransactionData(res?.data);
                }else{
                    setPurchaseTransactionState((prevState) => ({
                        ...prevState,
                        responseErrors: res?.__error,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE
                    }))
                }
            })
    };


    const getTransactionData = (mode) => {
        const txnRequestId = getTransactionRequestId();
        const clientRequestId = getClientRequestId();
        getTransactionDetails(txnRequestId).then(transactionsRes => {
            if (transactionsRes?.success) {
                let requestData = transactionsRes.data;
                if (isEmpty(requestData?.funds)) {
                    setTransactionData(requestData);
                    props.history.replace("/client-requests/" + clientRequestId + "/lumpsum-transaction/" + txnRequestId + "/select-portfolio")
                } else {
                    setTransactionData(requestData);
                }
            }
        })
    };

    const getTransactionRequestId = () => {
        return props.match?.params?.transactionId
    };

    const getClientRequestId = () => {
        return props.match?.params?.clientRequest
    };

    const setTransactionData = (selectedPortfolio) => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        if (isEmpty(selectedPortfolio)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                isShowLoader: false
            }));
            return
        }
        let allFunds = [];
        let response = selectedPortfolio?.funds;

        response?.map((selectedFund) => {
            let allocationAmount = getAllocation(selectedFund?.amount, selectedPortfolio?.investmentAmount);
            allFunds.push({
                productId: selectedFund?.productId,
                productName: selectedFund?.productName,
                amount: selectedFund?.amount,
                allocationAmount: selectedFund?.allocation,
                allocation: isEmpty(allocationAmount) ? '-' : (allocationAmount + '%'),
                errors: getErrors(selectedFund?.amount, selectedFund, selectedFund?.folioNumber),
                details: selectedFund?.details,
                folioNumbers: [
                    {
                        label: 'Create New Folio',
                        value: 'null'
                    }
                ],
                folioNumber: selectedFund?.folioNumber,
                reinvestFlag: setReinvest(selectedFund)
            })
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds,
            investmentAmount: selectedPortfolio?.investmentAmount,
            totalAllocationAmount: selectedPortfolio?.totalAllocationAmount,
            totalAllocationPercentage: selectedPortfolio?.totalAllocationPercentage,
            isShowLoader: false,
            UIState: APP_CONST.CONTENT_AVAILABLE
        }))
    };

    const changeFolioNumber = (newFolioNumber, productId) => {
        const {funds} = purchaseTransactionState;
        let allFunds = funds.map(fund => {
            if (fund.productId === productId) {
                return {
                    ...fund,
                    errors: getErrors(fund?.amount, fund, newFolioNumber),
                    folioNumber: newFolioNumber
                };
            }
            return fund;
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds
        }))
    };

    const isMultiple = (num, multiple) => {

        if (multiple === 0) return false;

        let selectedMultiple = multiple;

        if(selectedMultiple < 100){
            //as per disscusion with both sir to solve 0.01 multiplier issue
            selectedMultiple = 100
        }

        // Count decimal places in multiple to determine the scaling factor
        const decimalPlaces = selectedMultiple?.toString().split('.')[1]?.length || 0;
        const scaleFactor = Math.pow(10, decimalPlaces);

        // Scale num and selectedMultiple to integers
        const scaledNum = Math.round(num * scaleFactor);
        const scaledMultiple = Math.round(selectedMultiple * scaleFactor);
        // Check if scaled num is a selectedMultiple of scaled selectedMultiple
        return scaledNum % scaledMultiple === 0;
    };

    const getErrors = (amount, selectedFund, folioNumber) => {
        let errors = selectedFund?.errors;
        let allLimits = selectedFund?.details?.limits;

        let uniqueError = errors;

        if (isEmpty(allLimits)) {
            return errors
        }


        let selectedLimit = getProductMinAndMaxValue(folioNumber, allLimits)

        //get limit details from array of all limits using txnType
        let selectedMultiplier = (selectedLimit?.productMultipleValue < 100) ? 100 : selectedLimit?.productMultipleValue;

        let minimumAmountMessage = 'Minimum amount required is ' + selectedLimit?.productMinValue;
        let maximumAmountMessage = 'Maximum amount allowed is ' + selectedLimit?.productMaxValue;
        let multiplierMessage = 'Amount should be in multiples of ' + selectedMultiplier;

        let valuesArray = {
            minimumAmountMessage : minimumAmountMessage,
            maximumAmountMessage : maximumAmountMessage,
            multiplierMessage : multiplierMessage
        }

        errors = replaceExistingMessage(errors, valuesArray);

        if (!isEmpty(selectedLimit)) {
            if (amount > selectedLimit?.productMaxValue) {
                errors.push(maximumAmountMessage)
            } else {
                errors = errors.filter(value => value !== maximumAmountMessage);
            }

            if (amount < selectedLimit?.productMinValue) {
                errors.push(minimumAmountMessage)
            } else {
                errors = errors.filter(value => value !== minimumAmountMessage);
            }

            if (isMultiple(amount, selectedMultiplier)) {
                errors = errors.filter(value => value !== multiplierMessage);

            } else {
                errors.push(multiplierMessage)
            }

            uniqueError = [...new Set(errors)];
        }
        return uniqueError
    };

    const replaceExistingMessage = (array, newValues) => {
        if(isEmpty(array)){
          return [];
        }
        let updatedArray = [...array]
        for(let i = 0; i < updatedArray.length; i++){
            if(updatedArray[i].includes("Minimum amount required is")){
                updatedArray[i] = newValues?.minimumAmountMessage;
            }else if(updatedArray[i].includes("Maximum amount allowed is")){
                updatedArray[i] = newValues?.maximumAmountMessage;
            }else if(updatedArray[i].includes("Amount should be in multiples of")){
                updatedArray[i] = newValues?.multiplierMessage;
            }
        }
        return updatedArray;
    };

    const onchangeValue = (name, value) => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            [name]: value,
            [name + "Error"]: ''
        }))
    };

    const getAllocation = (amountSelected, investmentAmount) => {

        if (isEmpty(amountSelected) || isEmpty(investmentAmount)) {
            return ''
        }

        let totalPercentage = (amountSelected / investmentAmount) * 100;
        return isEmpty(totalPercentage) ? '' : totalPercentage.toFixed(2)
    };

    const changeAmount = (newAmount, productId) => {
        const {funds, investmentAmount} = purchaseTransactionState;
        let totalAllocationAmount = 0;
        let allFunds = funds.map(fund => {
            if (fund.productId === productId) {
                let allocationAmount = getAllocation(newAmount, investmentAmount);
                totalAllocationAmount += parseInt(newAmount);
                return {
                    ...fund,
                    amount: newAmount,
                    errors: getErrors(newAmount, fund, fund?.folioNumber),
                    allocationAmount: allocationAmount,
                    allocation: isEmpty(allocationAmount) ? '-' : allocationAmount + '%'
                };
            } else {
                totalAllocationAmount += parseInt(fund?.amount)
            }
            return fund;
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            warningMessage: (totalAllocationAmount !== parseInt(investmentAmount)) ? 'The investment amount does not match the total amount of all schemes.' : '',
            funds: allFunds
        }))
    };

    const clearSelectedPortfolio = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: [],
            totalAllocationAmount: "",
            totalAllocationPercentage: "",
            warningMessage: '',
            totalSelectedAmount: ''
        }))
    };

    const deleteFund = (productId) => {
        const {funds, investmentAmount} = purchaseTransactionState;
        let allFunds = funds.filter(product => product.productId !== productId);

        const totalAllocationAmount = allFunds.reduce((sum, fund) => {
            return sum + parseInt(fund?.amount || 0);
        }, 0);

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds,
            warningMessage: (totalAllocationAmount !== parseInt(investmentAmount) && totalAllocationAmount !== 0) ? 'The investment amount does not match the total amount of all schemes.' : '',
        }))
    };

    const closeAddSchemeModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenAddSchemeModal: false,
            productModalError:'',
            searchFundError: '',
            searchProduct: '',
            searchKeyword:'',
            products:[],
            addedFund: '',
            isReinvested: false,
            addedProductAmountError: '',
            productMinValue:'',
            productMaxValue: '',
            productMultipleValue : '',
            addedProductAmount: ''
        }))
    };

    const openAddSchemeModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenAddSchemeModal: true
        }))
    };

    const redirectScreen = (action, data) => {
        let transactionRequestId = getTransactionRequestId();
        let clientRequestId = getClientRequestId();

        if (action === 'SELECT-FOLIO') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/lumpsum-transaction/' + transactionRequestId + '/select-portfolio',{portfolio: data});
        }
        if (action === 'DISCARD') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/lumpsum-transaction/' + transactionRequestId);
        }
    };

    const validateTransaction = () => {
        const {funds} = purchaseTransactionState;
        let allErrors = [];
        funds.map((selectedFund) => {
            let errors = isEmpty(selectedFund?.errors) ? [] : selectedFund?.errors;
            allErrors = [...allErrors, ...errors]
        });
        return (isEmpty(allErrors));
    };

    let searchProductAPI = (keyword) => {
        let payload = {keyword: keyword, limitCount: 30};
        serachPurchaseProducts(payload).then(res => {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchFundError: '',
                products: res.data?.products,
                productModalError: '',
                isShowInlineLoader: false
            }))
        });
    };

    const searchProduct = (keyword) => {

        if (isEmpty(keyword)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchKeyword: '',
                products:[]
            }));
            return
        }

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            searchKeyword: keyword,
            isShowInlineLoader: true,
        }));

        searchProductAPI(keyword)

    };

    const setReinvest = (selectedFund) => {

        if(selectedFund?.reinvestFlag === true){
            return true;
        }else if(selectedFund?.reinvestFlag === false){
            return false;
        }
        return selectedFund?.details?.reinvestTag !== 'N';

    }

    const submitTransactionDetails = () => {
        const {funds} = purchaseTransactionState;
        let transactionRequestId = getTransactionRequestId();
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        let products = [];
        funds.map((selectedFund) => {
            products.push({
                productId: selectedFund?.productId,
                allocation: selectedFund?.allocationAmount,
                amount: selectedFund?.amount,
                folioNumber: selectedFund?.folioNumber,
                reinvestFlag: setReinvest(selectedFund)
            })
        });

        if (validateTransaction()) {
            let payload = {
                transactionRequestId: transactionRequestId,
                products: products
            };

            submitFundRequest(payload, true)
                .then(res => {
                    if (res.success) {
                        setPurchaseTransactionState((prevState) => ({
                            ...prevState,
                            isShowLoader: false
                        }));
                        redirectScreen('DISCARD')
                    } else {
                        setPurchaseTransactionState((prevState) => ({
                            ...prevState,
                            responseErrors: res?.__error,
                            isShowLoader: false
                        }))

                    }
                })
        }
    };

    const addProduct = (id) => {
        const {products} = purchaseTransactionState;
        const selectedProduct = products.find(item => id === item.id)
        let transactionRequestId = getTransactionRequestId();
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));
        if (isEmpty(selectedProduct)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedFund: '',
                selectedProductId: '',
                searchKeyword: '',
                products: [],
                productModalError: '',
                addedProductAmount: '',
                addedFolioNumber: '',
                isShowLoader: false,
                isReinvested: false
            }));
            return
        }

        let productId = selectedProduct?.id;

        selectProduct(transactionRequestId, productId).then(res => {
            if (res?.success) {
                let limits = getProductMinAndMaxValue("", res?.data?.productLimits)
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    ...limits,
                    selectedScheme: res.data,
                    addedFund: selectedProduct?.description,
                    selectedProductId: productId,
                    productModalError: '',
                    searchKeyword: '',
                    isShowLoader: false,
                    isReinvested: isReinvestChecked(res.data)
                }))
            } else {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    addedFund: selectedProduct?.description,
                    selectedProductId: productId,
                    productModalError: res?.__error,
                    isShowLoader: false
                }))
            }
        });
    };

    const reinvestProduct = (value) => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isReinvested: value,
        }))
    };

    const validateAmount = () => {
        const {addedProductAmount, productMinValue, productMaxValue, productMultipleValue, selectedProductId} = purchaseTransactionState;

        if (isEmpty(selectedProductId)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchFundError: 'Please select Product'
            }));
            return false
        }

        if (isEmpty(addedProductAmount)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Please enter amount'
            }));
            return false
        }

        if (addedProductAmount > productMaxValue) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Maximum amount allowed is ' + productMaxValue
            }));
            return false
        }

        if (addedProductAmount < productMinValue) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Minimum amount required is ' + productMinValue
            }));
            return false
        }

        if (!isMultiple(addedProductAmount, productMultipleValue)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Amount should be in multiples of ' + productMultipleValue
            }));
            return false
        }

        return true
    };

    const addScheme = () => {
        const {addedProductAmount, addedFolioNumber, selectedProductId, funds, investmentAmount, isReinvested} = purchaseTransactionState;
        let transactionRequestId = getTransactionRequestId();

        let isValidAmount = validateAmount();
        if (!isValidAmount) {
            return
        }

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            txnRequestId: transactionRequestId,
            productId: selectedProductId,
            amount: addedProductAmount,
            folioNumber: addedFolioNumber,
            reinvestFlag: isReinvested
        };

        addPurchaseScheme(payload).then(res => {
            if (res?.success) {
                let resp = res?.data[0];

                let allocationAmount = getAllocation(resp?.amount, investmentAmount);
                let respFunds = [{
                    productId: resp?.productId,
                    productName: resp?.productName,
                    amount: resp?.amount,
                    allocationAmount: resp?.allocation,
                    allocation: isEmpty(allocationAmount) ? '-' : (allocationAmount + '%'),
                    errors: getErrors(resp?.amount, resp, addedFolioNumber),
                    details: resp?.details,
                    folioNumbers: [
                        {
                            label: 'Create New Folio',
                            value: 'null'
                        }
                    ],
                    folioNumber: isEmpty(addedFolioNumber)? "" : addedFolioNumber,
                    reinvestFlag: resp?.reinvestFlag
                }];

                const mappedProducts = funds.concat(respFunds);
                const totalAllocationAmount = mappedProducts.reduce((sum, fund) => {
                    return sum + parseInt(fund?.amount || 0);
                }, 0);

                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    funds: mappedProducts,
                    isShowLoader: false,
                    addedFolioNumber: '',
                    addedProductAmount: '',
                    addedFund: '',
                    selectedProductId: '',
                    searchKeyword: '',
                    isOpenAddSchemeModal: false,
                    warningMessage: (parseInt(investmentAmount) !== totalAllocationAmount) ? 'The investment amount does not match the total amount of all schemes.' : '',
                }))
            } else {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    productModalError: res?.__error,
                    isShowLoader: false
                }))
            }
        });
    };

    const closeAddDiscardModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: false
        }))
    };

    const isReinvestChecked = (selectedScheme) => {
        let flag = false
        if(isEmpty(selectedScheme)){
            return;
        }
        if(selectedScheme.reinvestTag === 'X' || selectedScheme.reinvestTag === 'Y' || selectedScheme.reinvestTag === 'Z'){
            flag = true
        }
        return flag
    }

    const isCheckBoxDisabled = (selectedScheme) => {
        let flag = false
        if(isEmpty(selectedScheme)){
            return;
        }
        if(selectedScheme?.reinvestTag === 'N' || selectedScheme?.reinvestTag === 'Y'  || selectedScheme?.reinvestTag === 'Z'){
            flag = true
        }
        return flag
    }

    const getProductMinAndMaxValue = (addedFolioNumber, productLimits) => {

        if(isEmpty(productLimits)){
            return;
        }
        let purchaseTypeObj = getPurchaseTypeObject(addedFolioNumber, productLimits)

        let limits = {
            productMinValue : purchaseTypeObj?.minAmount,
            productMaxValue : purchaseTypeObj?.maxAmount,
            productMultipleValue: (purchaseTypeObj?.multiples < 100) ? 100 : purchaseTypeObj?.multiples,
        }
        return limits;
    };

    const getPurchaseTypeObject = (folioNumber, limitsArray) => {
        if(isEmpty(limitsArray)){
            return;
        }
        let txn = [];

        if(isEmpty(folioNumber)){
            txn = limitsArray.find((item)=>{
               return item?.TRXNType === "FP";
           })
        }else{
            txn = limitsArray.find((item)=>{
                return item?.TRXNType === "AP";
            })
        }
        return txn;
    };

    const openDiscardModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: true
        }))
    };

    if (isEmpty(purchaseTransactionState?.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }


    return (
        <PurchaseTransactionView
            {...props}
            {...purchaseTransactionState}
            onchangeValue={onchangeValue}
            changeAmount={changeAmount}
            clearSelectedPortfolio={clearSelectedPortfolio}
            deleteFund={deleteFund}
            closeAddSchemeModal={closeAddSchemeModal}
            openAddSchemeModal={openAddSchemeModal}
            redirectScreen={redirectScreen}
            validateTransaction={validateTransaction}
            submitTransactionDetails={submitTransactionDetails}
            searchProduct={searchProduct}
            addProduct={addProduct}
            reinvestProduct={reinvestProduct}
            addScheme={addScheme}
            closeAddDiscardModal={closeAddDiscardModal}
            openDiscardModal={openDiscardModal}
            isCheckBoxDisabled={isCheckBoxDisabled}
            isReinvestChecked={isReinvestChecked}
            changeFolioNumber={changeFolioNumber}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    isMDView: state.appState.deviceInfo.isMDView,
});


export default connect(mapStateToProps, {})(PurchaseTransaction);