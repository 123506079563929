import React, {useState} from 'react';
import {connect} from "react-redux";

import {discardTransaction} from "../../../../FTAction";

import DiscardTransactionView from "./DiscardTransactionView";


const DiscardTransaction = (props) => {

    const {txnRequestId, clientRequestId, handleCloseDiscardTransactionModal} = props;
    const [discardTransactionState, setDiscardTransactionState] = useState({
        isApiInProgress: false,
        handleErrorInComponent: true,
        modalErrorMessage: ''
    });

    const handleDiscard = () => {
        setDiscardTransactionState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        discardTransaction(txnRequestId)
            .then((res) => {
                    if (res.success) {
                        setDiscardTransactionState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false
                        }));
                        handleCloseDiscardTransactionModal();
                        props.history.replace("/client-requests/"+clientRequestId);
                    } else {
                        setDiscardTransactionState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                            modalErrorMessage: res.__error
                        }));
                    }
                }
            );
    };

    return (
        <DiscardTransactionView
            {...props}
            {...discardTransactionState}
            handleDiscard={handleDiscard}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(DiscardTransaction);
