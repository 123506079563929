import React from 'react';
import moment from "moment";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem";

import {isEmpty} from "../../../core/components/DLComponentHelper";
import {renderNFTDetailsStatusBadge} from "../../../transactions/NFT/NFTHelper";
import appTheme from "../../../assets/appTheme";

import DLText from "../../../core/components/DLText/DLText";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import AddNewTransaction from "./component/addNewTransaction/AddNewTransaction";
import MarkAsCompleted from "./component/markAsCompleted/MarkAsCompleted"
import {capitalizeFirstLetter, convertUTCToLocalTime, isCorporateUser} from "../../../utils/helper";
import {getTicketBadge, renderTransactionStatusDotUI} from "../../../transactions/FT/FTHelper";
import DiscardTransaction from "./component/discardTransaction/DiscardTransaction";

const ClientRequestDetailsView = (props) => {
    const {
        isMobileView,
        category,
        IINNumber,
        accountType,
        ticketNumber,
        status,
        FTStatuses,
        isShowLoader,
        UIState,
        createdBy,
        comments,
        createdAt,
        getAdvisoryUserName,
        handleOpenAddNewTransaction,
        transactionsHistory,
        earlierReqHistory,
        handleOpenMarkAsCompletedModal,
        isMarkAsCompletedAvailable,
        handleItemOpen,
        anchorEl,
        handleItemClose,
        handleRedirect,
        responseError,
        profile,
        handleOpenDiscardTransactionModal
    } = props;

    const renderAddTransactionModal = () => {
        const {isMobileView, handleCloseAddNewTransaction, isAddNewTransactionDialogOpen} = props;

        if (isAddNewTransactionDialogOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseAddNewTransaction}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <AddNewTransaction {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <AddNewTransaction {...props}/>
                )
            }
        }

    };

    const renderAccountHoldersDetails = (accountDetails) => {
        const {details, corporateAccountDetails} = props;
        if(isCorporateUser(details?.accountDetails[0]?.accountType)){
            let name = corporateAccountDetails?.name;
            let email = corporateAccountDetails?.email;
            let phoneNumber = corporateAccountDetails?.phoneNumber;
            return(
                <div className={'mb-1'}>
                    <div className='text-start'>
                        <DLText
                            id={'account-holder-name'}
                            text={name}
                            marginBottom={"none"}
                            fontSize={"xs"}
                        />
                        <div>
                            {isEmpty(email) ? '-' : <a href={'mailto:' + email}
                                                       style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                                {email}
                            </a>}
                        </div>
                        <div>
                            {isEmpty(phoneNumber) ? '-' : <a href={'tel:' + phoneNumber}
                                                             style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                                {phoneNumber}
                            </a>}
                        </div>
                    </div>
                </div>
            )
        }
        return accountDetails.map((accountHolder, index) => (
            <div key={'menuItem-' + accountHolder.id} className={(accountDetails?.length === index + 1) ? ' mb-1' : ' border-bottom mb-1 pb-2'}>
                <div className='text-start'>
                    <DLText
                        id={'account-holder-name'}
                        text={'Account Holder ' + (index + 1) + ': ' + accountHolder.fullName}
                        marginBottom={"none"}
                        fontSize={"xs"}
                    />
                    <div>
                        {(isEmpty(accountHolder.email)) ? '-' : <a href={'mailto:' + accountHolder.email}
                           style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                            {accountHolder.email}
                        </a>}
                    </div>
                    <div>
                        {isEmpty(accountHolder.phoneNumber) ? '-' :<a href={'tel:' + accountHolder.phoneNumber}
                           style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                            {accountHolder.phoneNumber}
                        </a>}
                    </div>
                </div>
            </div>
        ));
    };


    const renderAccountDetails = () => {
        const { accountDetails, corporateAccountDetails, details, getAccountHolderDetails } = props;
        let allAccountHoldersNames = "";
        let count = 0;
        let accountType = isEmpty(details?.accountDetails)? [] : details?.accountDetails[0]?.accountType;
        let accountHolderDetails = getAccountHolderDetails(accountDetails, accountType);

        if(!isEmpty(accountHolderDetails)){
            accountHolderDetails.map((applicant, index) => {
                if (isEmpty(allAccountHoldersNames)) {
                    allAccountHoldersNames = applicant?.fullName;
                } else {
                    count = index;
                }
            });
            if(!isEmpty(count) && count > 0){
                allAccountHoldersNames = allAccountHoldersNames + ' + ' + count;
            }
        }
        if(isCorporateUser(accountType)){
            allAccountHoldersNames = corporateAccountDetails?.name;
        }

        return (
            <div key={'accountDetails'}
                 className='d-flex align-items-center '>
                <div>
                    {(!isMobileView) &&
                        <div onClick={(event) => {
                            handleItemOpen(event)
                        }}>
                            <DLText id={'account-holder-name'}
                                    text={allAccountHoldersNames}
                                    marginBottom={"none"}
                                    isClickable={true}
                                    fontColor={"darkBlue"}
                            />
                        </div>
                    }
                    <Menu id="items-menu"
                          anchorEl={anchorEl}
                          getcontentanchorel={null}
                          anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                          transformOrigin={{vertical: "top", horizontal: "left"}}
                          open={Boolean(anchorEl)}
                          onClose={() => handleItemClose()}
                          className="p-2 w-100"
                          disableAutoFocusItem
                          elevation={0}
                          PaperProps={{
                              style: {
                                  marginTop: '0px',
                                  boxShadow: '0 4px 4px rgba(147, 147, 147, 0.25)',
                                  borderRadius: '8px'
                              }
                          }}
                          MenuListProps={{
                              style: {
                                  color: appTheme.appColor.black,
                                  maxHeight: '400px',
                                  textAlign: 'center',
                                  borderRadius: '8px'
                              },
                          }}>
                        {accountHolderDetails && accountHolderDetails.length > 0  || !isEmpty(corporateAccountDetails) ? (
                            <MenuItem key={'menuItem-'}
                                      className={'py-0 text-center'}
                                      style={{minWidth: 200, height: "max-content", cursor: "auto"}} disableRipple>
                                <div className='text-start'>
                                    {renderAccountHoldersDetails(accountHolderDetails)}
                                </div>
                            </MenuItem>
                        ) : (
                            <MenuItem disabled>No applicants available</MenuItem>
                        )}
                    </Menu>
                </div>
            </div>
        );
    };

    const renderMarkAsCompletedModal = () => {
        const {isMobileView, handleCloseMarkAsCompletedModal, isMarkAsCompletedModalOpen} = props;

        if (isMarkAsCompletedModalOpen) {
            if (isMobileView) {
                return (<div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseMarkAsCompletedModal}>
                    <div className="modal-content box-shadow h-100" onClick={(e) => e.stopPropagation()}>
                        <MarkAsCompleted {...props} isOverlay={true}/>
                    </div>
                </div>)
            } else {
                return (
                    <MarkAsCompleted {...props}/>
                )
            }
        }

    };

    const renderStatusDot = (status) => {
        if (isEmpty(status)) {
            return '-'
        }
        switch (status) {
            case 'NOT_CONTACTED':
                return <span className="status-dot warning"/>;

            case 'COMPLETED':
                return <span className="status-dot success"/>;

            default :
                return <span className="status-dot warning"/>;
        }
    };

    const renderStatus = (status) => {
        if (isEmpty(status)) {
            return '-'
        }
        switch (status) {
            case 'OPEN':
                return 'Open';
            case 'IN_PROGRESS':
                return 'In Progress';
            case 'COMPLETED':
                return 'Completed';
            default:
                return '';
        }
    };

    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div className='page-header d-flex justify-content-between'>
                    <div className="d-flex align-items-center">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            <div className="d-flex">
                                <div className='d-flex align-items-center'>
                                    Request
                                </div>
                                {getTicketBadge("Ticket No: ", ticketNumber)}
                            </div>
                        </div>
                    </div>

                </div>
                <div className='d-flex align-items-center mb-3 page-container '>
                    <div className='pe-3 border-end-black d-flex '>
                        <DLText id={'ac-details-name'}
                                fontWeight={'semi-bold'}
                                text={accountType + ' - ' + IINNumber}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='ps-3 pe-3 border-end-black cursor'>
                        <DLText id={'category'}
                                text={category}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='ps-3 pe-3 border-end-black'>
                        {renderAccountDetails()}
                    </div>
                    <div className='ps-3'>
                        {renderNFTDetailsStatusBadge(status)}
                    </div>
                </div>
            </div>
        )
    };

    const renderMultilineRemark = (remarks) => {
        if (isEmpty(remarks)) {
            return '-'
        }
        const lines = remarks.split('\n');
        return lines.map((line, index) => {
            if (isEmpty(line)) {
                return (
                     <br/>
                )
            }
            return (
                <div key={'summary' + index}>
                    <DLText id={'ViewActivity-Summary'}
                            fontSize={'sm'}
                            marginBottom={"none"}
                            fontWeight={"normal"}
                            text={line}/>
                </div>
            )
        })
    };

    const renderTransactionHistory = (data) => {
        if (isEmpty(data)) {
            return '-'
        }
        return data.map((transaction, index) => {
            return (
                <div className='p-0 w-100 border-rounded panel h-100 p-3 mb-3' style={{backgroundColor: appTheme.appColor.lightBlue}} key={'transaction' + index}>
                    <div className="px-0 h-100 p-1">
                        <div className='row'>
                            <div className='col-3'>
                                <DLText id={'created-date'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Created Date'}/>
                                <DLText id={'created-date-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={isEmpty(transaction?.createdAt) ? "" : convertUTCToLocalTime(transaction?.createdAt, "DD-MM-YYYY")}/>
                            </div>
                            <div className='col-3'>
                                <DLText id={'type-of-transaction'}
                                        fontWeight={"normal"}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        text={'Type of Transaction'}/>
                                <DLText id={'type-of-transaction-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={capitalizeFirstLetter(transaction?.txnRequestType)}/>
                            </div>
                            <div className='col-4'>
                                <DLText id={'status'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Status'}/>
                                <div className='d-inline-flex'>
                                    <DLText id={'status-value'}
                                            fontSize={'sm'}
                                            marginBottom={"none"}
                                            fontWeight={"normal"}
                                            text={renderTransactionStatusDotUI(transaction?.txnRequestStatus?.stage, transaction?.txnRequestStatus?.status, FTStatuses)}/>
                                </div>
                            </div>
                            <div className='col-2'>
                                <div className='d-flex align-items-center h-100'>
                                    <DLText id={'details'}
                                            fontSize={'sm'}
                                            isClickable={true}
                                            onClick={()=>{handleRedirect(transaction?.id)}}
                                            fontWeight={"normal"}
                                            text={'Details'}
                                            customTextStyle={{
                                                textDecoration: 'underline'
                                            }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    };

    const renderEarlierRequest = (data) => {
        if (isEmpty(data)) {
            return '-'
        }
        return data.map((req, index) => {
            return (
                <div key={index} className={index !== data.length - 1 ? 'border-bottom' : 'pb-3'} style={{ width: '96%' }}>
                    <div className='my-4'>
                        <div className='mb-4'>
                            <DLText id={'request'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Request'}/>
                            <div className='text-break'>
                                <DLText id={'created-date-value'}
                                        fontSize={'xs'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={req?.clientRequestComment?.comments}/>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div style={{ flex: '1' }}>
                                <DLText
                                    id={'created-date'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Created Date'}
                                />
                                <DLText
                                    id={'created-date-value'}
                                    fontSize={'xs'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={isEmpty(req?.createdAt)? "" : convertUTCToLocalTime(req?.createdAt, "DD-MM-YYYY")}
                                />
                            </div>
                            <div className={'ms-2'} style={{ flex: '1' }}>
                                <DLText
                                    id={'status'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Status'}
                                />
                                <div className="d-flex align-items-center">
                                    {renderStatusDot(req?.status)}
                                    <DLText
                                        id={'created-date-value'}
                                        fontSize={'xs'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={renderStatus(req?.status)}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )
        })
    };

    const renderNSETransactionDesktopDetails = () => {
        if (isEmpty(transactionsHistory)) {
            return (
                <div className="h-100 bg-white border-rounded p-3">
                    <div className='pt-1'>
                        <DLText
                            id={'NSE-transactions-title'}
                            type={'normal'}
                            text={'NSE Transactions'}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                    <div className='d-flex justify-content-center align-items-center h-75'>
                        <div className='text-center'>
                            <div className={'mb-1'}>
                                <DLText id={''}
                                        text={'There are no transactions initiated'}
                                        fontWeight={'normal'}
                                        fontSize={"sm"}
                                />
                            </div>
                            <div className={'mt-2'}>
                                <DLButton id={'add-new-transaction-button'}
                                          onClick={() => {
                                              handleOpenAddNewTransaction()
                                          }}
                                          label={'Add New Transaction'}
                                          hidden={profile?.isManagement}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded pt-3 ps-3 ">
                <div className='d-flex justify-content-between pt-1 pe-3'>
                    <DLText
                        id={'NSE-transactions-title'}
                        type={'normal'}
                        text={'NSE Transactions'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                    <DLButton
                        id={'add-new-transaction'}
                        label={'Add New Transaction'}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        hidden={profile?.isManagement}
                        onClick={() => handleOpenAddNewTransaction()}/>

                </div>
                <div className={'pe-3 overflow-auto overflow-x-hidden mt-3'}
                     style={{maxHeight: 'calc(100dvh - 405px)'}}>
                    {renderTransactionHistory(transactionsHistory)}
                </div>
            </div>
        )
    };

    const renderEarlierRequestsDesktopDetails = () => {
        if (isEmpty(earlierReqHistory)) {
            return (
                <div className="h-100 bg-white border-rounded p-3">
                    <div className='pt-1' style={{ marginLeft : '-1px' }}>
                        <DLText
                            id={'earlier-requests'}
                            type={'normal'}
                            text={'Earlier Requests'}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-center'>
                            <DLText id={''}
                                    text={'There are no earlier requests.'}
                                    fontWeight={'normal'}
                                    fontSize={"sm"}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded pt-3 ps-3">
                <div className='pt-1'  style={{ marginLeft : '-1px' }}>
                    <DLText
                        id={'earlier-requests-title'}
                        type={'normal'}
                        text={'Earlier Requests'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className={'overflow-auto overflow-x-hidden'} style={{maxHeight: 'calc(100dvh - 240px)', flex: 1}}>
                    {renderEarlierRequest(earlierReqHistory)}
                </div>
            </div>
        )
    };

    const renderRequestDesktopDetails = () => {
        return (
            <div className="h-100 bg-white border-rounded p-3 ">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'request-details-title'}
                        type={'normal'}
                        text={'Request'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                    {/*{isMarkAsCompletedAvailable &&*/}
                    {/*    <DLButton*/}
                    {/*        id={'add-new-transaction'}*/}
                    {/*        label={'Mark as Complete'}*/}
                    {/*        buttonSize={"sm"}*/}
                    {/*        fontSize={"sm"}*/}
                    {/*        styleType={"outlined"}*/}
                    {/*        backgroundColor={"grey"}*/}
                    {/*        sideMargin={"sm"}*/}
                    {/*        onClick={() => handleOpenMarkAsCompletedModal()}/>}*/}
                </div>
                <div className='pt-3'>
                    {renderMultilineRemark(comments)}
                    <DLText
                        id={'remark'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={"by " + getAdvisoryUserName(createdBy) + " on " + (isEmpty(createdAt) ? "-" : convertUTCToLocalTime(createdAt, "DD-MM-YYYY"))}
                        fontColor={'grayDark'}
                    />
                </div>
            </div>
        )
    };

    const renderClientRequestDesktopData = () => {
        return (
            <div className="row" style={{
                minHeight:
                    profile?.isCRM && (status === "IN_PROGRESS" || status === "OPEN")
                        ? 'calc(100dvh - 195px)'
                        : 'calc(100dvh - 180px)',
                maxHeight:
                    profile?.isCRM && (status === "IN_PROGRESS" || status === "OPEN")
                        ? 'calc(100dvh - 195px)'
                        : 'calc(100dvh - 180px)',
            }}>
                <div className='col-lg-8'>
                    <div className='d-flex flex-column h-100'>
                        <div style={{marginBottom : '22px'}}>
                            {renderRequestDesktopDetails()}
                        </div>
                        <div className='flex-grow-1'>
                            {renderNSETransactionDesktopDetails()}
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 bg-light '>
                    {renderEarlierRequestsDesktopDetails()}
                </div>
            </div>
        )
    };

    const renderModalError = () => {
        if (responseError) {
            return (
                <div className='mb-3 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            )
        }
    };

    const renderDesktopUI = () => {
        return (
            <div className='d-flex flex-column bg-light h-100'>
                {renderDesktopHeader()}
                <div className="page-container">
                    {renderModalError()}
                </div>
                <div className='page-container flex-grow-1 h-100'>
                    {renderClientRequestDesktopData()}
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    if (isEmpty(UIState)) {
        return renderLoader()
    }

    const renderButtons = () => {
        if(profile?.isManagement){
            return;
        }
        if(status === "IN_PROGRESS" || status === "OPEN") {
            return (
                <div className="sticky-bottom-buttons gap-0">
                    <DLButton
                        id={'discardBtn'}
                        history={history}
                        label={'Discard'}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        type={'danger'}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            handleOpenDiscardTransactionModal()
                        }}/>
                </div>
            )
        }
    };

    const renderDiscardTransactionModal = () => {
        const {isMobileView, handleCloseDiscardTransactionModal, isDiscardTransactionModalOpen} = props;
        if (isDiscardTransactionModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}}
                            onClick={handleCloseDiscardTransactionModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <DiscardTransaction {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <DiscardTransaction {...props}/>
                )
            }
        }

    };

    return (
        <div className='h-100 bg-light'>
            {renderDesktopUI()}
            {renderLoader()}
            {renderAddTransactionModal()}
            {renderMarkAsCompletedModal()}
            {renderButtons()}
            {renderDiscardTransactionModal()}
        </div>
    );
};

export default ClientRequestDetailsView;