import React, {useState} from 'react';

import {removeNFTDetails} from "../../../../NFTAction";
import {connect} from "react-redux";

import RemoveNFTDetailsView from "./RemoveNFTDetailsView";
import store from "../../../../../../store";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";


const RemoveNFTDetails = (props) => {

    const [deleteLeadState, setDeleteLeadState] = useState({
        isApiInProgress: false,
        handleErrorInComponent:true,
        modalErrorMessage:'',
    });

    const clearData = () => {
        props.history.goBack();
    };

    const removeNFT = () => {
        const {id, handleHistoryBlockRefresh, selectedName, ticketId} = props;

        setDeleteLeadState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        let payload = {
            id: id
        };
        removeNFTDetails(payload)
            .then((res) => {
                if (res.success) {
                    store.dispatch(showToastMessage('info', 'Request: Ticket '+ticketId+' for '+ selectedName + ' is discarded'))
                    handleHistoryBlockRefresh(true);
                    clearData();
                    setDeleteLeadState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false
                    }));
                } else {
                    setDeleteLeadState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false,
                        modalErrorMessage:res.__error
                    }));
                }
            }
        );
    };

    return (
        <RemoveNFTDetailsView
            {...props}
            {...deleteLeadState}
            removeNFT={removeNFT}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(RemoveNFTDetails);
