import React from 'react';

import {isEmpty} from "../../../utils/validations";
import {connect} from "react-redux";

const FilterBadgeView = (props) => {
    const {badgeData, onClose, isMobileView} = props;
    const {title,data} = badgeData;
    return (
        !isEmpty(title) && <div className={ isMobileView ? 'badge-light px-3 ms-0' : 'badge-light px-3'}  style={{lineHeight:'26px'}}>
            {title}{data} <i className="fa-solid fa-xmark ps-2 cursor" onClick={onClose}/>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(FilterBadgeView);