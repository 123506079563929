import appTheme from "../../assets/appTheme";

let dlAppTheme = {};

export const setTheme = (theme) => {
  if(isEmpty(theme)){
    return;
  }
  if (theme === dlAppTheme) {
    return;
  }
  dlAppTheme = theme
};

export const getTheme = () => {
  return dlAppTheme
};

export const isEmpty = value =>
  value === undefined ||
  value === 'undefined' ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);


export const getRandomId = (idLength) => {
  let stringList = '0123456789abcdefghijklmnopqrstuvwxyz';
  return stringList.split('').map(function (v, i, a) {
    return i > idLength ? null : a[Math.floor(Math.random() * stringList.length)]
  }).join('');
};


export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    let context = this, args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};



export const getSortedObjectArray = (arr, sortBy) => {
  if (isEmpty(arr)) {
    return arr;
  }
  return arr.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    return 0;
  });
};



export const getFontFamily = () => {
    return appTheme.default.fontFamily
 };


export const getFileExtension = (fileNameOnServer) => {
  if (isEmpty(fileNameOnServer)) {
    return "";
  }
  return fileNameOnServer.split('.').pop().toLowerCase();
};


export const getThemeColor = (fontColor) => {
  let theme = getTheme();
  switch (fontColor) {
    case 'primary':
      return theme?.appColor?.primary;
    case 'secondary':
      return theme?.appColor?.secondary;
    case 'tertiary':
      return theme?.appColor?.tertiary;
    case 'success':
      return theme?.appColor?.success;
    case 'successLight':
      return theme?.appColor?.successLight;
    case 'danger':
      return theme?.appColor?.danger;
    case 'darkBlue':
      return theme?.appColor?.darkBlue;
    case 'lightBlue':
      return theme?.appColor?.lightBlue;
    case 'grayLight':
      return theme?.appColor?.grayLight;
    case 'grayDark':
      return theme?.appColor?.grayDark;
    case 'gray':
      return theme?.appColor?.gray;
    case 'white':
      return theme?.appColor?.white;
    case 'disable':
      return theme?.appColor?.disable;
    case 'black':
      return theme?.appColor?.black;
    default:
      return theme?.default?.color;
  }

};

export const getThemeFontSize = (fontSize) => {
  let theme = getTheme();

  if(fontSize === 'xxs'){
    if(isEmpty(theme?.fontSizes?.xxs)){
      return appTheme.fontSizes.xxs
    }
    return theme?.fontSizes?.xxs;
  }
  if(fontSize === 'xs'){
    if(isEmpty(theme?.fontSizes?.xs)){
      return appTheme.fontSizes.xs
    }
    return theme?.fontSizes?.xs;
  }
  if(fontSize === 'sm'){
    if(isEmpty(theme?.fontSizes?.sm)){
      return appTheme.fontSizes.sm
    }
    return theme?.fontSizes?.sm;
  }
  if(fontSize === 'md'){
    if(isEmpty(theme?.fontSizes?.md)){
      return appTheme.fontSizes.md
    }
    return theme?.fontSizes?.md;
  }
  if(fontSize === 'lg'){
    if(isEmpty(theme?.fontSizes?.lg)){
      return appTheme.fontSizes.lg
    }
    return theme?.fontSizes?.lg;
  }
  if(fontSize === 'xl'){
    if(isEmpty(theme?.fontSizes?.xl)){
      return appTheme.fontSizes.xl
    }
    return theme?.fontSizes?.xl;
  }
};

export const getFontSize = (fontSize) => {
  let style;
  switch (fontSize) {
    case 'xxs':
      style = {
        fontSize: appTheme.fontSizes.xxs,
      };
      break;
    case 'xs':
      style = {
        fontSize: appTheme.fontSizes.xs,
      };
      break;
    case 'sm':
      style = {
        fontSize:  appTheme.fontSizes.sm,
      };
      break;
    case 'md':
      style = {
        fontSize: appTheme.fontSizes.md,
      };
      break;
    case 'lg':
      style = {
        fontSize: appTheme.fontSizes.lg,
      };
      break;
    case 'xl':
      style = {
        fontSize: appTheme.fontSizes.xl,
      };
      break;
    case 'xxl':
      style = {
        fontSize: appTheme.fontSizes.xxl,
      };
      break;
    case 'xxxl':
      style = {
        fontSize: appTheme.fontSizes.xxxl,
      };
      break;
    default:
      style = {
        fontSize: appTheme.fontSizes.md,
      }
  }
  return style;
};

export const getFontColor = (fontColor) => {
  let theme = getTheme();
  let color = "";
  switch (fontColor) {
    case 'primary':
      color = theme?.appColor?.primary;
      break;
    case 'secondary':
      color = theme?.appColor?.secondary;
      break;
    case 'tertiary':
      color = theme?.appColor?.tertiary;
      break;
    case 'success':
      color = theme?.appColor?.success;
      break;
      case 'successLight':
      color = theme?.appColor?.successLight;
      break;
    case 'danger':
      color = theme?.appColor?.danger;
      break;
    case 'darkBlue':
      color = theme?.appColor?.darkBlue;
      break;
    case 'lightBlue':
      color = theme?.appColor?.lightBlue;
      break;
    case 'grayLight':
      color = theme?.appColor?.grayLight;
      break;
    case 'grayDark':
      color = theme?.appColor?.grayDark;
      break;
    case 'gray':
      color = theme?.appColor?.gray;
      break;
    case 'white':
      color = theme?.appColor?.white;
      break;
    case 'disable':
      color = theme?.appColor?.disable;
      break;
    case 'black':
      color = theme?.appColor?.black;
      break;
    default:
      color = theme?.default?.color;
  }
  return color;
};

export const getFontWeight = (fontWeight) => {
  if(fontWeight === 'bold'){
    return {
      fontWeight: 600
    }
  }
  if(fontWeight === 'semi-bold'){
    return {
      fontWeight: 500
    }
  }
  return {
    fontWeight: 400
  }
};

export const getBgColor = (fontColor) => {
  let theme = getTheme();
  let color = "";
  switch (fontColor) {
    case 'primary':
      color = theme?.appColor?.primary;
      break;
    case 'secondary':
      color = theme?.appColor?.secondary;
      break;
    case 'tertiary':
      color = theme?.appColor?.tertiary;
      break;
    case 'success':
      color = theme?.appColor?.success;
      break;
    case 'successLight':
      color = theme?.appColor?.successLight;
      break;
    case 'danger':
      color = theme?.appColor?.danger;
      break;
    case 'darkBlue':
      color = theme?.appColor?.darkBlue;
      break;
    case 'lightBlue':
      color = theme?.appColor?.lightBlue;
      break;
    case 'grayLight':
      color = theme?.appColor?.grayLight;
      break;
    case 'grayDark':
      color = theme?.appColor?.grayDark;
      break;
    case 'gray':
      color = theme?.appColor?.gray;
      break;
    case 'white':
      color = theme?.appColor?.white;
      break;
    case 'disable':
      color = theme?.appColor?.disable;
      break;
    case 'black':
      color = theme?.appColor?.black;
      break;
    case 'transparent':
      color = "transparent";
      break;
    default:
      color = theme?.appColor?.white;
  }
  return color;
};

