import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLText from "../../../../../core/components/DLText/DLText";
import DLModalActions from "../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLModalContainer from "../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalTitle from "../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModal from "../../../../../core/components/DLModal/DLModal";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import React from "react";


const DiscardTransactionView = (props) => {

    const {
        history, isDiscardTransactionModalOpen,
        isApiInProgress, handleDiscard, handleCloseDiscardTransactionModal, selectedName
    } = props;

    const renderUiText = () => {
        return (
            <div className='mb-2 pb-1 text-center'>
                <DLText id={''}
                        text={"Are you sure you want to discard this request for"}
                        fontSize={"sm"}
                        fontWeight={"normal"}
                        marginBottom={"none"}
                />
                <DLText id={''}
                        text={selectedName + "?"}
                        fontSize={"sm"}
                        fontWeight={"semi-bold"}
                />
            </div>
        )
    };

    const renderButtons = () => {
        return (
            <div>
                <DLButton
                    id={'cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => handleCloseDiscardTransactionModal()}/>
                <DLButton
                    id={'Btn'}
                    history={props.history}
                    label={'Discard'}
                    buttonSize={"sm"}
                    type={"danger"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => handleDiscard()}
                />
            </div>
        )
    }

    const renderDiscardModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"xs"}
                    onClose={() => handleCloseDiscardTransactionModal()}
                    open={isDiscardTransactionModalOpen}
                    id={'discard-modal'}>
                    <DLModalTitle
                        id={'discard-title'}
                        onClose={() => handleCloseDiscardTransactionModal()}
                        title={'Confirmation'}/>
                    <DLModalContainer
                        id={'discard-container'}>
                        <div>
                            {renderUiText()}
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'discard-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            {renderButtons()}
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const overlayDiscardModal = () => {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='p-3'>
                        <DLText
                            id={'discard-text'}
                            fontSize={'md'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            text={'Confirmation'}
                        />
                    </div>
                    <div className='p-3'>
                        <i className="fa-solid fa-xmark cursor default-icon"
                           onClick={() => handleCloseDiscardTransactionModal()}/>
                    </div>
                </div>
                <div className='px-3'
                     style={{
                         maxHeight: 'calc(100dvh - 190px)',
                         overflow: 'auto',
                     }}>
                    <div className='pb-5'>
                        <div className='my-4'>
                            {renderUiText()}
                        </div>
                    </div>
                </div>
                <div className='fixed-bottom mb-2'>
                    <div className='mb-2 d-flex justify-content-center bg-white'
                         style={{paddingTop: '7px', minHeight: '43px'}}>
                        {renderButtons()}
                    </div>
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (
            <DLLoader type={"screen"} isVisible={isApiInProgress}/>
        )
    }

    return (
        <div>
            {renderLoader()}
            {props.isOverlay ? overlayDiscardModal() : renderDiscardModal()}
        </div>
    );
};

export default DiscardTransactionView;