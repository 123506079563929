import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import MajorNomineeCreateOrUpdateView from "./MajorNomineeCreateOrUpdateView";
import {getDocAssociateData, getPersonalProfileData, updateNominee} from "../../OnboardingActions";
import {isEmpty} from "../../../utils/validations";
import {isAlphabetic, isIncludeSpecialCharacters, validateAge} from "../../../utils/helper";
import store from "../../../store";
import {showToastMessage} from "../../../AppComponents/AppActions";

const MajorNomineeCreateOrUpdate = (props) => {

    const {nomineeId, requiredDocuments, nomineeType, nomineeData, dropdownValues} = props;

    const [majorNomineeState, setMajorNomineeState] = useState({
        personalInformation : {
            type : "N",  // N is major Y is minor
            dateOfBirth: null,
            name: "",
            PANNumber: "",
            relation: "",
            percentageAllocation: 1,
        },
        nomineeAddress:{
            countryCode: "IND",
            city: "",
            stateCode: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            zipCode: ""
        },
        document: {
            documentType:"NOMINEE_PAN_CARD",
            documentCategory:"NOMINEE_PAN_CARD",
            originalFileName: '',
            uploadedFileName: '',
            filePath: '',
            fileName: '',
            PAN_CARD: '',
            id: '',
        },
        errors:{
            personalInformation : {
                dateOfBirth: "",
                name: "",
                PANNumber: "",
                relation: "",
                percentageAllocation:"",
            },
            nomineeAddress:{
                countryCode: "",
                city: "",
                stateCode: "",
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                zipCode: ""
            },
            document:{
                PAN_CARD: ''
            },
        },
        isLoaderVisible: false,
        scrollToFieldId: '',
        responseErrors: [],
        totalErrors: '',
        docs : []
    });

    useEffect(()=>{
        setNomineeDetails()
    },[nomineeData,dropdownValues]);

    useEffect(()=>{
        setDocuments()
    },[majorNomineeState.docs]);

    const setNomineeDetails = () =>{
        if(nomineeType !== "NEW" && nomineeData?.type === "N"){
            getRequiredDocuments();
            setMajorNomineeState((prevState)=>({
                ...prevState,
                personalInformation : {
                    type : "N",  // N is major Y is minor
                    dateOfBirth: isEmpty(nomineeData?.dateOfBirth)? null : nomineeData?.dateOfBirth,
                    name: isEmpty(nomineeData?.name)? "" : nomineeData?.name,
                    PANNumber: isEmpty(nomineeData?.PANNumber)? "" : nomineeData?.PANNumber,
                    relation: isEmpty(nomineeData?.relation)? "" : getDropdownSelectedObj("relations", nomineeData?.relation, dropdownValues),
                    percentageAllocation: isEmpty(nomineeData?.percentageAllocation)? 1 : nomineeData?.percentageAllocation,
                },
                nomineeAddress:{
                    countryCode: "IND",
                    city: isEmpty(nomineeData?.nomineeAddress?.city)? "" : nomineeData?.nomineeAddress?.city,
                    stateCode: isEmpty(nomineeData?.nomineeAddress?.stateCode)? "" : getDropdownSelectedObj("states", nomineeData?.nomineeAddress?.stateCode, dropdownValues),
                    addressLine1: isEmpty(nomineeData?.nomineeAddress?.addressLine1)? "" : nomineeData?.nomineeAddress?.addressLine1,
                    addressLine2: isEmpty(nomineeData?.nomineeAddress?.addressLine2)? "" : nomineeData?.nomineeAddress?.addressLine2,
                    addressLine3: isEmpty(nomineeData?.nomineeAddress?.addressLine3)? "" : nomineeData?.nomineeAddress?.addressLine3,
                    zipCode: isEmpty(nomineeData?.nomineeAddress?.zipCode)? "" : nomineeData?.nomineeAddress?.zipCode
                },

            }))
        }
    }

    const getRequiredDocuments = () =>{
        if(isEmpty(requiredDocuments)){
            return;
        }
        let data = "";
        requiredDocuments.forEach((item)=>{
            if(item.holderType === "NOMINEE"){
                data = item?.documentCategory.documentTypes
            }
        });

        setMajorNomineeState(prevStep => ({
            ...prevStep,
            docs : data
        }));
    };

    const setDocuments = () =>{
        const {docs} =majorNomineeState;
        let requiredDocuments = [];
        docs.map((item)=>{
            if( item.id=== "NOMINEE_PAN_CARD"){
                requiredDocuments = item?.documents
            }
        });

        if(!isEmpty(requiredDocuments)){
            setMajorNomineeState(prevStep => ({
                ...prevStep,
                document: {
                    ...prevStep.document,
                    filePath: isEmpty(requiredDocuments[0]?.imageFilePaths) ? '' : requiredDocuments[0]?.imageFilePaths[0],
                    fileName: requiredDocuments[0]?.fileName,
                    id: requiredDocuments[0]?.id
                },
            }));
        }
    };

    const getDropdownSelectedObj=(type,selectedValue, dropDownValues)=>{
        if(isEmpty(selectedValue)){
            return ''
        }
        return dropDownValues[type]?.find(dropdownVal=>{
            return dropdownVal.value === selectedValue})
    };


    const handleChange = (name, value, fieldType) => {
        let updatedCompState = {...majorNomineeState};

        if (fieldType === "personalInformation") {
            updatedCompState.personalInformation[name] = value;
        } else if (fieldType === "nomineeAddress") {
            updatedCompState.nomineeAddress[name] = value;
        }else {
            updatedCompState[name] = value;
        }


        const validateObject = validateChanges(updatedCompState, name, fieldType);

        setMajorNomineeState(prevStep => ({
            ...prevStep,
            ...updatedCompState,
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));

    };

    const validatePersonalInformation = (componentState, attr) => {
        const {personalInformation, errors} = componentState;
        const {name, dateOfBirth,
            PANNumber, relation, percentageAllocation} = personalInformation;

        let errorObj = {...errors.personalInformation};

        if (attr === 'name' || attr === 'SUBMIT') {
            if (isEmpty(name)) {
                errorObj.name = 'Please enter name'
            }  else if (isIncludeSpecialCharacters(name) || isAlphabetic(name)) {
                errorObj.name = 'Please enter alphabets only'
            } else if (name.length > 62) {
                errorObj.name = 'Please enter 60 alphabets only'
            } else {
                errorObj.name = ''
            }

            if (attr === 'name') {
                return errorObj
            }
        }



        if (attr === 'PANNumber' || attr === 'SUBMIT') {

            if (!isEmpty(PANNumber) && isIncludeSpecialCharacters(PANNumber)) {
                errorObj.PANNumber = 'Please enter valid PAN number'
            } else if (!isEmpty(PANNumber) && PANNumber?.length !== 10) {
                errorObj.PANNumber = 'PAN number must be of 10 characters'
            } else {
                errorObj.PANNumber = ''
            }
            if (attr === 'PANNumber') {
                return errorObj
            }
        }

        if (attr === 'dateOfBirth' || attr === 'SUBMIT') {
            if (isEmpty(dateOfBirth)) {
                errorObj.dateOfBirth = 'Please enter date of birth'
            } else if (validateAge(dateOfBirth, 18)) {
                errorObj.dateOfBirth = 'Applicant age must be above 18'
            }else {
                errorObj.dateOfBirth = ''
            }

            if (attr === 'dateOfBirth') {
                return errorObj
            }
        }

        if (attr === 'relation' || attr === 'SUBMIT') {
            if (isEmpty(relation)) {
                errorObj.relation = 'Please select relation'
            }else {
                errorObj.relation = ''
            }

            if (attr === 'relation') {
                return errorObj
            }
        }

        if (attr === 'percentageAllocation' || attr === 'SUBMIT') {
            if (isEmpty(percentageAllocation)) {
                errorObj.percentageAllocation = 'Please enter percentage allocation'
            }else if (percentageAllocation > 100) {
                errorObj.percentageAllocation = 'Allocation can not be more than 100 %'
            }else if (percentageAllocation < 0) {
                errorObj.percentageAllocation = 'Allocation can not be less than 0 %'
            }else {
                errorObj.percentageAllocation = ''
            }

            if (attr === 'percentageAllocation') {
                return errorObj
            }
        }

        return errorObj
    };

    const getRequiredDocumentId = () =>{
        if(isEmpty(requiredDocuments)){
            return;
        }
        let requirementId = "";

        requiredDocuments.forEach((item)=>{
            if(item.holderType === "NOMINEE"){
                requirementId = item?.id
            }
        });

        return requirementId;
    };

    const validateAddress = (componentState, name) => {
        const {nomineeAddress, errors} = componentState;
        const {addressLine1, addressLine2, addressLine3, zipCode, stateCode, city} = nomineeAddress;


        let errorObj = {...errors.nomineeAddress};

        if ((name === 'addressLine1') || name === 'SUBMIT') {
            if (isEmpty(addressLine1)) {
                errorObj.addressLine1 = "Please enter address line 1"
            }else if (addressLine1.length > 50) {
                errorObj.addressLine1 = "Please enter 50 characters only"
            } else {
                errorObj.addressLine1 = ""
            }
            if (name === 'addressLine1') {
                return errorObj
            }
        }

        if ((name === 'addressLine2') || name === 'SUBMIT') {
            if (addressLine2.length > 50) {
                errorObj.addressLine2 = "Please enter 50 characters only"
            }else {
                errorObj.addressLine2 = ""
            }
            if (name === 'addressLine2') {
                return errorObj
            }
        }

        if ((name === 'addressLine3') || name === 'SUBMIT') {
            if (addressLine3.length > 50) {
                errorObj.addressLine3 = "Please enter 50 characters only"
            } else {
                errorObj.addressLine3 = ""
            }
            if (name === 'addressLine3') {
                return errorObj
            }
        }

        if ((name === 'city') || name === 'SUBMIT') {
            if (isEmpty(city)) {
                errorObj.city = "Please enter city"
            }else if (city.length > 30) {
                errorObj.city = "Please enter 30 characters only"
            } else {
                errorObj.city = ""
            }
            if (name === 'city') {
                return errorObj
            }
        }

        if ((name === 'zipCode') || name === 'SUBMIT') {

            if (isEmpty(zipCode)) {
                errorObj.zipCode = "Please enter pincode"
            }else if (zipCode.length > 6) {
                errorObj.zipCode = "Please enter 6 characters only"
            } else {
                errorObj.zipCode = ""
            }
            if (name === 'zipCode') {
                return errorObj
            }
        }

        if ((name === 'stateCode') || name === 'SUBMIT') {
            if (isEmpty(stateCode)) {
                errorObj.stateCode = "Please select state"
            } else {
                errorObj.stateCode = ""
            }
            if (name === 'stateCode') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateChanges = (componentState, name, fieldType) => {
        const {errors} = componentState;
        let errorObj = errors;

        if (fieldType === 'personalInformation' || isEmpty(fieldType)) {
            errorObj.personalInformation = validatePersonalInformation(componentState, name);
        }

        if (fieldType === 'nomineeAddress' || isEmpty(fieldType)) {
            errorObj.nomineeAddress = validateAddress(componentState, name);
        }

        return errorObj;
    };


    const simplifyObjectValues = (input) => {
        // Create a copy of the input object to avoid mutating the original object
        const output = {...input};

        // Iterate through the keys of the object
        for (const key in output) {
            // If the property's value is an object and has a 'value' key, replace it
            if (typeof output[key] === 'object' && output[key] !== null && 'value' in output[key]) {
                output[key] = output[key].value;
            }
        }
        return output;
    };

    const onSaveClick = () => {
        const {isProceedWithErrors, accountId, handleCloseCreateModal, onboardingApplicationId} = props;

        setMajorNomineeState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));


        const validateObject = validateChanges(majorNomineeState, 'SUBMIT');
        let updatedData = {
            ...majorNomineeState,
            ...majorNomineeState.personalInformation
        };

        delete updatedData.personalInformation;
        delete updatedData.errors;
        delete updatedData.isLoaderVisible;
        delete updatedData.scrollToFieldId;
        delete updatedData.responseErrors;
        delete updatedData.totalErrors;
        delete updatedData.document;

        let nomineeDataObj = {
            ...updatedData,
        };
        let errorId = '';
        nomineeDataObj = simplifyObjectValues(nomineeDataObj);
        nomineeDataObj.nomineeAddress = simplifyObjectValues(updatedData.nomineeAddress);
        nomineeDataObj.accountId = accountId;
        nomineeDataObj.nomineeId = nomineeId;
        nomineeDataObj.onboardingApplicationId = onboardingApplicationId;


        updateNominee(nomineeDataObj)
            .then(res => {
                if (res.success) {
                    if(isEmpty(res.data?.validationErrors)){
                        handleCloseCreateModal();
                        setMajorNomineeState(prevState => ({
                            ...prevState,
                            isLoaderVisible: true
                        }));
                    }else{
                        store.dispatch(showToastMessage('success', 'Error exists but partial data is saved.'))
                        assignResponseError(res.data, validateObject)
                    }
                } else {
                    let responseErrors = [];
                    if(res?.__error){
                        responseErrors.push(res?.__error);
                        errorId= 'page-level-errors'
                    }
                    setMajorNomineeState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                            ...validateObject,
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                        scrollToFieldId: errorId
                    }));
                }
                if (isProceedWithErrors) {
                    handleCloseCreateModal();
                    setMajorNomineeState(prevState => ({
                        ...prevState,
                        isLoaderVisible: true
                    }));
                }
            })
    };

    const assignResponseError = (response, stateErrors) => {

        const errorResponse = response?.validationErrors;

        let newStateErrors = {...stateErrors};
        let responseErrors = [];

        if (isEmpty(errorResponse)) { //handled state if reponse status is failed
            setMajorNomineeState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...newStateErrors,
                },
                isLoaderVisible: false,
                responseErrors: [],
                totalErrors: 0,
            }));
            return
        }

        errorResponse.forEach(error => { //assign each field by section name
            const section = error.section;
            if (!newStateErrors[section]) {
                return;
            }
            if ((error.fields).length > 1) { //add page level validation error
                responseErrors.push(error.message)
            } else {
                //assign each field error to respected field and log if invalid field is found
                error.fields.forEach(field => {
                    newStateErrors[section][field] = error.message;
                });
            }
        });
        let errors = {
            ...majorNomineeState.errors,
            ...newStateErrors,
        };
        setMajorNomineeState(prevStep => ({
            ...prevStep,
            errors: {
                ...prevStep.errors,
                ...newStateErrors,
            },
            responseErrors: responseErrors,
            totalErrors: responseErrors.length,
            isLoaderVisible: false,
            scrollToFieldId: isEmpty(responseErrors) ? getFirstErrorFieldId(errors) : 'page-level-errors'
        }));
    };

    const getFirstErrorFieldId = (errors) => {
        let sectionOrderToSort =["personalInformation", "nomineeAddress"];
        for (const section of sectionOrderToSort) {
            if (errors[section]) {
                for (const key in errors[section]) {

                    const error = errors[section][key];
                    if (error && typeof error === 'object' && 'label' in error && error.label !== 'type') {
                        return section + '-' + error.label + '-id';
                    }
                    if (typeof error === 'string' && error && key !== 'type') {
                        return section + '-' + key + '-id';
                    }
                }
            }
        }
        return null;
    };

    const associateDocument = (type, response) => {
        const {accountId, onboardingApplicationId} = props;
        const {document} = majorNomineeState;

        if (type === 'DELETE') {
            setMajorNomineeState(prevStep => ({
                ...prevStep,
                document: {
                    ...document,
                    filePath: ''
                },
                errors: {
                    ...prevStep.errors,
                    document: {
                        ...prevStep.errors.document,
                        PAN_CARD: ''
                    }
                },
                responseErrors: [],
                totalErrors: 0,
            }));
            return;
        }

        setMajorNomineeState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true,
        }));

        const associatePayload = {
            type: document.documentType,
            accountId: accountId,
            nomineeId: nomineeId,
            documentRequirementId: getRequiredDocumentId(),
            originalFileName: response?.originalFileName,
            uploadedFileName: response?.uploadedFileName,
            onboardingApplicationId: onboardingApplicationId
        };

        getDocAssociateData(associatePayload)
            .then((res) => {
                if (res.success) {
                    const mappedState = getMappedData(res.data.documentDetails);
                    setMajorNomineeState(prevStep => ({
                        ...prevStep,
                        ...mappedState,
                        document: {
                            ...document,
                            filePath: (isEmpty(res.data?.imageFilePaths)) ? '' : res.data?.imageFilePaths[0],
                            fileName: res.data?.fileName,
                            id: res.data?.id
                        },
                        isLoaderVisible: false,
                        responseErrors: [],
                        totalErrors: 0,
                    }));
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setMajorNomineeState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                            document: {
                                ...prevStep.errors.document,
                                PAN_CARD: '',
                                filePath: '',
                                id: ''
                            }
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                    }));
                }
            })
    };

    const getMappedData = (response) => {
        const {errors} = majorNomineeState;
        if (isEmpty(response)) {
            return {...majorNomineeState}
        }
        return {
            personalInformation: mapPANInformation(response),
            errors: {
                ...errors,
                ...mapPANInformationErrors(errors, response),
            },
        };
    };

    const mapPANInformation = (PANDetails) => {
        let personDetails = PANDetails;
        return {
            ...majorNomineeState.personalInformation,
            name: isEmpty(personDetails?.nameAsPerPAN) ? '' : personDetails.nameAsPerPAN,
            PANNumber: isEmpty(personDetails?.PANNumber) ? '' : personDetails.PANNumber,
            dateOfBirth: isEmpty(personDetails?.dateOfBirth) ? '' : personDetails.dateOfBirth,
        };
    };

    const mapPANInformationErrors = (errors, PANDetails) => {

        let errorPANInformationObj = {...errors.personalInformation};
        if (!isEmpty(PANDetails?.nameAsPerPAN)) {
            errorPANInformationObj.name = ''
        }
        if (!isEmpty(PANDetails?.PANNumber)) {
            errorPANInformationObj.PANNumber = ''
        }
        if (!isEmpty(PANDetails?.dateOfBirth)) {
            errorPANInformationObj.dateOfBirth = ''
        }

        return {
            personalInformation: errorPANInformationObj
        };
    };

    const getMappedAddressData = (response) => {
        const {errors} = majorNomineeState;
        if (isEmpty(response) || response?.length === 0) {
            return {...majorNomineeState}
        }
        return {
            nomineeAddress: mapNomineeAddress(response[0]),
            errors: {
                ...errors,
                ...mapNomineeAddressErrors(errors, response[0]),
            },
        };
    };

    const mapNomineeAddress = (addressDetails) => {
        let personAddressDetails = addressDetails;
        return {
            ...majorNomineeState.nomineeAddress,
            countryCode: isEmpty(personAddressDetails?.countryCode) ? "IND" : personAddressDetails?.countryCode,
            city: isEmpty(personAddressDetails?.city) ? "" : personAddressDetails?.city,
            stateCode: isEmpty(personAddressDetails?.stateCode) ? "" : getDropdownSelectedObj("states",personAddressDetails?.stateCode, dropdownValues),
            addressLine1: isEmpty(personAddressDetails?.addressLine1) ? "" : personAddressDetails?.addressLine1,
            addressLine2: isEmpty(personAddressDetails?.addressLine2) ? "" : personAddressDetails?.addressLine2,
            addressLine3: isEmpty(personAddressDetails?.addressLine3) ? "" : personAddressDetails?.addressLine3,
            zipCode: isEmpty(personAddressDetails?.zipCode) ? "" : personAddressDetails?.zipCode
        };
    };

    const mapNomineeAddressErrors = (errors, addressDetails) => {

        let errorAddressObj = {...errors.nomineeAddress};
        if (!isEmpty(addressDetails?.countryCode)) {
            errorAddressObj.countryCode = ''
        }
        if (!isEmpty(addressDetails?.city)) {
            errorAddressObj.city = ''
        }
        if (!isEmpty(addressDetails?.stateCode)) {
            errorAddressObj.stateCode = ''
        }
        if (!isEmpty(addressDetails?.addressLine1)) {
            errorAddressObj.addressLine1 = ''
        }
        if (!isEmpty(addressDetails?.addressLine2)) {
            errorAddressObj.addressLine2 = ''
        }
        if (!isEmpty(addressDetails?.addressLine3)) {
            errorAddressObj.addressLine3 = ''
        }
        if (!isEmpty(addressDetails?.zipCode)) {
            errorAddressObj.zipCode = ''
        }

        return {
            nomineeAddress: errorAddressObj
        };
    };

    const getFirstApplicantAddressData = (id) =>{
        const {onboardingApplicationId} = props;
        if(isEmpty(id)){
            return;
        }

        setMajorNomineeState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true,
        }));

        getPersonalProfileData(id, onboardingApplicationId)
            .then(res => {
                if (res.success) {
                    const mappedState = getMappedAddressData(res.data?.personAddresses, dropdownValues);
                    setMajorNomineeState(prevStep => ({
                        ...prevStep,
                        ...mappedState,
                        isLoaderVisible: false,
                        responseErrors: [],
                        totalErrors: 0,
                    }));
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setMajorNomineeState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                    }));
                }
            })
    }

    const getFirstApplicantData = () =>{
        const{accountHolderDetails} = props;
        let primaryHolder = [];

        if(!isEmpty(accountHolderDetails)){
            primaryHolder = accountHolderDetails.find((item)=>{
                return item?.holderType === "PRIMARY_HOLDER";
            })
            getFirstApplicantAddressData(primaryHolder?.personId)
        }
    }

    return (
        <MajorNomineeCreateOrUpdateView {...props}
                                        {...majorNomineeState}
                                        handleChange={handleChange}
                                        onSaveClick={onSaveClick}
                                        associateDocument={associateDocument}
                                        getFirstApplicantData={getFirstApplicantData}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    accountId: state.onboardingFlowState.accountId,
    onboardingApplicationId: state.onboardingFlowState.onboardingApplicationId
});

export default connect(mapStateToProps, {})(MajorNomineeCreateOrUpdate);