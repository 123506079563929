import React from 'react';

import {getRoundedvalue, isCorporateUser, numberWithCommas} from "../../utils/helper";
import {isEmpty} from "../../core/components/DLComponentHelper";

import DLText from "../../core/components/DLText/DLText";
import DLButton from "../../core/components/DLButton/DLButton";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import ApproveTransaction from "../components/approveTransaction/ApproveTransaction";
import RejectTransaction from "../components/rejectTransaction/RejectTransaction";
import ApprovedOrRejected from "../approvedOrRejectedTransaction/ApprovedOrRejected";
import appTheme from "../../assets/appTheme";
import moment from "moment";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import logo from "../../assets/img/simplicity_logo.jpg";

const ClientsApprovalView = (props) => {
    const {
        isMobileView, IINNumber, accountType, isShowLoader
        , UIState, paymentDetails, investmentDetails, ticketNumber, txnData, handleOpenApproveTransactionModal,
        handleOpenRejectTransactionModal, transactionStatus, approvalTransactionStatus
    } = props;

    const handleAmount = (amount) => {
        if (isEmpty(amount)) {
            return "-";
        }
        return numberWithCommas(amount)
    }

    const renderApproveTransactionModal = () => {
        const {isApproveTransactionModalOpen} = props;
        if (isApproveTransactionModalOpen) {
            return (
                <ApproveTransaction {...props}/>
            )
        }

    };

    const renderRejectTransactionModal = () => {
        const {isRejectTransactionModalOpen} = props;

        if (isRejectTransactionModalOpen) {
            return (
                <RejectTransaction {...props}/>
            )
        }

    };

    const renderAccountDetails = () => {
        const {accountDetails, details, corporateAccountDetails} = props;

        let allAccountHoldersNames = "";
        let accountType = isEmpty(details?.accountDetails) ? "" : details?.accountDetails?.accountType;
        if (!isEmpty(accountDetails)) {
            accountDetails.map((applicant) => {
                if (isEmpty(allAccountHoldersNames)) {
                    allAccountHoldersNames = applicant?.fullName;
                } else {
                    allAccountHoldersNames = allAccountHoldersNames + ', ' + applicant?.fullName;
                }
            });
        }

        if(isCorporateUser(accountType?.toUpperCase())){
            allAccountHoldersNames = corporateAccountDetails?.name;
        }

        return (
            <div key={'accountDetails'}
                 className='d-flex align-items-center '>
                <div>
                    <DLText id={'account-holder-name'}
                            text={allAccountHoldersNames}
                            fontWeight={'bold'}
                            fontSize={'md'}
                            marginBottom={"none"}
                    />
                </div>
            </div>
        );
    };

    const renderHeader = () => {
        if(isMobileView){
            return (
                <div className='w-100'>
                    <div className='ps-3 pb-3'>
                        <div>
                            <div>
                                {renderAccountDetails()}
                            </div>
                            <DLText id={'ac-details-name'}
                                    text={accountType + '-' + IINNumber}
                                    fontSize={"xs"}
                                    fontColor={"grayDark"}
                                    marginBottom={"none"}
                            />
                        </div>
                        <div className='pe-2 pt-2 d-flex align-items-center'>
                            {renderHeaderStatus(approvalTransactionStatus)}
                        </div>
                    </div>

                </div>
            )
        }
        return (
            <div className='w-100'>
                <div className='d-flex align-items-center mb-3 page-container '>
                    <div className='pe-2 border-end-black d-flex'>
                        {renderAccountDetails()}
                    </div>
                    <div className='px-2 border-end-black'>
                        <DLText id={'ac-details-name'}
                                fontWeight={'normal'}
                                fontSize={"md"}
                                fontColor={"grayDark"}
                                text={accountType + '-' + IINNumber}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2'>
                        {renderHeaderStatus(approvalTransactionStatus)}
                    </div>
                </div>
            </div>
        )
    };

    const renderHeaderStatus = (statusType) =>{
        if (statusType === 'Client Rejected') {
            return (<div className="d-flex align-items-center badge-red ms-0">
                <span style={{color: appTheme.appColor.white}}> {statusType} </span>
            </div>);
        } else if (statusType === "Client Approved") {
            return (<div className="d-flex align-items-center badge-green ms-0">
                <span style={{color: appTheme.appColor.white}}> {statusType} </span>
            </div>);
        } else {
            return (<div className="d-flex align-items-center badge-amber ms-0">
                <span style={{color: appTheme.appColor.white}}> {statusType} </span>
            </div>);
        }
    }

    const renderChequePaymentDetails = () => {
        if(isMobileView){
            return (
                <div className="h-100 bg-white border-rounded">
                    <div className='d-flex justify-content-between p-3' style={{borderTop : '1px solid', borderBottom : '1px solid', borderColor : appTheme.appColor.black}}>
                        <DLText
                            id={'payment-details-title'}
                            type={'normal'}
                            text={'Payment Details'}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                    <div className={'p-3'}>
                        <div className='row mb-3'>
                            <div className='col-6'>
                                <DLText id={'payment-mode'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Payment Mode'}/>
                                <DLText id={'payment-mode-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.paymentMechanism?.description}/>
                            </div>
                            <div className='col-6'>
                                <DLText id={'bank-name-&-account-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Bank Name & Account Number'}/>
                                {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-6'>
                                <DLText id={'cheque-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Cheque Number'}/>
                                <DLText id={'cheque-number-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.chequePayment?.chequeNo}/>
                            </div>
                            <div className='col-6'>
                                <DLText id={'micr-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'MICR Number'}/>
                                <DLText id={'micr-number-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.chequePayment?.micrNo}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <DLText id={'date'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Date (date on cheque)'}/>
                                <DLText id={'date-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={isEmpty(paymentDetails?.chequePayment?.chequeDate)? "" : moment(paymentDetails?.chequePayment?.chequeDate).format("DD-MM-YYYY")}/>
                            </div>
                            <div className='col-6'>
                                <DLText id={'cheque-deposite-mode'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Cheque Deposite Mode'}/>
                                <DLText id={'cheque-deposite-mode-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.chequePayment?.checkDepositModeDescription}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className={'pe-3 mt-3'}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.paymentMechanism?.description}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'>
                            <DLText id={'cheque-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Cheque Number'}/>
                            <DLText id={'cheque-number-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.chequeNo}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'micr-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'MICR Number'}/>
                            <DLText id={'micr-number-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.micrNo}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'date'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Date (date on cheque)'}/>
                            <DLText id={'date-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={isEmpty(paymentDetails?.chequePayment?.chequeDate)? "" : moment(paymentDetails?.chequePayment?.chequeDate).format("DD-MM-YYYY")}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'cheque-deposite-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Cheque Deposite Mode'}/>
                            <DLText id={'cheque-deposite-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.checkDepositModeDescription}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderPaymentDetails = () => {
        if (paymentDetails?.paymentMechanism?.description === "CHEQUE") {
            return renderChequePaymentDetails()
        }

        if (isMobileView) {
            return (
                <div className={"h-100 bg-white border-rounded "}>
                    <div className={'d-flex justify-content-between align-items-center p-3 '}  style={{borderTop : '1px solid', borderBottom : '1px solid', borderColor : appTheme.appColor.black}}>
                        <DLText
                            id={'payment-details-title'}
                            type={'normal'}
                            text={'Payment Details'}
                            fontSize={'md'}
                            fontWeight={'bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                    <div className={'pe-3 p-3'}>
                        <div className='row mb-3'>
                            <div className={"mb-3"}>
                                <DLText id={'bank-name-&-account-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Bank Name & Account Number'}/>
                                {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                            </div>
                            <div className={isMobileView ? "" : "col-3"}>
                                <DLText id={'payment-mode'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Payment Mode'}/>
                                <DLText id={'payment-mode-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.paymentMechanism?.description}/>
                            </div>
                        </div>
                        {paymentDetails?.paymentMechanism?.description !== "Bank Mandate" && <div className='row'>
                            <div>
                                <DLText id={'RTGS/IFSC-code'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'RTGS/IFSC Code'}/>
                                <DLText id={'RTGS/IFSC-code-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.bankAccount?.IFSCCode}/>
                            </div>
                        </div>}
                    </div>
                </div>
            )
        }

        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className={'pe-3 mt-3'}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.paymentMechanism?.description}/>
                        </div>
                        <div className='col-9'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                    </div>
                    {paymentDetails?.paymentMechanism?.description !== "Bank Mandate" && <div className='row'>
                        <div className='col-3'>
                            <DLText id={'RTGS/IFSC-code'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'RTGS/IFSC Code'}/>
                            <DLText id={'RTGS/IFSC-code-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.bankAccount?.IFSCCode}/>
                        </div>
                    </div>}
                </div>
            </div>
        )
    };

    const renderBankNameAndAccountNumberUi = (bankAccountDetails) => {
        if (isEmpty(bankAccountDetails?.bankName) && isEmpty(bankAccountDetails?.accountNumber)) {
            return "-"
        }
        return (
            <div>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={bankAccountDetails?.BankMaster?.description}/>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={" - "+bankAccountDetails?.accountNumber}/>
            </div>
        )
    }

    const renderInvestmentDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded p-3 h-100">
                    <div className='d-flex justify-content-between'>
                        <DLText
                            id={'investment-amount-title'}
                            type={'normal'}
                            text={'Investment Amount: ' + handleAmount(txnData?.investmentAmount)}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-center'>
                            <DLText id={''}
                                    text={'No schemes have been added for the funds'}
                                    fontWeight={'normal'}
                                    fontSize={"sm"}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3 ">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'investment-amount-title'}
                        type={'normal'}
                        text={'Investment Amount: ' + handleAmount(txnData?.investmentAmount)}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className='pt-3'>
                    <div className='p-0 w-100 border-rounded panel h-100'>
                        <div className="p-2 h-100 c">
                            {renderHeadings()}
                            {renderData()}
                            {renderTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderHeadings = () => {
        return (
            <div className={"py-2 border-bottom row  px-0 m-0"}>
                <div className='data-card-label col-5'>
                    <DLText id={'schemes'}
                            text={"Schemes"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-3'>
                    <DLText id={'folio-no'}
                            text={"Folio No"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-3'>
                    <div className={'text-end'}  style={{maxWidth: "150px"}}>
                        <DLText id={'allocation'}
                                text={"Allocation"}
                                marginBottom={"none"}
                                fontWeight={"semi-bold"}
                                fontSize={"sm"}
                        />
                    </div>
                </div>
                <div className='data-card-label col-1 text-end'>
                    <DLText id={'amount'}
                            text={"Amount"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
            </div>
        )
    };

    const renderTotalCount = () => {
        return (
            <div className={"py-2 border-top row px-0 m-0"}>
                <div className='data-card-label col-5'/>
                <div className='data-card-label col-3'/>
                <div className='data-card-label col-3'>
                    <div className={'text-end'}  style={{maxWidth: "150px"}}>
                        <DLText id={'total-percentage'}
                                text={isEmpty(txnData?.totalAllocationPercentage) ? "-" : getRoundedvalue(txnData?.totalAllocationPercentage) + "%"}
                                fontWeight={"semi-bold"}
                                marginBottom={"none"}
                                fontSize={"sm"}
                        />
                    </div>
                </div>
                <div className='data-card-label col-1 text-end'>
                    <DLText id={'total-amount'}
                            text={isEmpty(txnData?.totalAllocationAmount) ? "-" : handleAmount(txnData?.totalAllocationAmount)}
                            fontWeight={"semi-bold"}
                            marginBottom={"none"}
                            fontSize={"sm"}
                    />
                </div>
            </div>
        )
    };

    const renderData = () => {
        if(isEmpty(investmentDetails)){
            return '';
        }
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"py-2 row px-0 m-0"}
                                 key={index}>
                                <div className='data-card-label col-5'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={item?.productName}
                                                fontWeight={"semi-bold"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-3'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item?.folioNumber) ? "Create new folio" : item?.folioNumber}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-3'>
                                    <div className={'text-end h-100'}  style={{maxWidth: "150px"}}>
                                        <div className='d-flex align-items-center justify-content-end h-100'>
                                            <DLText id={''}
                                                    text={item?.allocation + "%"}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='data-card-label col-1 text-end' style={{maxWidth: "150px"}}>
                                    <div className='d-flex align-items-center justify-content-end h-100'>
                                        <DLText id={''}
                                                text={handleAmount(item?.amount)}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderButtons = () => {
        if(approvalTransactionStatus !== "Client Approval Pending"){
          return;
        }
        if(isMobileView){
            return (
                <div className="sticky-bottom-buttons gap-0" style={{background: appTheme.appColor.white}}>
                    <DLButton
                        id={'reject-Btn'}
                        history={props.history}
                        label={'Reject'}
                        buttonSize={"sm"}
                        type={"danger"}
                        fontSize={"sm"}
                        sideMargin={"sm"}
                        onClick={() => {
                            handleOpenRejectTransactionModal()
                        }}
                    />
                    <DLButton
                        id={'approve-Btn'}
                        history={history}
                        label={'Approve'}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            handleOpenApproveTransactionModal()
                        }}/>
                </div>
            )
        }
        return (
            <div className="sticky-bottom-buttons gap-0">
                {!isEmpty(txnData?.totalAllocationAmount)
                &&
                <div className='d-flex justify-content-end align-items-center me-2 gap-1'>
                    <DLText id={''}
                            text={'Current payment amount'}
                            fontSize={"sm"}
                            fontWeight={"normal"}
                            fontColor={"grayDark"}
                            marginBottom={"none"}
                    />
                    <DLText id={''}
                            text={'Rs.' + handleAmount(txnData?.totalAllocationAmount)}
                            fontSize={"lg"}
                            fontWeight={"bold"}
                            marginBottom={"none"}
                    />
                </div>}
                <DLButton
                    id={'reject-Btn'}
                    history={props.history}
                    label={'Reject'}
                    buttonSize={"sm"}
                    type={"danger"}
                    fontSize={"sm"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenRejectTransactionModal()
                    }}
                />
                <DLButton
                    id={'approve-Btn'}
                    history={history}
                    label={'Approve'}
                    buttonSize={"sm"}
                    fontSize={"sm"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenApproveTransactionModal()
                    }}/>
            </div>
        )
    };

    const renderMobileData = () => {
        if(isEmpty(investmentDetails)){
            return '';
        }
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"p-3 m-0"}
                                 key={index}>
                                <div className='data-card-label pb-2'>
                                    <DLText id={''}
                                            text={item?.productName}
                                            fontColor={'black'}
                                            fontWeight={"semi-bold"}
                                            fontSize={"xs"}
                                    />
                                </div>
                                <div className='row'>
                                    <div className='data-card-label col-6'>
                                        <div className='d-flex align-items-center h-100'>
                                            <div>
                                                <DLText id={''}
                                                        text={'Folio No'}
                                                        fontColor={'grayDark'}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        isInline={false}
                                                        marginBottom={"none"}
                                                />
                                                <DLText id={''}
                                                        text={isEmpty(item?.folioNumber) ? "Create new folio" : item?.folioNumber}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        isInline={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='data-card-label col-3'>
                                        <div className='d-flex align-items-center justify-content-start h-100'>
                                            <div>
                                                <DLText id={''}
                                                        text={'Amount'}
                                                        fontColor={'grayDark'}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        marginBottom={"none"}
                                                        isInline={false}
                                                />
                                                <DLText id={''}
                                                        text={handleAmount(item?.amount)}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='data-card-label col-3'>
                                        <div className={'text-start'} style={{maxWidth: "150px"}}>
                                            <div className='d-flex align-items-center justify-content-start h-100'>
                                                <div>
                                                    <DLText id={''}
                                                            text={'Allocation'}
                                                            fontColor={'grayDark'}
                                                            fontWeight={"normal"}
                                                            fontSize={"xs"}
                                                            marginBottom={"none"}
                                                            isInline={false}
                                                    />
                                                    <DLText id={''}
                                                            text={item?.allocation + "%"}
                                                            fontWeight={"normal"}
                                                            fontSize={"xs"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderMobileTotalCount = () => {
        return (
            <div className={"py-2 row px-1 m-0"} style={{borderTop : '1px solid', borderBottom : '1px solid', borderColor : appTheme.appColor.black}}>
                <div className='data-card-label col-6'>
                    <div className={'text-start'}>
                        <DLText id={''}
                                fontWeight={"bold"}
                                fontSize={"sm"}
                                text={'Total'}
                        />
                    </div>
                </div>
                <div className='data-card-label col-3 d-flex align-items-center justify-content-start'>
                    <div className={'text-start'}>
                        <DLText id={''}
                                fontWeight={"bold"}
                                fontSize={"sm"}
                                text={isEmpty(txnData?.totalAllocationAmount) ? "-" : handleAmount(txnData?.totalAllocationAmount)}
                        />
                    </div>
                </div>
                <div className='data-card-label col-3 d-flex align-items-center justify-content-start'>
                    <div className={'text-start'} style={{maxWidth: "150px"}}>
                        <DLText id={''}
                                fontWeight={"bold"}
                                fontSize={"sm"}
                                text={isEmpty(txnData?.totalAllocationPercentage) ? "-" : getRoundedvalue(txnData?.totalAllocationPercentage) + "%"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileInvestmentDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded h-100">
                    <div className='p-3 border-top border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <DLText
                                id={'investment-amount-title'}
                                type={'normal'}
                                text={'Investment Amount : ' + handleAmount(txnData?.investmentAmount)}
                                fontSize={'md'}
                                fontWeight={'bold'}
                                fontColor={'black'}
                                marginBottom={"none"}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-center'>
                            <DLText id={''}
                                    text={'No schemes have been added for the funds'}
                                    fontWeight={'normal'}
                                    fontSize={"sm"}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded mb-2">
                <div>
                    <div className='d-flex justify-content-between align-items-center p-3'  style={{borderTop : '1px solid', borderBottom : '1px solid', borderColor : appTheme.appColor.black}}>
                        <DLText
                            id={'investment-amount-title'}
                            type={'normal'}
                            text={'Investment Amount : ' + handleAmount(txnData?.investmentAmount)}
                            fontSize={'md'}
                            fontWeight={'bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                </div>
                <div>
                    <div className='p-0 w-100 h-100'>
                        <div className="h-100 c">
                            {renderMobileData()}
                            {renderMobileTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderDetails = () => {
        return (
            <div className='h-100'>
                <div className='d-flex flex-column h-100'>
                    <div className='mb-2 flex-grow-1'>
                        {isMobileView ? renderMobileInvestmentDetails() : renderInvestmentDetails()}
                    </div>
                    <div className={isMobileView? 'mb-5 pb-1' : 'mb-5'}>
                        {renderPaymentDetails()}
                        {isMobileView && renderMobileTotalAmount()}
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileTotalAmount = () => {
        return (
            <div className="py-2 row px-1 m-0 bg-white" style={{borderTop : '1px solid', borderBottom : '1px solid', borderColor : appTheme.appColor.black}}>
                <div className='data-card-label col-9'>
                    <div className={'text-start'}>
                        <DLText id={''}
                                text={'Current payment amount: '}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                        />
                    </div>
                </div>
                <div className='data-card-label col-3'>
                    <div className={'text-start'}>
                        <DLText id={''}
                                text={handleAmount(txnData?.totalAllocationAmount)}
                                fontSize={"md"}
                                fontWeight={"bold"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderDesktopUI = () => {
        return (
            <div className='d-flex flex-column bg-light h-100' style={{minHeight: 'calc(100dvh)'}}>
                {renderAppBar()}
                <div style={{marginTop: '75px'}}>
                    {renderHeader()}
                </div>
                <div className='page-container flex-grow-1 bg-light'>
                    {renderDetails()}
                </div>
                {renderButtons()}
            </div>
        );
    };

    const renderMobileUI = () => {
        return (
            <div className='d-flex flex-column h-100 bg-light'>
                {renderAppBar()}
                <div className='bg-white' style={{paddingTop: '75px'}}>
                    {renderHeader()}
                </div>
                <div className='flex-grow-1'>
                    {renderDetails()}
                </div>
                <div>
                    {renderButtons()}
                </div>
            </div>
        );
    };

    const renderAppBar = () =>{
        return(
            <div className="">
                <AppBar drawerwidth={0}
                        style={{backgroundColor: appTheme.appColor.white, borderBottom: '2px solid lightGray'}}
                        position="fixed"
                        open={true}
                        className='shadow-none d-flex'>
                    <Toolbar className='' style={{height: isMobileView? '70px' : '60px', minHeight: isMobileView? '70px' : '60px '}}>
                        {logoHeaderUi()}
                    </Toolbar>
                </AppBar>
            </div>
        )
    }

    const logoHeaderUi = () => {
        const {isXLView} = props;
        if(isMobileView){
            return (
                <div className='w-100'>
                    <div className="d-flex justify-content-between w-100 " style={{height: "100%"}}>
                        <div className='d-flex align-items-center w-100'>
                            <div style={{marginRight: isXLView ? 70 : 20}}>
                                <div className='d-flex align-items-center'>
                                    <img className='rounded-2 me-1 cursor'
                                         src={logo}
                                         alt={'logo'}
                                         width={'30px'}
                                         height={'30px'}/>
                                    <div id={"logo"} className='cursor'>
                                        <DLText id={'logo-text'}
                                                text={'Advisory'}
                                                fontWeight={"bold"}
                                                fontSize={"lg"}
                                                marginBottom={"none"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex align-items-center flex-shrink-0'>
                            <div className="d-flex align-items-center">
                                <div>
                                    <div>
                                        <DLText id={"header"}
                                                text={"Lumpsum Purchase"}
                                                fontSize={"sm"}
                                                fontColor={"grayDark"}
                                                marginBottom={"none"}
                                        />
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='info-panel ms-0'>
                                            <DLText id={''}
                                                    text={'Ticket No: '}
                                                    fontSize={"xs"}
                                                    fontWeight={"normal"}
                                                    isInline={true}
                                                    fontColor={"grayDark"}
                                            />
                                            <DLText id={''}
                                                    text={ticketNumber}
                                                    fontSize={"xs"}
                                                    fontWeight={"semi-bold"}
                                                    isInline={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className='w-100'>
                <div className="d-flex justify-content-between w-100 " style={{height: "100%"}}>
                    <div className='d-flex align-items-center w-100'>
                        <div style={{marginRight: isXLView ? 70 : 20}}>
                            <div className='d-flex align-items-center'>
                                <img className='rounded-2 me-1 cursor'
                                     src={logo}
                                     alt={'logo'}
                                     width={'30px'}
                                     height={'30px'}/>
                                <div id={"logo"} className='cursor'>
                                    <DLText id={'logo-text'}
                                            text={'Advisory'}
                                            fontWeight={"bold"}
                                            fontSize={"xl"}
                                            marginBottom={"none"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex align-items-center flex-shrink-0'>
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <div>
                                    <DLText id={"header"}
                                            text={"Financial Transaction: Lumpsum Purchase"}
                                            fontSize={"sm"}
                                            fontColor={"grayDark"}
                                            marginBottom={"none"}
                                    />
                                </div>
                                <div className='d-flex align-items-center ms-2 ps-1'>
                                    <div className='info-panel ms-0'>
                                        <DLText id={''}
                                                text={'Ticket No: '}
                                                fontSize={"xs"}
                                                fontWeight={"normal"}
                                                isInline={true}
                                                fontColor={"grayDark"}
                                        />
                                        <DLText id={''}
                                                text={ticketNumber}
                                                fontSize={"xs"}
                                                fontWeight={"semi-bold"}
                                                isInline={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    if (isEmpty(UIState)) {
        return renderLoader()
    }

    if (!isEmpty(transactionStatus)) {
        return (
            <div className='h-100'>
                <ApprovedOrRejected {...props} type={transactionStatus}/>
            </div>
        )
    }

    return (
        <div>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            {renderLoader()}
            {renderApproveTransactionModal()}
            {renderRejectTransactionModal()}
        </div>
    );
};

export default ClientsApprovalView;