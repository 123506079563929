import React, {useState} from 'react';
import {connect} from "react-redux";
import DiscardTransactionView from "./DiscardTransactionView";
import {discardTransaction} from "../../../../ClientRequestsActions";
import {showToastMessage} from "../../../../../AppComponents/AppActions";
import store from "../../../../../store";

const DiscardTransaction = (props) => {
    const {getId, handleCloseDiscardTransactionModal} = props;
    const [discardTransactionState, setDiscardTransactionState] = useState({
        isApiInProgress: false,
        modalErrorMessage: ''
    });

    const handleDiscard = () => {
        setDiscardTransactionState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        discardTransaction(getId())
            .then((res) => {
                    if (res.success) {
                        setDiscardTransactionState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false
                        }));
                        handleCloseDiscardTransactionModal();
                        store.dispatch(showToastMessage('info', 'Request: Ticket '+props.ticketNumber+' for '+ props.selectedName + ' is discarded'))
                        props.history.replace("/client-requests");
                    } else {
                        setDiscardTransactionState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                        }));
                    }
                }
            );
    };

    return (
        <DiscardTransactionView
            {...props}
            {...discardTransactionState}
            handleDiscard={handleDiscard}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(DiscardTransaction);
