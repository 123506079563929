import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../../../AppComponents/AppConstants";

import {
    getPortfolioListing,
    applyPortfolio
} from "../../../../../../portfolio/portfolioAction";

import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import ModelPortfolioView from "./ModelPortfolioView";

const ModelPortfolio = (props) => {
    const [modelPortfolioState, setModelPortfolioState] = useState({
        portfolioListing: [],
        portfolioName: '',
        UIState: '',
        responseError: '',
        isShowLoader: false,
        filters: {
            keyword: ''
        },
        portfolioSelected: '',
        selectedPortfolioId: ''
    });

    useEffect(() => {
        getPortfolios(modelPortfolioState?.filters);
    }, []);

    const getTransactionRequestId = () => {
        return props.match?.params?.transactionId
    };

    const getInvestmentAmount = () => {
        return props?.location?.state?.portfolio
    };

    const getClientRequestId = () => {
        return props.match?.params?.clientRequest
    };

    const generateFilter = (filter) => {
        let filterObject = {};
        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }
        return filterObject;
    };

    const getPortfolios = (portfolioFilter) => {
        const {profile} = props;

        setModelPortfolioState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            "filters": generateFilter(portfolioFilter)
        };

        getPortfolioListing(payload, profile.advisoryId)
            .then(res => {
                if (res?.success) {
                    setModelPortfolioState(prevState => ({
                        ...prevState,
                        portfolioListing: res?.data?.portfolio,
                        responseError: '',
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                } else {
                    setModelPortfolioState(prevState => ({
                        ...prevState,
                        responseError: res?.__error,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const onChangeFilter = (name, value) => {
        if (name === 'keyword') {
            setModelPortfolioState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    keyword: value,
                },
                portfolioSelected: '',
                selectedPortfolioId: ''
            }));
            getPortfolios(
                {
                    keyword: value,
                }
            );
        }
    };

    if (isEmpty(modelPortfolioState.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    const selectFolio = (value, selectedPortfolioId) => {
        setModelPortfolioState((prevStep) => ({
            ...prevStep,
            portfolioSelected: value,
            selectedPortfolioId: selectedPortfolioId,
            responseError: ''
        }));
    };

    const redirectScreen = (data, action) => {
        let transactionRequestId = getTransactionRequestId();
        let investmentAmount = getInvestmentAmount();
        let clientRequestId = getClientRequestId();

        if (action === 'DISCARD') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/lumpsum-transaction/' + transactionRequestId,
                {portfolio: data});
        }
        if (action === 'SKIP') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/lumpsum-transaction/' + transactionRequestId + '/select-portfolio/purchase-transaction',
                {mode: 'ADD'});
        }
    };

    const onNextClick = () => {
        const {selectedPortfolioId} = modelPortfolioState;
        let clientRequestId = getClientRequestId();
        let transactionRequestId = getTransactionRequestId();

        setModelPortfolioState(prevState => ({
            ...prevState,
            isShowLoader: true,
        }));

        props.history.replace(
            '/client-requests/' + clientRequestId + '/lumpsum-transaction/' + transactionRequestId + '/select-portfolio/purchase-transaction',
            {portfolioId: selectedPortfolioId, mode: 'ADD'});

    };

    return (
        <ModelPortfolioView
            {...props}
            {...modelPortfolioState}
            onChangeFilter={onChangeFilter}
            selectFolio={selectFolio}
            onNextClick={onNextClick}
            redirectScreen={redirectScreen}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    isMDView: state.appState.deviceInfo.isMDView,
});


export default connect(mapStateToProps, {})(ModelPortfolio);
