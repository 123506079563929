import React from 'react';

import DLButton from "../../../core/components/DLButton/DLButton";
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalActions from "../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";


const RejectTransactionView = (props) => {

    const {
        history, isRejectTransactionModalOpen,
        isApiInProgress, modalErrorMessage, handleReject, handleCloseRejectTransactionModal, comment, errors, handleChange
    } = props;

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderUiText = () => {
        return (
            <div className='mb-2 pb-1 text-start'>
                <DLInputTextField
                    id={''}
                    label={'Comment'}
                    value={comment}
                    inputType={'text'}
                    isMulti={true}
                    rows={3}
                    maxRows={4}
                    maxCharLength={200}
                    isClearable={false}
                    isRequired={true}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    hideCharLength={true}
                    onChangeValue={(value) => {
                        handleChange('comment', value)
                    }}
                    onBlur={() => {
                    }}
                    error={errors.commentError !== ''}
                    helperMessage={errors.commentError}
                />
            </div>
        )
    };

    const renderButtons = () => {
        return (
            <div>
                <DLButton
                    id={'cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => handleCloseRejectTransactionModal()}/>
                <DLButton
                    id={'Btn'}
                    history={props.history}
                    label={'Reject'}
                    type={"danger"}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => handleReject()}
                />
            </div>
        )
    }

    const renderRejectTransactionModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"xs"}
                    onClose={() => handleCloseRejectTransactionModal()}
                    open={isRejectTransactionModalOpen}
                    id={'discard-modal'}>
                    <DLModalTitle
                        id={'discard-title'}
                        onClose={() => handleCloseRejectTransactionModal()}
                        title={'Confirmation'}/>
                    <DLModalContainer
                        id={'discard-container'}>
                        <div>
                            {renderModalError()}
                            {renderUiText()}
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'discard-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            {renderButtons()}
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const renderLoader = () => {
        return (
            <DLLoader type={"screen"} isVisible={isApiInProgress}/>
        )
    }

    return (
        <div>
            {renderLoader()}
            {renderRejectTransactionModal()}
        </div>
    );
};

export default RejectTransactionView;