import React, {useEffect, useState} from 'react';

import {isEmpty} from "../../utils/validations";
import {APP_CONST} from "../../AppComponents/AppConstants";

import {connect} from "react-redux";

import ClientsApprovalView from "./ClientsApprovalView";


const ClientsApproval = (props) => {
    const {approvalData} = props;

    const [clientsApprovalState, setClientsApprovalState] = useState({

        ticketNumber: '',
        accountCategory: '',
        accountHolders: '',
        status: "",
        accountType: '',
        IINNumber: '',
        paymentDetails: [],
        investmentDetails: [],
        accountDetails: [],
        FTStatuses: [],
        chequeDepositModes: [],
        clientRequestId: '',
        txnRequestId: '',
        isShowLoader: false,
        category: '',
        advisoryUsers: [],
        details: [],
        corporateAccountDetails: [],
        createdBy: '',
        comments: '',
        createdAt: '',
        responseError: '',
        UIState: '',
        txnStatus: '',
        txnStage: '',
        selectedId: '',
        isApproveTransactionModalOpen: false,
        isRejectTransactionModalOpen: false,
        isManagementUser: false,
        transactionStatus: ''
    });

    useEffect(() => {
        getDetails();
    }, []);

    const getAdvisoryUserName = (value) => {
        const {advisoryUsers} = clientsApprovalState;
        if (isEmpty(value)) {
            return '-'
        }
        const user = advisoryUsers.find(item => item.id === value)
        return user ? user.firstName + " " + user.lastName : "-";
    };

    const getDetails = () => {
        const {profile} = props;
        let FTStatuses = [];
        setClientsApprovalState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        if (!isEmpty(approvalData)) {
            let clientsApprovalData = approvalData;
            let txnData = approvalData?.clientData;

            setClientsApprovalState(prevState => ({
                ...prevState,
                paymentDetails: txnData?.paymentDetails,
                investmentDetails: txnData?.funds,
                txnStatus: txnData?.txnRequestStatus?.status,
                txnStage: txnData?.txnRequestStatus?.stage,
                approvalTransactionStatus: txnData?.txnRequestStatusDetails?.label,
                txnData: txnData,
                clientRequestId: txnData?.txnRequestStatus?.id,
                txnRequestId: txnData?.txnRequestStatus?.txnRequestId,
                details: clientsApprovalData,
                corporateAccountDetails: isEmpty(clientsApprovalData?.accountDetails)? [] : clientsApprovalData?.accountDetails?.corporate,


                accountHolders: getFormattedData('accountHolders', clientsApprovalData?.accountDetails),
                accountType: getFormattedData('accountType', clientsApprovalData?.accountDetails),
                IINNumber: getFormattedData('IINNumber', clientsApprovalData?.accountDetails),
                category: getFormattedData('category', clientsApprovalData?.accountDetails),
                accountDetails: clientsApprovalData?.accountDetails?.accountHolders,
                ticketNumber: txnData?.ticketNumber,


                isManagementUser: profile?.isManagement,
                FTStatuses: FTStatuses,
                isShowLoader: false,
                UIState: APP_CONST.CONTENT_AVAILABLE,
                responseError: ''
            }));

        } else {
            setClientsApprovalState(prevState => ({
                ...prevState,
                isShowLoader: false,
                UIState: APP_CONST.CONTENT_UNAVAILABLE
            }));
        }
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return [];
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    function capitalizeFirstLetters(name) {
        if (isEmpty(name)) {
            return;
        }
        return name
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const getFormattedData = (name, accountDetails) => {
        const{isXSView} = props;

        if (name === 'accountHolders') {
            if (isEmpty(accountDetails?.accountHolders)) {
                return [];
            }
            return accountDetails.accountHolders.map(holder => capitalizeFirstLetters(holder.fullName))
        }
        if (name === 'accountType') {
            const accountType = accountDetails?.accountType;

            return isEmpty(accountType)? "-" : accountType+(isXSView? " A/c" : " Account");
        }
        if (name === 'IINNumber') {
            return accountDetails?.IINNumber
        }
        if (name === 'category') {
            let category = accountDetails?.category;

            if (category === 'HNI') {
                return category;
            } else if (category === 'UNASSIGNED') {
                return capitalizeFirstLetters(category);
            } else if (category === 'RETAIL') {
                return capitalizeFirstLetters(category);
            } else if (category === 'PREMIUM_RETAIL') {
                return 'Premium Retail';
            } else if (category === 'ULTRA_HNI') {
                return 'Ultra HNI';
            }else{
                return "-";
            }
        }

    };

    const handleOpenApproveTransactionModal = () => {
        setClientsApprovalState((prevState) => ({
            ...prevState,
            isApproveTransactionModalOpen: true
        }));
    };

    const handleCloseApproveTransactionModal = () => {
        setClientsApprovalState((prevState) => ({
            ...prevState,
            isApproveTransactionModalOpen: false
        }));
    };

    const handleOpenRejectTransactionModal = () => {
        setClientsApprovalState((prevState) => ({
            ...prevState,
            isRejectTransactionModalOpen: true
        }));
    };

    const handleCloseRejectTransactionModal = () => {
        setClientsApprovalState((prevState) => ({
            ...prevState,
            isRejectTransactionModalOpen: false
        }));
    };

    const onSaveSuccess = (type) => {

        setClientsApprovalState((prevState) => ({
            ...prevState,
            transactionStatus: type
        }));
    };

    return (
        <ClientsApprovalView
            {...props}
            {...clientsApprovalState}
            getDetails={getDetails}
            onSaveSuccess={onSaveSuccess}
            getAdvisoryUserName={getAdvisoryUserName}
            handleOpenApproveTransactionModal={handleOpenApproveTransactionModal}
            handleCloseApproveTransactionModal={handleCloseApproveTransactionModal}
            handleOpenRejectTransactionModal={handleOpenRejectTransactionModal}
            handleCloseRejectTransactionModal={handleCloseRejectTransactionModal}
        />);
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(ClientsApproval);
