import React from 'react';

import DLText from "../../../core/components/DLText/DLText";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalActions from "../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLLoader from "../../../core/components/DLLoader/DLLoader";


const ApproveTransactionView = (props) => {

    const {
        history, isApproveTransactionModalOpen,
        isApiInProgress, modalErrorMessage, handleApprove, handleCloseApproveTransactionModal
    } = props;

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderUiText = () => {
        return (
            <div className='mb-2 pb-1 text-center'>
                <DLText id={''}
                        text={"Are you sure you want to approve transaction?"}
                        fontSize={"sm"}
                        fontWeight={"normal"}
                        marginBottom={"none"}
                />
            </div>
        )
    };

    const renderButtons = () => {
        return (
            <div>
                <DLButton
                    id={'cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => handleCloseApproveTransactionModal()}/>
                <DLButton
                    id={'Btn'}
                    history={props.history}
                    label={'Approve'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => handleApprove()}
                />
            </div>
        )
    }

    const renderApproveTransactionModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"xs"}
                    onClose={() => handleCloseApproveTransactionModal()}
                    open={isApproveTransactionModalOpen}
                    id={'discard-modal'}>
                    <DLModalTitle
                        id={'discard-title'}
                        onClose={() => handleCloseApproveTransactionModal()}
                        title={'Confirmation'}/>
                    <DLModalContainer
                        id={'discard-container'}>
                        <div>
                            {renderModalError()}
                            {renderUiText()}
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'discard-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            {renderButtons()}
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const renderLoader = () => {
        return (
            <DLLoader type={"screen"} isVisible={isApiInProgress}/>
        )
    }

    return (
        <div>
            {renderLoader()}
            {renderApproveTransactionModal()}
        </div>
    );
};

export default ApproveTransactionView;