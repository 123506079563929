import React from 'react';

import DLText from "../../core/components/DLText/DLText";
import DLButton from "../../core/components/DLButton/DLButton";

const s3BaseURL = process.env.REACT_APP_S3_IMAGE_PUBLIC_BASE_URL;

const ApprovedOrRejectedView = (props) => {
    const {type, isMobileView, closeWindow} = props;

    const getImg = () => {
        if (type === "APPROVED") {
            return (
                <div className='d-flex align-items-center'>
                    <img
                        src={s3BaseURL + 'assets/general/transactionSuccessful.png'}
                        alt={'img'}
                        className={'img-fluid'}
                        width={70}
                        height={70}/>
                </div>
            )
        }
        return (
            <div className='d-flex align-items-center'>
                <img
                    src={s3BaseURL + 'assets/general/transactionRejected.png'}
                    alt={'img'}
                    className={'img-fluid'}
                    width={70}
                    height={70}/>
            </div>
        )
    }

    const getMessage = () => {
        if (type === "APPROVED") {
            return (
                <div className="text-center">
                    <DLText id={'enter-security-code'}
                            alignText={"center"}
                            text={'Thank You'}
                            fontWeight={"bold"}
                            marginBottom={'md'}
                            fontSize={isMobileView ? "lg" : "xl"}/>
                    <div className='mb-5' style={{maxWidth : '400px'}}>
                        <DLText id={'eventItem-clone-text1'}
                                type={'normal'}
                                alignText={"center"}
                                marginBottom={'none'}
                                fontSize={isMobileView ? "sm" : "md"}
                                text={"You have approved the transaction. You will receive further information shortly."}
                        />
                        <div className='mt-3'>
                            <DLButton
                                id={'move-to-draft-Btn'}
                                history={props.history}
                                label={'Done'}
                                buttonSize={"sm"}
                                fontSize={"sm"}
                                sideMargin={"sm"}
                                onClick={() => {
                                    closeWindow()
                                }}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="text-center">
                <DLText id={'enter-security-code'}
                        alignText={"center"}
                        text={"Transaction Rejected!"}
                        fontWeight={"bold"}
                        marginBottom={'md'}
                        fontSize={isMobileView ? "lg" : "xl"}/>
                <div className='mb-5' style={{maxWidth : '400px'}}>
                    <DLText id={'eventItem-clone-text1'}
                            type={'normal'}
                            alignText={"center"}
                            marginBottom={'none'}
                            fontSize={isMobileView ? "sm" : "md"}
                            text={"We have noted your response your CRM will get in touch with you shortly"}
                    />
                    <div className='mt-3'>
                        <DLButton
                            id={'move-to-draft-Btn'}
                            history={props.history}
                            label={'Done'}
                            buttonSize={"sm"}
                            fontSize={"sm"}
                            sideMargin={"sm"}
                            onClick={() => {
                                closeWindow()
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const renderLinkExpiredUi = () => {

        return (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <div className="text-center mb-3">
                <span className="p-0">
                    {getImg()}
                </span>
                </div>
                <div className="text-center">
                    {getMessage()}
                </div>
            </div>
        );
    };

    return (
        <div className='w-100 d-flex flex-column align-items-center justify-content-center bg-light'
             style={{height: 'calc(100dvh)'}}>
            <div style={{
                padding: "34px 27px 34px 27px"
            }}>
                {renderLinkExpiredUi()}
            </div>
        </div>
    );
};

export default ApprovedOrRejectedView;
