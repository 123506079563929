import React from 'react';

import {isEmpty} from "../../../../../../utils/validations";
import {getTimeFromNowWithDate} from "../../../../../../utils/helper";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";

const HistoryBlockView = (props) => {
    const {events, getSentenceCase, isShowLoader, isMobileView, isFrom, getAdvisoryUser, status} = props;

    const renderMultilineRemark = (remarks) => {
        const {isManagementUser, isMarkAsCompleted} = props
        const isButtonPresent = isManagementUser
            ? status !== 'COMPLETED'
            : status !== 'COMPLETED' || status === 'OPEN' || (status === 'IN_PROGRESS' && !isMarkAsCompleted);

        if (isEmpty(remarks)) {
            return '-'
        }
        const lines = remarks.split('\n');
        return lines.map((line, index) => {
            if (isEmpty(line)) {
                return (
                    <br key={index}/>
                )
            }
            return (
                <div key={index} style={{ marginBottom: isMobileView && isButtonPresent ? '32px' : ''}}>
                <DLText id={'ViewActivity-Summary'}
                            fontSize={'xs'}
                            marginBottom={"none"}
                            text={line}/>
                </div>
            )
        })
    };

    const renderHeading = (item) => {
        let date = isEmpty(item?.createdAt) ? '' : getTimeFromNowWithDate(item?.createdAt);
        let name = getAdvisoryUser(item?.createdBy?.id);
        const title = getSentenceCase(item.type);

        return (
            <div style={{lineHeight: "16px"}}>
                <div className={"text-break d-flex gap-1 pe-3 align-item-center"}>
                    <DLText id={'title-NFTDetails'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            fontSize={'sm'}
                            isInline={true}
                            text={title}/>
                    <DLText id={'ViewActivity-Summary'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            fontSize={'sm'}
                            isInline={true}
                            text={" - "}/>
                    <DLText id={'itle-NFTDetails'}
                            fontColor={'grayDark'}
                            fontSize={'xxs'}
                            text={'by ' + name + ' ' + date}/>
                </div>
            </div>
        );
    };

    const renderDetails = (item, index) => {
        let remarks = '';
        if (item?.details?.comment) {
            remarks = item?.details?.comment;
        }
        if (item?.details?.remark) {
            remarks = item?.details?.remark;
        }

        return (
            <div className='d-flex mb-3' key={index}>
                <div>
                    <div className='vertical-step-icon mx-1'/>
                    <div className='d-flex justify-content-center h-100'>
                        <div className={index === events?.length - 1 ? '' : 'step-connector'}/>
                    </div>
                </div>

                <div className='mb-1 ms-1'>
                    {renderHeading(item)}

                    {!isEmpty(remarks) &&
                        renderMultilineRemark(remarks)
                    }

                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderErrorUI = () => {
        const {responseError} = props;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'py-2'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={isFrom === "LISTING" ? "" : isMobileView ? ' ' : 'p-3 pe-0 '}>
            <div className="history-block"
                 style={{
                     minHeight: !isMobileView
                         ? (status === "COMPLETED" ? 'calc(100dvh - 207px)' : 'calc(100dvh - 241px)')
                         : '',
                     maxHeight: !isMobileView
                         ? (status === "COMPLETED" ? 'calc(100dvh - 207px)' : 'calc(100dvh - 241px)')
                         : ''
                 }}
            >
                {isFrom !== "LISTING" && <div className={isMobileView ? '' :'mb-3 pb-2 pt-1 ms-1'}>
                    <DLText id={'history-block-title'}
                            text={'History'}
                            fontSize={"lg"}
                            marginBottom={'none'}
                            fontWeight={"semi-bold"}
                            isInline={true}/>
                    {renderErrorUI()}
                </div>}

                <div className={isFrom === "LISTING" ? "" : isMobileView ? 'mt-3' : 'mt-3 pt-1 pb-3'}
                     style={{
                         minHeight: isMobileView ? '' : 'calc(100dvh - 284px)',
                         maxHeight: isMobileView ? '' : 'calc(100dvh - 284px)',
                         overflowY: "auto"
                     }}>
                    {events.map((item, index) => (
                        renderDetails(item, index)
                    ))}
                </div>
            </div>
            {renderLoader()}
        </div>
    );
};

export default HistoryBlockView;