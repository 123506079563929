import React, {useEffect} from 'react';
import {debounce, isEmpty} from "../../../core/components/DLComponentHelper";
import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLSearchDropdown from "../../../core/components/DLSearchDropdown/DLSearchDropdown";

const PortfolioCreateView = (props) => {
    const {
        isXSView,
        isShowLoader,
        isMobileView,
        addOneMoreParam,
        nodeLinks,
        setFundAllocation,
        removeLinking,
        portfolioName,
        errors,
        handleChange,
        handleSave,
        modalErrorMessage,
        getTotalAllocation,
        handleCancel,
        isCancelPopUpRequired,
        handleCloseCancelModal,
        handleRedirect,
        isShowInlineLoader,
        getDropdownItem,
        scrollToFieldId,
        handleScrollToErrorElement
    } = props;

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce();
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {
        handleScrollToErrorElement('RESET');
    }, 500);

    const renderCancelModal = () => {
        return (
            <DLModal
                history={history}
                maxWidth={"xs"}
                onClose={() => handleCloseCancelModal()}
                open={isCancelPopUpRequired}
                id={'cancel-modal'}>
                <DLModalTitle
                    id={'modal-title'}
                    onClose={() => handleCloseCancelModal()}
                    title={'Confirmation'}/>
                <DLModalContainer
                    id={'modal-container'}>
                    <div>
                        <div className='text-center'>
                            <>
                                <DLText id={''}
                                        text={'Are you sure you want to leave this page ?'}
                                        fontSize={"sm"}
                                        fontWeight={"normal"}
                                />
                                <DLText id={''}
                                        text={'You will lose all the progress if you continue.'}
                                        fontSize={"sm"}
                                        fontWeight={"normal"}
                                />
                            </>
                        </div>
                    </div>
                </DLModalContainer>
                <DLModalActions id={'modal-action'}>
                    <div className='mb-4' style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'redirect-modal-cancelBtn'}
                            history={history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => handleCloseCancelModal()}/>
                        <DLButton
                            id={'redirect-modal-continueBtn'}
                            history={props.history}
                            label={'Continue'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => handleRedirect()}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        );
    };

    const renderBottomCreateButton = () => {
        const {isMobileView} = props;

        return (
            <div className={'sticky-bottom-buttons gap-0 d-flex justify-content-end' + (isMobileView ? ' page-container bg-white' : '')}>
                <div className="">
                    <DLButton id={'cancel-button'}
                              styleType={'outlined'}
                              onClick={() => handleCancel()
                              }
                              label={'Cancel'}/>
                    <DLButton id={'save-button'}
                              onClick={() => {
                                  handleSave()
                              }}
                              label={'Save'}/>
                </div>
            </div>
        )

    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between bg-white pb-2 pt-2'>
                <div className='page-header'>
                    Create New Portfolio
                </div>
            </div>
        )
    };

    const renderTableUi = () => {
        return (
            <div className={'overflow-scroll overflow-x-hidden overflow-y-auto'}>
                {isShowLoader ?
                    <div className='d-flex align-items-center justify-content-center h-100'>
                        {renderLoader()}
                    </div>
                    :
                    renderCreateUi()
                }
            </div>
        )
    };

    const renderBottomBlock = () => {
        return (
            getTotalAllocation() !== 0 &&
            <div
                className={"py-2 row d-flex justify-content-between m-0 " + (getTotalAllocation() !== 0 ? "border-top" : "")}>
                <div className='col-1'></div>
                <div className='col-8'></div>

                <div className='col-2' style={{paddingLeft: '20px'}}>
                    <DLText id={'total-allocation mt-1'} text={getTotalAllocation() + '%'} fontWeight={'bold'}/>
                </div>
                <div className='col-1'></div>
            </div>
        )
    };

    const renderWidget = () => {
        return (
            <div className={"py-3 px-4 border-bottom d-flex justify-content-between"}>
                <div className='d-flex align-items-center'>
                    <DLText id={'funds-title'}
                            isInline={true}
                            fontSize={'lg'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            text={'Funds'}/>
                </div>
                <div >
                    <DLButton id={'add-button'}
                              isDisabled={nodeLinks?.length === 10 }
                              styleType={'outlined'}
                              onClick={() => {
                                  addOneMoreParam()
                              }}
                              hidden={isEmpty(nodeLinks)}
                              label={'Add'}
                              marginBottom={"none"}
                    />
                </div>
            </div>
        )
    };

    const renderDesktopUi = () => {
        return (
            <div className={`p-0 w-100 border-rounded panel h-100 ${isXSView ? 'mb-3' : ''}`}>
                <div className="px-0 h-100">
                    {renderWidget()}
                    {renderTableUi()}
                </div>
            </div>
        )
    };

    const renderCreateUi = () => {
        if (isEmpty(nodeLinks)) {
            return (
                <div className='d-flex justify-content-center align-items-center' style={{minHeight: 'calc(100dvh - 336px)', maxHeight: 'calc(100dvh - 336px)'}}>
                    <DLButton id={'add-funds-to-create-portfolio-button'}
                              onClick={() => {
                                  addOneMoreParam()
                              }}
                              label={'Add Funds to Create Portfolio'}/>
                </div>
            )
        }
        return (
            <div>
                {renderHeadings()}
                {renderData()}
            </div>
        )
    };

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='pb-3 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const getFormattedData = (data) => {
        return data?.map(item => ({
            value: item.id,
            label: item.description
        }));
    }

    const renderSearchBar = (index, item) => {
        const {funds} = props;

        return (
            <div style={{width: '90%'}}>
                <DLSearchDropdown
                    placeholder={"Search by Name"}
                    isShowInlineLoader={isShowInlineLoader}
                    data={getFormattedData(funds)}
                    onChangeData={e => setFundAllocation('keyword', e, index)}
                    value={nodeLinks.keyword}
                    onClickItem={(value) => getDropdownItem(value, index)}
                    helperMessage={'Please select fund'}
                    error={item?.fundError !== ''}
                    disableHelperMessageHeight={true}
                    inputMarginBottom={"-2.5px"}
                />
            </div>
        )
    };

    const renderData = () => {
        return (
            <div
                style={{
                    minHeight: 'calc(100dvh - ' + (modalErrorMessage ? 442 : 395) + 'px)',
                    maxHeight: 'calc(100dvh - ' + (modalErrorMessage ? 442 : 395) + 'px)',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

        <div style={{flex: 1, overflowY: 'auto', overflowX: 'hidden'}}>
                    {nodeLinks.map((item, index) => (
                        <div className={'px-4 border-bottom row pt-2 pb-1 ' + ((nodeLinks[index]?.fund !== '' && item?.percentageError === '') ? 'align-items-center' : '')} key={index}>
                            <div
                                className={'col-1 ' + ((nodeLinks[index]?.fund !== '' && item?.percentageError === '') && nodeLinks[index].isSelected ? 'pb-1' : 'pt-2')}>
                                {(index + 1)}
                            </div>
                            <div className='data-card-label col-8' id={'search-field-'+index}>
                                {nodeLinks[index].isSelected ?
                                    <div className={"pt-2 pb-2"}>
                                        <DLText id={''}
                                                text={nodeLinks[index]?.fund}
                                        />
                                    </div>
                                    : renderSearchBar(index, item)}
                            </div>
                            <div className='col-2'>
                                <div className='w-75' id={'allocation-field-'+index}>
                                    <DLInputTextField
                                        id={'portfolio-name'}
                                        marginBottom={"none"}
                                        onChangeValue={(e) => {
                                            setFundAllocation("percentage", e, index)
                                        }}
                                        isClearable={false}
                                        error={item?.percentageError !== ''}
                                        helperMessage={item?.percentageError}
                                        value={item?.percentage}
                                        inputProps={{style: {maxWidth: "80px"}}}
                                        inputType={"decimal"}
                                        inputHeight={'sm'}
                                        disableHelperMessageHeight={true}
                                    />
                                </div>
                            </div>
                            <div
                                className={'data-card-label col-1 text-center cursor ' + ((nodeLinks[index]?.fund !== '' && item?.percentageError === '') && nodeLinks[index].isSelected ? 'pb-1' : 'pt-2')}
                                onClick={() => {
                                    removeLinking(index)
                                }}>
                                <i className="fa-regular fa-lg fa-trash-can"/>
                            </div>
                        </div>
                    ))}
                </div>
                {renderBottomBlock()}
            </div>
        )
    };


    const renderHeadings = () => {
        return (
            <div className={"py-3 border-bottom row px-4"}>
                <div className='data-card-label col-1' style={{}}>{"Sr. No."}</div>
                <div className='data-card-label col-8' style={{}}>{"Fund"}</div>
                <div className='data-card-label col-2' style={{}}>{"Allocation in %"}</div>
                <div className='data-card-label col-1 text-center' style={{}}>{"Action"}</div>
            </div>
        )
    };

    const renderUi = () => {
        return (
            <div className={'w-100 ' + ((isMobileView && !isXSView) ? '  page-container bg-white' : '')}>
                <div className='row '>
                    <div className='col-12'>
                        {renderDesktopUi()}
                    </div>
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"}  isVisible={isShowLoader} />);
    };

    if (isMobileView) {
        return (
            <div>
                <div className={'w-100'} style={{
                    background: 'rgba(0, 0, 0, 0.02)'
                }}>
                    {renderHeader()}
                    {renderUi()}
                    {renderBottomCreateButton()}
                    {renderLoader()}
                </div>
            </div>
        );
    }

    return (
        <div className={'h-100 d-flex justify-content-center bg-light'}>
            <div className={'w-100'}>
                {renderHeader()}
                <div className='page-container bg-white'>
                    <div className='d-flex'>
                        <div className='search-input ' style={{flex: 1.8}}>
                            <DLInputTextField id={'full-name'}
                                              label={"Portfolio Name"}
                                              value={portfolioName}
                                              maxCharLength={60}
                                              inputType={"text"}
                                              labelFontColor={'grayDark'}
                                              isClearable={false}
                                              isRequired={true}
                                              disableHelperMessageHeight={false}
                                              error={errors.portfolioName !== ''}
                                              helperMessage={errors.portfolioName}
                                              marginBottom={'none'}
                                              onChangeValue={(e) => {
                                                  handleChange("portfolioName", e)
                                              }}
                            />
                        </div>
                    </div>
                    {renderModalError()}
                </div>
                <div className='page-container mt-3 bg-light'>
                    {renderUi()}
                </div>
                {renderBottomCreateButton()}
                {renderLoader()}
                {renderCancelModal()}
            </div>
        </div>
    );
};

export default PortfolioCreateView;