import React, {useState} from 'react';
import {connect} from "react-redux";

import {moveToDraft} from "../../../../FTAction";

import MoveToDraftView from "./MoveToDraftView";


const MoveToDraft = (props) => {

    const {txnRequestId, handleCloseMoveToDraftModal, onSaveSuccess} = props;
    const [moveToDraftState, setMoveToDraftState] = useState({
        isApiInProgress: false,
        handleErrorInComponent: true,
        modalErrorMessage: ''
    });

    const handleMoveToDraft = () => {
        let payload = {
            txnRequestId : txnRequestId
        }
        setMoveToDraftState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        moveToDraft(payload)
            .then((res) => {
                    if (res.success) {
                        setMoveToDraftState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false
                        }));
                        handleCloseMoveToDraftModal();
                        onSaveSuccess();
                    } else {
                        setMoveToDraftState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                            modalErrorMessage: res.__error
                        }));
                    }
                }
            );
    };

    return (
        <MoveToDraftView
            {...props}
            {...moveToDraftState}
            handleMoveToDraft={handleMoveToDraft}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(MoveToDraft);
