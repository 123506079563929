import {httpDelete, httpGet, httpPost, httpPut} from "../utils/httpHelper";
import store from "../store";
import {
    setSearchKeywordValue,
    setStatusValue, resetAllFilters,
    setLimitCountValue,
    setPageValue,
    setRowsPerPageValue,
    setSkipCountValue,
    setCRMValue
} from "./ClientRequestsReducer"

export const getClientRequestsData = (data) => {
    return httpPost('/service/advisory/clientRequest/all', data)
        .then(res => {
            return res;
        });
}

export const getAccountTypeOptions = () => {
    return httpGet('/service/advisory/accountType/all?isClient=true').then(res => {
        return res;
    });
};

export const raiseRequest = (data,handleErrorInComponent) => {
    return httpPost('/service/advisory/clientRequest', data, handleErrorInComponent).then(res => {
        return res;
    })
};

export const getCategoryOptions = () => {
    return httpGet('/service/advisory/categoryType/all').then(res => {
        return res;
    });
};

export const clientsListing = (data,handleErrorInComponent) => {
    return httpPost('/service/advisory/activatedAccount/search', data, handleErrorInComponent).then(res => {
        return res;
    })
};

export const getClientRequestById = (requestId) => {
    return httpGet('/service/advisory/clientRequest?id='+requestId, true).then(res => {
        return res;
    });
};

export const getTransactionRequestById = (requestId) => {
    return httpGet('/service/advisory/clientRequest/transactions/all?clientRequestId='+requestId, true).then(res => {
        return res;
    });
};

export const getEarlierRequest = (accountId, clientRequestId) => {
    return httpGet('/service/advisory/clientRequest/allForAccount?accountId='+accountId+'&skipCount=1&limitCount=3&clientRequestId='+clientRequestId).then(res => {
        return res;
    });
};

export const getTransactionOptionsList = () => {
    return httpGet('/service/advisory/txnRequestType/all').then(res => {
        return res;
    });
};

export const initiateTransaction = (data,handleErrorInComponent) => {
    return httpPost('/service/advisory/financialTransaction/initiate', data, handleErrorInComponent).then(res => {
        return res;
    });
};

export const discardTransaction = (requestId) => {
    return httpDelete('/service/advisory/clientRequest?clientRequestId=' + requestId, {}).then(res => {
        return res;
    })
};

export const getSipFrequency =() => {
    return httpGet('/service/advisory/sipFrequency/all')
      .then(res =>{
          return res;
      })
};

export const markOnboardingAsCompleted = (data) => {
    return httpPut('/service/advisory/clientRequest/status/completed',data, true)
        .then(res => {
            return res;
        })
};


export const setSearchKeyword = (data) => {
    store.dispatch(setSearchKeywordValue(data))
};

export const setStatus = (data) => {
    store.dispatch(setStatusValue(data))
};

export const setCRM = (data) => {
    store.dispatch(setCRMValue(data))
};

export const resetClientRequestsFilters = (data) => {
    store.dispatch(resetAllFilters(data))
};

export const setSkipCount = (data) => {
    store.dispatch(setSkipCountValue(data))
};

export const setLimitCount = (data) => {
    store.dispatch(setLimitCountValue(data))
};

export const setPageCount = (data) => {
    store.dispatch(setPageValue(data))
};

export const setClientRequestsRowPerPageCount = (data) => {
    store.dispatch(setRowsPerPageValue(data))
};
