import React from 'react';

import DLText from "../../core/components/DLText/DLText";

const s3BaseURL = process.env.REACT_APP_S3_IMAGE_PUBLIC_BASE_URL;

const LinkExpiredView = (props) => {
    const {responseError, isMobileView} = props;

    const getHeaderMessage = () => {
        if (responseError.includes("expired")) {
            return "Link has expired";
        }
        else if (responseError.includes("used")) {
            return "Link Already Used";
        }
        return "Invalid link";
    }

    const renderLinkExpiredUi = () => {

        return (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <div className="text-center mb-3">
                <span className="p-0">
                    <div className='d-flex align-items-center'>
                              <img
                                  src={s3BaseURL + 'assets/general/linkExpired.png'}
                                  alt={'img'}
                                  className={'img-fluid'}
                                  width={150}
                                  height={100}/>
                    </div>
                </span>
                </div>
                <div className="text-center">
                    <DLText id={'enter-security-code'}
                            alignText={"center"}
                            text={getHeaderMessage()}
                            fontSize={isMobileView? "lg" : "xl"}
                            fontWeight={"bold"}
                            marginBottom={'md'}/>
                    <div className='mb-5' style={{maxWidth: '600px'}}>
                        <DLText id={'eventItem-clone-text1'}
                                type={'normal'}
                                alignText={"center"}
                                fontSize={isMobileView? "sm" : "md"}
                                marginBottom={'none'}
                                text={responseError}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='w-100 d-flex flex-column align-items-center justify-content-center bg-light'
             style={{height: 'calc(100dvh)'}}>
            <div style={{
                padding: "34px 27px 34px 27px"
            }}>
                {renderLinkExpiredUi()}
            </div>
        </div>
    );
};

export default LinkExpiredView;
