import React from 'react';
import moment from "moment";

import './../onboardingListing.css';
import {onboardingApplicationStatuses, onboardingStatuses} from "../../OnboardingConsts";
import {APP_CONST} from "../../../AppComponents/AppConstants";
import {
    getBankMndateFormatedStatus,
    getFullName,
    renderKycStatusIcon, renderOnboardingApplicationStatusDot,
    renderOnboardingStatusDot, renderCategoryStatusBadge
} from "../../OnboardingHelper";
import {isEmpty} from "../../../core/components/DLComponentHelper";
import {cellConfigMobile, managementCellConfig, managementHeadingData} from "../onboardingModel";

import DLText from "../../../core/components/DLText/DLText";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLTable from "../../../core/components/DLTable/DLTable";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

import emptyFilterFilled from "../../../assets/img/appIcons/emptyFilterFilled.png";
import emptyFilter from "../../../assets/img/appIcons/emptyFilter.png";
import filterDot from "../../../assets/img/appIcons/filterDot.png";
import DLDropDown from "../../../core/components/DLDropdown/DLDropDown";
import {handleKeyPress} from "../../../leads/LeadsHelper";
import FilterBadgeView from "../../../leads/components/filterBadge/FilterBadgeView";
import appTheme from "../../../assets/appTheme";


const s3BaseURL = process.env.REACT_APP_S3_IMAGE_PUBLIC_BASE_URL;

const ListingForManagementView = (props) => {
    const {
        UIState, onboardingList, isShowLoader, isXSView, onboardingFilter, onChangeFilter, isMobileView, listingCount,
        rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, getPaginationNextButtonStatus, skipCount,
        resetOnboardingListing, handleNewOnboarding, getCategoryValue,
        statusDropdownValues, CRMDropdownValues, applicationStatusDropdownValues,
        isShowLoaderMaster, ECSStatusDropdownValues,
    } = props;


    function checkIfWrapped() {
        const container = document.getElementById("flex-container");
        if(!isEmpty(props.badgeData.title) && !isEmpty(props.badgeData.data)){
            return ((((props.badgeData.title).length) + ((props.badgeData.data).length)) > 14)
        }
        if(container){
            const children = container.children;
            if (children.length > 1) {
                const firstChildTop = children[0].getBoundingClientRect().top;

                // Loop through all children and compare their top positions
                for (let i = 0; i < children.length; i++) {
                    const childTop = children[i].getBoundingClientRect().top;
                    if (childTop !== firstChildTop) {
                        // The element has wrapped to the next line
                        return true;
                    }
                }
            }
            // No wrapping detected
            return false;
        }

    }

    const {keyword, iinStatus, CRMId, status, ecsStatus} = onboardingFilter;

    const getDocumentCustomUI = (documents) => {
        if (isEmpty(documents)) {
            return '-'
        }

        const isDocumentUpload = () => {
            return (
                <>
                    {documents.map((document, index) => {
                        return (
                            <div key={index}>
                                <div
                                    className={document.isDocumentUploaded ? 'isDocumentupload  bg-success border-success ' : 'isDocumentupload'}
                                    style={{border: document.isDocumentUploaded ? '0.5px' : 'solid 0.5px'}}
                                />
                            </div>
                        );
                    })}
                </>
            );
        };
        return (
            <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center">
                        {isDocumentUpload()}
                    </div>
                </div>
            </div>
        );
    };

    function isFiltersApplied(filters) {
        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (Array.isArray(filters[key])) {
                    if (filters[key].length > 0) {
                        return true;
                    }
                } else if (filters[key] !== '' && key !== 'skipCount' && key !== 'limitCount' && key !== 'page' && key !== 'rowsPerPage') {
                    return true;
                }
            }
        }
        return false;
    }

    const getOnboardingNames = (fields) => {
        const names = fields?.holderNames?.map(accHolder => {
            return getFullName(accHolder?.personName);
        });

        const applicantNames = names?.map((name, index) => {
            return <DLText key={index}
                           id={"onbarding-users"}
                           fontSize={"xs"}
                           fontWeight={"normal"}
                           text={name}
                           marginBottom={(names?.length === index + 1) ? "sm" : "xs"}
                           isClickable={isXSView}
            />
        });

        if (isXSView) {
            const categoryId = isEmpty(fields?.accountCategory) ? '-' : fields?.accountCategory;
            return <div>
                {applicantNames}
                <DLText
                    id={"onbarding-users"}
                    fontSize={"xs"}
                    fontWeight={"semi-bold"}
                    text={renderCategoryStatusBadge(categoryId)}
                />
            </div>
        }
        return <div className={'pt-2'}>
            {applicantNames}
        </div>;
    };

    const getOnboardingKYCStatus = (fields) => {
        let flag = false;
        let count = 0;
        const KYCStatuses = fields?.holderNames?.map(accHolder => {
            return getFullName(accHolder?.kycDescription);
        });
        KYCStatuses?.forEach((name) => {
            if (name !== 'KRA Validated' && name !== 'KRA Verified' && name !== 'Modification Validated') {
                flag = true;
                count += 1;
            }
        });

        const applicantKYCStatuses = renderKycStatusIcon(flag);

        const renderKycUI = (applicantKYCStatuses) => {
            return (<div className='d-flex gap-1'>
                {applicantKYCStatuses}
                {flag && KYCStatuses.length !== 1 &&
                <div>{count}/{KYCStatuses.length}
                </div>
                }
            </div>)
        };
        return <div className={''}>
            {isEmpty(applicantKYCStatuses) ? '-'
                :
                renderKycUI(applicantKYCStatuses)}
        </div>;
    };

    const getOnboardingDetailsMobile = (fields) => {
        return (
            <div style={{flex: 1}} className="mt-1 ps-3 pt-1 section-divider">
                {getOnboardingNames(fields)}
                <div className="row mt-2 pt-1 gx-0">
                    <div className="col-3">
                        <DLText
                            id="funnel-chart-label-right"
                            text="Errors"
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        <DLText
                            id="funnel-chart-label-right"
                            text={getErrorCustomUI(fields.errorCount)}
                            fontSize="xs"
                            marginBottom="none"
                        />
                    </div>
                    <div className="col-3 ps-2">
                        <DLText
                            id="funnel-chart-label-right"
                            text="KYC"
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        <div>
                            {getOnboardingKYCStatus(fields)}
                        </div>
                    </div>
                    <div className="col-6 ps-2">
                        <DLText
                            id="document"
                            text="Documents"
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        {getDocumentCustomUI(fields.documents)}
                    </div>
                </div>

                <div className="row pt-3 mb-1 gx-0">
                    <div className="col-3">
                        <DLText
                            id="funnel-chart-label-right"
                            text="IIN & FATCA"
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        <div>
                            {getOnboardingStatus(fields?.iinStatus, onboardingStatuses, false)}
                        </div>
                    </div>
                    <div className="col-3 ps-2">
                        <DLText
                            id="funnel-chart-label-right"
                            text="Bank Mandate"
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        <div>
                            {renderOnboardingStatusDot(fields?.achMandateStatus)}
                        </div>
                    </div>
                    <div className="col-6 ps-2">
                        <div className=" d-flex" style={{marginTop: '-4px'}}>
                            <div className="rounded-pill"
                                 style={{backgroundColor: appTheme.appColor.grayDark, width: '4px', height: '42px'}}/>
                            <div className="rounded-end ps-1 pe-2 py-1"
                                 style={{backgroundColor: appTheme.appColor.tertiary}}>
                                <DLText
                                    id="status"
                                    text="Status"
                                    fontColor="grayDark"
                                    fontSize="xxs"
                                    marginBottom="none"
                                />
                                <div>
                                    {getOnboardingApplicationStatus(fields?.applicationStatus, onboardingApplicationStatuses, true)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderAccountTypes = (accountType) => {
        if (isEmpty(accountType)) {
            return '-'
        }
        if (accountType === 'INDIVIDUAL') {
            return 'Individual'
        }
        if (accountType === 'JOINT') {
            return 'Joint'
        }
        return accountType
    };

    const getAccountTypeCustomUI = (fields) => {
        const {accountCategories} = props;
        const accountType = renderAccountTypes(fields?.accountType);
        const categoryId = isEmpty(fields?.accountCategory) ? '-' : getCategoryValue(fields?.accountCategory, accountCategories);
        return <div>
            <div>
                <DLText id={''}
                        isInline={true}
                        fontSize={'xs'}
                        fontWeight={'normal'}
                        text={accountType}/>
            </div>
            {(!isEmpty(categoryId)) && (categoryId !== '-') && <div>
                <DLText id={''}
                        isInline={true}
                        fontSize={'xs'}
                        fontWeight={'normal'}
                        text={categoryId}/>
            </div>}
        </div>
    };

    const getErrorCustomUI = (error) => {
        return <div>
            {error}
        </div>
    };

    const getOnboardingStatus = (selectedStatus, onboardingStatuses, isDesktopView) => {
        if (isEmpty(selectedStatus) || isEmpty(onboardingStatuses)) {
            return '-'
        }
        const status = onboardingStatuses.find(status => status.value === selectedStatus);
        if (status) {
            return (
                <div className={"d-flex"}>
                    <div>
                        {renderOnboardingStatusDot(status?.value)}
                    </div>
                    {isDesktopView && <div>
                        <DLText id={''}
                                isInline={true}
                                fontSize={'xs'}
                                fontWeight={'normal'}
                                text={status.label}/>
                    </div>}
                </div>
            )
        } else {
            return '-';
        }
    };

    const getOnboardingApplicationStatus = (selectedStatus, onboardingStatuses, isDesktopView) => {
        if (isEmpty(selectedStatus) || isEmpty(onboardingStatuses)) {
            return '-'
        }
        const status = onboardingStatuses.find(status => status.value === selectedStatus);
        if (!status) {
            return '-';
        }
        return (
            <div className={"d-flex"}>
                <div>
                    {renderOnboardingApplicationStatusDot(status?.value)}
                </div>
                {isDesktopView && <div>
                    <DLText id={''}
                            isInline={true}
                            fontSize={'xs'}
                            fontWeight={'normal'}
                            text={status.label}/>
                </div>}
            </div>
        )
    };

    const getFormattedTableData = (OnboardingList) => {
        let tableData = [];
        const {isXSView} = props;
        if (isEmpty(OnboardingList)) {
            return tableData
        }
        OnboardingList.map((fields) => {
            let OnboardingFields = [];
            let onboardingDetails = {};
            if (!isXSView) {
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getOnboardingNames(fields)
                });
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getAccountTypeCustomUI(fields)
                });
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getDocumentCustomUI(fields.documents)
                });
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getErrorCustomUI(fields.errorCount)
                });
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getOnboardingKYCStatus(fields)
                });
                OnboardingFields.push(getOnboardingApplicationStatus(fields?.applicationStatus, onboardingApplicationStatuses, true));
                OnboardingFields.push(getOnboardingStatus(fields?.iinStatus, onboardingStatuses, true));
                OnboardingFields.push(getBankMndateFormatedStatus(fields?.achMandateStatus));
                OnboardingFields.push(moment(fields.startDate).format("DD-MM-YYYY"));
                OnboardingFields.push(isEmpty(fields?.advisoryUserName) ? '-' : fields?.advisoryUserName);


            } else {
                OnboardingFields.push({
                    isCustomUI: true,
                    customUI: getOnboardingDetailsMobile(fields)
                });
            }
            onboardingDetails.id = fields.onboardingApplicationId;
            onboardingDetails.data = OnboardingFields;
            tableData.push(onboardingDetails);
        });

        return tableData
    };

    const renderDesktopTable = () => {
        const {redirectToDetailsScreen,getCalculatedMinHeight, getCalculatedMaxHeight} = props;
        let isnextRow = checkIfWrapped();
        let tableData = getFormattedTableData(onboardingList);
        if (isEmpty(onboardingList)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center panel'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 360px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100">
                <DLTable
                    id={''}
                    tableMaxHeight={getCalculatedMaxHeight(isnextRow)}
                    tableMinHeight={getCalculatedMinHeight(isnextRow)}
                    isRowClickable={true}
                    onRowClick={(rowId) => {
                        redirectToDetailsScreen(rowId)
                    }}
                    isDynamicHeight={true}
                    cellConfig={managementCellConfig}
                    headerData={managementHeadingData}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderCount = () => {
        const {isXSView, handleRefreshButton} = props;

        if (isXSView) {
            return (
                <div className=' page-container mt-4 mb-3'>
                    {!isEmpty(listingCount) && listingCount !== 0 &&
                    <div className={'d-flex align-items-center '}>
                        <DLText id={''}
                                isInline={true}
                                fontColor={'grayDark'}
                                fontSize={'sm'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                text={'Showing ' + (listingCount) + ' records'}/>
                        <div className={'d-flex align-items-center mx-2'}
                             onClick={() => {
                                 handleRefreshButton()
                             }}>
                            <i className="fa-solid fa-rotate-right cursor"/>
                        </div>
                    </div>}
                </div>)
        }
        return (
            <div className={isMobileView? 'd-flex align-items-center py-3' : 'd-flex align-items-center pb-3'}>
                {!isEmpty(listingCount) && listingCount !== 0 &&
                <div className={'d-flex align-items-center '}>
                    <DLText id={''}
                            isInline={true}
                            fontColor={'grayDark'}
                            fontSize={'sm'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            text={'Showing ' + (listingCount) + ' records'}/>
                    <div className={'d-flex align-items-center mx-2'}
                         onClick={() => {
                             handleRefreshButton()
                         }}>
                        <i className="fa-solid fa-rotate-right cursor"/>
                    </div>
                </div>}
            </div>)

    };

    const renderMobileUI = () => {
        const {isShowFilterMobile, redirectToDetailsScreen, badgeData} = props;
        let tableData = getFormattedTableData(onboardingList);

        if (isEmpty(onboardingList)) {
            return (
                <div className='d-flex align-items-center justify-content-center empty-records'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                    No data found.
                </div>
            )
        }

        return (
            <div className="w-100 pagination-container border-top">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? !isEmpty(badgeData.data) && (badgeData.data !== '-') ? 'calc(100dvh - 539px)' : 'calc(100dvh - 503px)' : 'calc(100dvh - 335px)'}
                    tableMinHeight={isShowFilterMobile ? !isEmpty(badgeData.data) && (badgeData.data !== '-') ? 'calc(100dvh - 539px)' : 'calc(100dvh - 503px)' : 'calc(100dvh - 335px)'}
                    cellConfig={cellConfigMobile}
                    headerData={[]}
                    tableData={tableData}
                    isRowClickable={true}
                    onRowClick={(rowId) => {
                        redirectToDetailsScreen(rowId)
                    }}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        );
    };

    const renderOnboardingTable = () => {
        return (
            <div className={'w-100' + ((isMobileView && !isXSView) ? '  page-container' : '')}>
                {renderCount()}
                {isXSView ?
                    renderMobileUI()
                    :
                    <div>
                        {renderDesktopTable()}
                    </div>
                }
            </div>
        )
    };

    const renderApplicationStatusAndBankMandateFilter = () => {
        return (
            <div className='d-flex justify-content-between flex-wrap gap-2 gap-wrap-3'>
                <div style={{flex: 1}}>
                    <DLDropDown
                        id={"application-status-filter"}
                        label={'Application Status'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={status[0]}
                        minWidth={"100%"}
                        options={applicationStatusDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('status', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div style={{flex: 1}}>
                    <DLDropDown
                        id={"bank-mandate-status-filter"}
                        label={'Bank Mandate'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={ecsStatus[0]}
                        minWidth={"100%"}
                        options={ECSStatusDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('ecsStatus', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
            </div>
        )
    };

    const renderStatusAndCRMFilter = () => {
        return (
            <div className='d-flex justify-content-between flex-wrap gap-2 gap-wrap-3'>
                <div style={{flex: 1}}>
                    <DLDropDown
                        id={"IIN-&-FATCA-filter"}
                        label={'IIN & FATCA'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={iinStatus[0]}
                        minWidth={"100%"}
                        options={statusDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('iinStatus', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div style={{flex: 1}}>
                    <DLDropDown
                        id={"CRM-filter"}
                        label={'CRM'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={CRMId[0]}
                        minWidth={"100%"}
                        options={CRMDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('CRMId', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isShowLoaderMaster}/>);
    };

    const renderFilter = () => {

        const {badgeData} = props;

        return (
            <div className='d-flex'
                 id="flex-container"
                 style={{flexWrap: "wrap"}}>
                <div className='search-input me-3 mb-3'>
                    <DLInputTextField
                        id={'lead-list-search-filter'}
                        label={'Search'}
                        labelFontSize={'sm'}
                        labelFontColor={'grayDark'}
                        marginBottom={"none"}
                        rows={1}
                        styleType={"search"}
                        onChangeValue={(e) => {
                            onChangeFilter('keyword', e)
                        }}
                        value={isEmpty(keyword) ? '' : keyword}
                        placeholder={'Search by Name'}
                        isSearchable={true}
                        size="small"
                        inputHeight={'sm'}
                        inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                        inputType={"text"}
                        disableHelperMessageHeight={true}
                    />
                </div>
                <div className='select-filter me-3 mb-3' style={{minWidth: "250px"}}>
                    <DLDropDown
                        id={"CRM-filter"}
                        label={'CRM'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={CRMId[0]}
                        minWidth={"100%"}
                        options={CRMDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('CRMId', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div className='select-filter mb-3 me-3'
                     style={{minWidth: "250px"}}>
                    <DLDropDown
                        id={"application-status-filter"}
                        label={'Application Status'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={isEmpty(applicationStatusDropdownValues) ? '' : status[0]}
                        minWidth={"100%"}
                        options={applicationStatusDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('status', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div className='select-filter me-3 mb-3'
                     style={{minWidth: "250px"}}>
                    <DLDropDown
                        id={"IIN-&-FATCA-filter"}
                        label={'IIN & FATCA'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={isEmpty(statusDropdownValues) ? '' : iinStatus[0]}
                        minWidth={"100%"}
                        options={statusDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('iinStatus', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div className='select-filter mb-3 me-3'
                     style={{minWidth: "250px"}}>
                    <DLDropDown
                        id={"bank-mandate-status-filter"}
                        label={'Bank Mandate'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={isEmpty(ECSStatusDropdownValues) ? '' : ecsStatus[0]}
                        minWidth={"100%"}
                        options={ECSStatusDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('ecsStatus', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                {!isEmpty(badgeData.data) && (badgeData.data !== '-') &&
                <div className='d-flex align-items-center py-1 my-2'
                id={'badge-filter'}>
                    <FilterBadgeView
                        badgeData={badgeData}
                        onClose={() => {
                            if (badgeData.filterKey === 'agingKey') {
                                onChangeFilter('fromDate', '');
                                onChangeFilter('toDate', '')
                            } else {
                                onChangeFilter(badgeData.filterKey, (badgeData.filterKey === 'title' ? '' : 'ALL'))
                            }
                            onChangeFilter('title', '')
                        }}
                    />
                </div>}
            </div>
        );
    };

    const renderHeaderCreateButton = () => {
        const {isMobileView, isXSView} = props;

        if (isXSView) {
            return (
                <div className={'mx-3'}>
                    <DLButton
                        id={'New-Onboarding-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        padding={'none'}
                        onClick={() => handleNewOnboarding()}
                        startIcon={<i className="fa-solid fa-plus"/>}/>
                </div>
            );
        }
        return (
            <div className={'d-flex justify-content-center pe-2 my-2' + (isMobileView ? ' page-container' : ' mx-4')}>
                <DLButton
                    id={'New-Onboarding-button'}
                    buttonSize={'sm'}
                    reduceWidth={isMobileView}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => handleNewOnboarding()}
                    label={'New Onboarding'}
                />
            </div>
        )

    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between pb-1 pt-3'>
                <div className='page-header pt-0'>
                    Onboardings
                </div>
                <div>
                    {renderHeaderCreateButton()}
                </div>
            </div>
        )
    };

    const renderUi = () => {
        return <div className={'h-100 d-flex justify-content-center' + (isMobileView ? '' : ' bg-light ')}
                    style={{minHeight: 'calc(100dvh - 52px)'}}>
            <div className={'w-100'}>
                {renderHeader()}
                <div className={'page-container'}>
                    {renderFilter()}
                </div>
                <div className={'page-container' + (isMobileView ? '' : ' bg-light ')}>
                    {renderOnboardingTable()}
                </div>
            </div>
        </div>
    };

    const renderFilterIcon = () => {
        const {isShowFilterMobile} = props;
        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        if (isFiltersApplied(onboardingFilter)) {
            return (
                <div className='icon-container'>
                    <img src={filterIcon} alt={''} height={14} width={14}/>
                    <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
                </div>
            );
        }

        return <img src={filterIcon} alt={''} height={14} width={14}/>;
    };

    const renderFilterMobile = () => {
        const {isShowFilterMobile, handleToggleMobileFilter, badgeData} = props;

        return <div>
            <div className='d-flex justify-content-between align-items-center gap-3 page-container '>
                <div className='search-input' style={{flex: 8}}>
                    <DLInputTextField id={'leads-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      styleType={"search"}
                                      onChangeValue={(e) => {
                                          onChangeFilter('keyword', e)
                                      }}
                                      value={keyword}
                                      onKeyPress={handleKeyPress}
                                      placeholder={'Search by Name'}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"text"}
                                      disableHelperMessageHeight={true}

                    />
                </div>
                <div className="d-flex align-items-center" style={{marginBottom: '5px'}}>
                    <DLButton
                        id={'filter-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        startIcon={renderFilterIcon()}
                    />

                    {isFiltersApplied(onboardingFilter) &&
                    <div>
                        <i className="fa-solid fa-xmark cursor ms-2 "
                           onClick={() => resetOnboardingListing()}
                           style={{fontSize: 18}}/>
                    </div>
                    }
                </div>
            </div>
            {isShowFilterMobile &&
            <div className='bg-light mt-2' style={{paddingTop: 10, paddingBottom: 10}}>
                <div className='page-container'>
                    <div className='my-1'>{renderStatusAndCRMFilter()}</div>
                    <div className='my-1'>{renderApplicationStatusAndBankMandateFilter()}</div>
                    <div className='mb-1'>
                        {!isEmpty(badgeData.data) && (badgeData.data !== '-') &&
                        <div className='d-flex align-items-center ' style={{paddingTop: '6px'}}>
                            <FilterBadgeView
                                badgeData={badgeData}
                                onClose={() => {
                                    if (badgeData.filterKey === 'agingKey') {
                                        onChangeFilter('fromDate', '');
                                        onChangeFilter('toDate', '')
                                    } else {
                                        onChangeFilter(badgeData.filterKey, (badgeData.filterKey === 'title' ? '' : 'ALL'))
                                    }
                                    onChangeFilter('title', '')
                                }}
                            />
                        </div>}
                    </div>
                </div>
            </div>
            }
        </div>
    };

    const emptyList = () => {
        return <div className='d-flex justify-content-center align-items-center gray-lg-text'
                    style={{minHeight: 'calc(100dvh - 132px)', overflow: 'hidden', fontSize: '16px'}}>
            <div className='text-center'>
                <img
                    id={'empty-onboarding-img'}
                    src={s3BaseURL + 'assets/general/emptyOnboarding.png'}
                    alt={'img'}
                    className={'img-fluid'}
                    width={'400px'}
                    height={'300px'}/>
                <DLText id={'create-first-onboarding'}
                        text={'Please initiate your first onboarding.'}
                        marginBottom={'md'}
                />
                <DLButton
                    id={'Initiate-button'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => handleNewOnboarding()}
                    label={'Initiate Onboarding'}/>
            </div>
        </div>
    };

    if (isEmpty(UIState)) {

        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    if (UIState === APP_CONST.CONTENT_UNAVAILABLE) {
        return (
            <div className='page-container'>
                {emptyList()}
            </div>
        );
    }

    if (isMobileView) {
        return (
            <div>
                <div className={'w-100'}>
                    {renderHeader()}
                    {renderFilterMobile()}
                    {renderOnboardingTable()}
                    {renderLoader()}
                </div>
            </div>
        );
    }

    return (
        <div>
            {renderUi()}
            {renderLoader()}
        </div>

    );
};

export default ListingForManagementView;