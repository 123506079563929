import React from 'react';

import {isEmpty} from "../../../../../utils/validations";

import DLText from "../../../../../core/components/DLText/DLText";
import DLImage from "../../../../../core/components/DLImage/DLImage";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import appTheme from "../../../../../assets/appTheme";

const AccountHolderView = (props) => {

    const {PEPCode, isMobileView, isShowAadharLoader, isShowPANLoader} = props;

    const renderGender = () => {
        const {gender} = props;
        switch (gender) {
            case 'M':
                return <DLText
                    id={'-id'}
                    type={'normal'}
                    text={'Male'}
                />;
            case 'F':
                return <DLText
                    id={'-id'}
                    type={'normal'}
                    text={'Female'}
                />;
            case 'T':
                return <DLText
                    id={'-id'}
                    type={'normal'}
                    text={'Transgender'}
                />;
            default:
                return <DLText
                    id={'-id'}
                    type={'normal'}
                    text={'-'}
                />


        }
    };

    const renderPersonalInformation = () => {
        const {previewImage, personFullName, maritalStatus, DOB, birthPlace, fathersName, mothersName, taxStatus} = props;

        return (
            <div className={isMobileView ? 'block-padding' : 'bg-white border border-rounded p-3'}
                 style={{
                     minHeight: isMobileView ? 'auto' : '100%',
                     overflow: 'hidden'
                 }}>
                <div className='w-100 text-center'>
                    {isEmpty(previewImage)  ?
                        <div className='w-100 d-flex justify-content-center pb-2'>
                            <div className='border border-rounded d-flex align-items-center justify-content-center'
                                 style={{
                                     height: 180,
                                     width: 180
                                 }}>
                                <div>
                                    Photo not available
                                </div>
                            </div>
                        </div>
                        :
                        <img src={previewImage}
                             alt={''}
                             style={{width:'100%',maxHeight:180,objectFit: 'contain'}}
                        />}
                    <DLText id={'iin-form-id'}
                            text={personFullName}
                            fontSize={"lg"}
                            marginBottom={'none'}
                            fontWeight={"semi-bold"}
                    />
                </div>

                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'taxStatus-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Tax Status'}
                        fontColor={'grayDark'}
                    />
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        text={taxStatus}
                    />
                </div>

                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'gender-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Gender'}
                        fontColor={'grayDark'}
                    />
                    {renderGender()}
                </div>

                <div className='row'>
                    <div className='col-6'>
                        <div className='pt-4'
                             style={{minWidth: '120px'}}>
                            <DLText
                                id={'-id'}
                                type={'normal'}
                                fontSize={'xs'}
                                text={'Date of Birth'}
                                fontColor={'grayDark'}
                            />
                            <DLText
                                id={'-id'}
                                type={'normal'}
                                text={DOB}
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='pt-4'
                             style={{minWidth: '120px'}}>
                            <DLText
                                id={'-id'}
                                type={'normal'}
                                fontSize={'xs'}
                                text={'Birthplace'}
                                fontColor={'grayDark'}
                            />
                            <DLText
                                id={'-id'}
                                type={'normal'}
                                text={birthPlace}
                            />
                        </div>
                    </div>

                </div>

                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Marital Status'}
                        fontColor={'grayDark'}
                    />
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        text={maritalStatus}
                    />
                </div>

                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Father’s Name'}
                        fontColor={'grayDark'}
                    />
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        text={fathersName}
                    />
                </div>

                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Mother’s Name '}
                        fontColor={'grayDark'}
                    />
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        text={mothersName}
                    />
                </div>
            </div>
        )
    };

    const renderContactInfo = () => {
        const {
            address, primaryEmail, primaryPhoneNumber, secondaryEmail, secondaryPhoneNumber, primaryEmailRelation,
            secondaryEmailRelation, primaryPhoneNumberRelation, secondaryPhoneNumberRelation, isSMView, isXSView,
            selectedPersonDetails, getStateName, getCountryName
        } = props;
        let addressDetails = isEmpty(selectedPersonDetails?.personAddresses)? [] : selectedPersonDetails?.personAddresses[0]

        let isSmallScreen = (isSMView || isXSView);
        return (
            <div className={isMobileView ? 'block-padding' : 'bg-white border border-rounded p-3'}>
                <DLText id={'iin-form-id'}
                        text={"Contact Info"}
                        fontSize={"lg"}
                        marginBottom={'none'}
                        fontWeight={"semi-bold"}
                />

                <div>
                    <div className='row'>
                        <div className={isSmallScreen ? 'col-12' : 'col-5'}>
                            <div className='pt-4'>
                                <DLText
                                    id={'Address-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Address'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'-id'}
                                    type={'normal'}
                                    text={address}
                                />
                            </div>
                        </div>
                        <div className={isXSView ? 'col-6' : isSmallScreen ? 'col-12' : 'col-5'}>
                            <div className='pt-4'>
                                <DLText
                                    id={'city-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'City'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'secondaryEmailRelation-id'}
                                    type={'normal'}
                                    text={isEmpty(addressDetails?.city) ? '' : addressDetails?.city}
                                    isInline={true}
                                />
                            </div>
                        </div>
                        <div className={isXSView ? 'col-6' : isSmallScreen ? 'col-12' : 'col-2'}>
                            <div className='pt-4'>
                                <DLText
                                    id={'district-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'District'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'secondaryEmailRelation-id'}
                                    type={'normal'}
                                    text={isEmpty(addressDetails?.district) ? '' : addressDetails?.district}
                                    isInline={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className={isXSView ? 'col-6' : isSmallScreen ? 'col-12' : 'col-5'}>
                            <div className='pt-4'>
                                <DLText
                                    id={'pincode-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Pincode'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'secondaryEmailRelation-id'}
                                    type={'normal'}
                                    text={isEmpty(addressDetails?.zipCode) ? '' : addressDetails?.zipCode}
                                    isInline={true}
                                />
                            </div>
                        </div>
                        <div className={isXSView ? 'col-6' : isSmallScreen ? 'col-12' : 'col-5'}>
                            <div className='pt-4'>
                                <DLText
                                    id={'state-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'State'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'secondaryEmailRelation-id'}
                                    type={'normal'}
                                    text={isEmpty(addressDetails?.stateCode) ? '' : getStateName(addressDetails?.stateCode)}
                                    isInline={true}
                                />
                            </div>
                        </div>
                        <div className={isSmallScreen ? 'col-12' : 'col-2'}>
                            <div className='pt-4'>
                                <DLText
                                    id={'country-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Country'}
                                    fontColor={'grayDark'}
                                />
                                <DLText
                                    id={'secondaryEmailRelation-id'}
                                    type={'normal'}
                                    text={isEmpty(addressDetails?.countryCode) ? '' : getCountryName(addressDetails?.countryCode)}
                                    isInline={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className={isSmallScreen ? 'col-12' : 'col-5'}>
                            <div className='pt-4'>
                                <DLText
                                    id={'primaryEmail-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Primary Email'}
                                    fontColor={'grayDark'}
                                />
                                {(isEmpty(primaryEmail) && isEmpty(primaryEmailRelation)) ? '-' : <div>
                                    <DLText
                                        id={'primaryEmail'}
                                        type={'normal'}
                                        text={
                                            isEmpty(primaryEmail) ? '-' :
                                                <a href={"mailto:" + primaryEmail}
                                                   className='link-primary'
                                                   style={{
                                                       fontSize: '14px'
                                                   }}>{primaryEmail}</a>
                                        }
                                        isInline={true}
                                    />
                                    {!(isEmpty(primaryEmailRelation)) && <DLText
                                        id={'primaryEmailRelation-id'}
                                        type={'normal'}
                                        text={isEmpty(primaryEmailRelation) ? '' : primaryEmailRelation}
                                        isInline={true}
                                    />}
                                </div>}
                            </div>
                        </div>
                        <div className={isSmallScreen ? 'col-12' : 'col-7'}>
                            <div className='pt-4'>
                                <DLText
                                    id={'secondaryEmail-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Secondary Email'}
                                    fontColor={'grayDark'}
                                />
                                {(isEmpty(secondaryEmail) && isEmpty(secondaryEmailRelation)) ? '-' :
                                    <div>
                                        <DLText
                                            id={'secondaryEmail'}
                                            type={'normal'}
                                            text={isEmpty(secondaryEmail) ? '-' :
                                                <a href={"mailto:" + secondaryEmail}
                                                   className='link-primary'
                                                   style={{
                                                       fontSize: '14px'
                                                   }}>{secondaryEmail}</a>}
                                            isInline={true}
                                        />
                                        {!(isEmpty(secondaryEmailRelation)) && <DLText
                                            id={'secondaryEmailRelation-id'}
                                            type={'normal'}
                                            text={isEmpty(secondaryEmailRelation) ? '' : secondaryEmailRelation}
                                            isInline={true}
                                        />}
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className={isSmallScreen ? 'col-12' : 'col-5'}>
                            <div className='pt-4'>
                                <DLText
                                    id={'primaryPhoneNumber-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Primary Mobile'}
                                    fontColor={'grayDark'}
                                />
                                {(isEmpty(primaryPhoneNumber) && isEmpty(primaryPhoneNumberRelation)) ? '-' : <div>
                                    <DLText
                                        id={'primaryPhoneNumber'}
                                        type={'normal'}
                                        text={isEmpty(primaryPhoneNumber) ? '-' :
                                            <a href={"tel:" + primaryPhoneNumber}
                                               className='link-primary '
                                               style={{fontSize: '14px'}}>{primaryPhoneNumber}</a>}
                                        isInline={true}
                                    />
                                    {!(isEmpty(primaryPhoneNumberRelation)) && <DLText
                                        id={'primaryPhoneNumberRelation-id'}
                                        type={'normal'}
                                        text={isEmpty(primaryPhoneNumberRelation) ? '' : primaryPhoneNumberRelation}
                                        isInline={true}
                                    />}
                                </div>}
                            </div>
                        </div>
                        <div className={isSmallScreen ? 'col-12' : 'col-7'}>
                            <div className='pt-4'>
                                <DLText
                                    id={'-id'}
                                    type={'normal'}
                                    fontSize={'xs'}
                                    text={'Secondary Mobile'}
                                    fontColor={'grayDark'}
                                />
                                {(isEmpty(secondaryPhoneNumber) && isEmpty(secondaryPhoneNumberRelation)) ? '-' : <div>
                                    <DLText
                                        id={'secondaryPhoneNumber'}
                                        type={'normal'}
                                        text={isEmpty(secondaryPhoneNumber) ? '-' :
                                            <a href={"tel:" + secondaryPhoneNumber}
                                               className='link-primary '
                                               style={{fontSize: '14px'}}>{secondaryPhoneNumber}</a>}
                                        isInline={true}
                                    />
                                    {!(isEmpty(secondaryPhoneNumberRelation)) && <DLText
                                        id={'secondaryPhoneNumberRelation-id'}
                                        type={'normal'}
                                        text={isEmpty(secondaryPhoneNumberRelation) ? '' : secondaryPhoneNumberRelation}
                                        isInline={true}
                                    />}
                                </div>}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    };

    const renderProfessionInfo = () => {

        const {companyName, occupationCode, industryType} = props;

        return (
            <div className={isMobileView ? 'block-padding' : 'bg-white border border-rounded p-3'}>
                <DLText id={'iin-form-id'}
                        text={"Profession"}
                        fontSize={"lg"}
                        marginBottom={'none'}
                        fontWeight={"semi-bold"}
                />

                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Occupation'}
                        fontColor={'grayDark'}
                    />
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        text={occupationCode}
                        marginBottom={'none'}
                    />
                </div>

                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Company Name'}
                        fontColor={'grayDark'}
                    />
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        text={companyName}
                        marginBottom={'none'}
                    />
                </div>

                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Industry Type'}
                        fontColor={'grayDark'}
                    />
                    <DLText
                        id={'-id'}
                        type={'normal'}
                        text={industryType}
                        marginBottom={'none'}
                    />
                </div>
            </div>
        )
    };

    const renderDocumentsInfo = () => {
        const {
            PANNumber, aadhaarNumber, nameAsPerAadhaar, nameAsPerPAN, PANFilePath, aadharFilePath,
            downloadAndViewDocument, personFullName, PANDownloadFilePath, AadhaarDownloadFilePath
        } = props;
        let personName = '';
        if(!isEmpty(personFullName)){
            personName = personFullName.replace(/\s+/g, '_')
        }
        return (
            <div className={isMobileView ? 'block-padding' : 'bg-white border border-rounded p-3'}
                 style={{
                     minHeight: 358
                 }}>
                <DLText id={'iin-form-id'}
                        text={"Documents"}
                        fontSize={"lg"}
                        marginBottom={'none'}
                        fontWeight={"semi-bold"}
                />

                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'PANNumber-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'PAN'}
                        fontColor={'grayDark'}
                    />
                    <div className='d-flex'>
                        {
                            isEmpty(PANFilePath) ?
                                <DLText
                                    id={'PANNumber'}
                                    type={'normal'}
                                    isInline={true}
                                    text={isEmpty(PANNumber) ? '' : PANNumber}
                                />
                                :
                                <DLImage id={''}
                                         onClick={()=>{}}
                                         src={PANFilePath}
                                         downloadFileName={isEmpty(personName) ? 'PAN_CARD' :(personName+'_PAN.jpg')}
                                         resizeMode={"cover"}
                                         isCustomUI={true}
                                         customUI={
                                             <DLText
                                                 id={'PANNumber'}
                                                 type={'normal'}
                                                 isClickable={true}
                                                 isInline={true}
                                                 text={isEmpty(PANNumber) ? '' : PANNumber}
                                             />
                                         }
                                         style={{
                                             minWidth: '100%',
                                             maxHeight: '100px',
                                             objectPosition: "top",
                                             borderBottomRightRadius: 8,
                                             borderBottomLeftRadius: 8,
                                         }}
                                         allowFullScreen={true}
                                />
                        }

                        {
                            !(isEmpty(PANFilePath)) && <span>
                            <i className='fa-solid fa-download default-icon cursor ps-1'
                               onClick={() => {
                                   downloadAndViewDocument('Download', PANDownloadFilePath, isEmpty(personName) ? 'PAN_CARD' :(personName+'_PAN'))
                               }}
                            />
                        </span>
                        }
                    </div>

                </div>

                <div className='pt-4 pb-4 border-bottom'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'nameAsPerPAN-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Name as per PAN'}
                        fontColor={'grayDark'}
                    />
                    <DLText
                        id={'nameAsPerPAN'}
                        type={'normal'}
                        text={isEmpty(nameAsPerPAN) ? '' : nameAsPerPAN}
                    />
                </div>

                <div className='pt-4 text-truncate'
                     style={{maxWidth: '80%'}}>
                    <DLText
                        id={'aadhaarNumber-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Aadhaar Number'}
                        fontColor={'grayDark'}
                    />
                    <div className='d-flex'>

                        {
                            isEmpty(aadharFilePath) ?
                                <DLText
                                    id={'aadhaarNumber'}
                                    type={'normal'}
                                    isInline={true}
                                    text={isEmpty(aadhaarNumber) ? '' : aadhaarNumber}
                                />
                                :
                                <DLImage id={''}
                                         onClick={()=>{}}
                                         src={aadharFilePath}
                                         downloadFileName={isEmpty(personName) ? 'AADHAAR_CARD' : (personName+'_AADHAAR.jpg')}
                                         resizeMode={"cover"}
                                         isCustomUI={true}
                                         customUI={
                                             <DLText
                                                 id={'aadhaarNumber'}
                                                 type={'normal'}
                                                 isClickable={!isEmpty(aadhaarNumber)}
                                                 isInline={true}
                                                 text={isEmpty(aadhaarNumber) ? '' : aadhaarNumber}
                                             />
                                         }
                                         style={{
                                             minWidth: '100%',
                                             maxHeight: '100px',
                                             objectPosition: "top",
                                             borderBottomRightRadius: 8,
                                             borderBottomLeftRadius: 8,
                                         }}
                                         allowFullScreen={true}
                                />
                        }
                        {!(isEmpty(aadharFilePath)) && <span>
                                <i className='fa-solid fa-download default-icon cursor ps-1'
                                   onClick={() => {
                                       downloadAndViewDocument('Download', AadhaarDownloadFilePath, isEmpty(personName) ? 'AADHAAR_CARD' :(personName+'_AADHAAR'))
                                   }}
                                />
                            </span>}
                    </div>

                </div>

                <div className='pt-4'
                     style={{minWidth: '120px'}}>
                    <DLText
                        id={'nameAsPerAadhaar-id'}
                        type={'normal'}
                        fontSize={'xs'}
                        text={'Name as per Aadhaar'}
                        fontColor={'grayDark'}
                    />
                    <DLText
                        id={'nameAsPerAadhaar'}
                        type={'normal'}
                        text={isEmpty(nameAsPerAadhaar) ? '' : nameAsPerAadhaar}
                    />
                </div>
            </div>
        )
    };

    const renderDesktopUI = () => {
        return (
            <div className='page-container'>
                <div className='row m-0 p-0 pt-3 mt-1 pb-4'>
                    <div className={isMobileView ? 'col-12 p-0 m-0' : 'col-3 p-0 m-0 pe-2'}>
                        {renderPersonalInformation()}
                    </div>

                    <div className='col-9 p-0 m-0 ps-2'>
                        {renderContactInfo()}
                        <div className='row m-0 p-0 pt-2 mt-2'>
                            <div className='col-6 p-0 m-0 pe-2'>
                                {renderProfessionInfo()}
                                <div className='bg-white border border-rounded p-3 mt-3'>
                                    {PEPCode}
                                </div>
                            </div>
                            <div className='col-6 p-0 m-0 ps-2'>
                                {renderDocumentsInfo()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    };

    const renderMobileUI = () => {
        return (<div>
            <div className={'row m-0 p-0 pt-3 mt-1 pb-4'}>
                <div className='col-12 p-0 m-0'>
                    {renderPersonalInformation()}
                    <div style={{ height: '5px', backgroundColor: appTheme.appColor.gray }} />
                </div>

                <div className='col-12 p-0 m-0'>
                    {renderContactInfo()}
                    <div style={{ height: '5px', backgroundColor: appTheme.appColor.gray }} />
                    <div className='row p-0 m-0'>
                        <div className='col-12 p-0 m-0'>
                            {renderProfessionInfo()}
                        </div>
                        <div style={{ height: '5px', backgroundColor: appTheme.appColor.gray }} />
                        <div className={isMobileView ? 'col-12 p-0 m-0' : 'col-6 p-0 m-0 ps-2'}>
                            {renderDocumentsInfo()}
                        </div>
                        <div style={{ height: '5px', backgroundColor: appTheme.appColor.gray }} />
                        <div className='block-padding pb-0'>
                            {PEPCode}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    };
    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={props.isShowLoader || isShowAadharLoader || isShowPANLoader}/>);
    };

    return (
        <div>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            {renderLoader()}
        </div>
    );
};

export default AccountHolderView;