export const headingData = ['Name', 'IIN Activation Date'];

export const managementHeadingData = ['Name', 'CRM', 'IIN Activation Date'];

export const headingMobileData = ['Name', 'CRM & IIN Activation Date'];

export const managementCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    }
];

export const cellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    }
];

export const cellConfigMobile = [
    {
        textAlign: 'left',
        width: 'auto',
        padding: '0 8px 0 16px',
        minWidth: '80px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '155px',
        maxWidth: '155px'
    }
];