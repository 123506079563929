import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLDropDownView from './DLDropDownView';

class DLDropDown extends Component {

  render() {
    return (
      <DLDropDownView
          {
            ...this.props
          }
      />
    );
  }
}

DLDropDown.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  type: PropTypes.oneOf(['normal','inline-edit','sort-options']),
  label: PropTypes.any.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired, // options is an array of label, value args
  onSelect: PropTypes.func.isRequired,
  isClearable: PropTypes.bool,   // applicable when type is inline-edit
  enableTranslation: PropTypes.bool,
  isRequired: PropTypes.bool,
  minWidth:PropTypes.string,
  inputHeight:PropTypes.string,
  fontColor: PropTypes.oneOf([
    'primary', 'secondary', 'tertiary', 'success', 'successLight', 'danger', 'darkBlue', 'lightBlue', 'grayLight', 'grayDark', 'gray', 'disable', 'black', 'white'
  ]),
  title:PropTypes.string,
  fontSize:PropTypes.string,
  isHideIcon: PropTypes.bool, // hide pencil icon
  showBottomPopUp: PropTypes.bool, // to show dropdown option from bottom pop up
  marginBottom: PropTypes.oneOf([
    'none',
    'xxs',
    'xs',
    'sm',
    'md',
  ]),
  disableHelperMessageHeight: PropTypes.bool,    // to disable the min height for input text field if helper message passed it will expand in bottom
  error: PropTypes.bool,           // to show in red color
  labelFontSize: PropTypes.string,
  helperMessage: PropTypes.string,
  labelFontColor: PropTypes.oneOf([
    'primary', 'secondary', 'tertiary', 'success', 'successLight', 'danger', 'darkBlue', 'lightBlue', 'grayLight', 'grayDark', 'gray', 'disable', 'black', 'white'
  ]),
  labelPlacement: PropTypes.oneOf([
    'top', 'inside'
  ]),
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
  maxCharLength: PropTypes.number,  // to limit the characters in the string
  hideCharLength: PropTypes.bool,   // to hide char length which shown on right bottom corner
  placeholder: PropTypes.string,  // placeholder for the textfield,
  bgColor: PropTypes.oneOf([
    'primary', 'secondary', 'tertiary', 'success', 'successLight', 'danger', 'darkBlue', 'lightBlue', 'grayLight', 'grayDark', 'gray', 'disable', 'black', 'white'
  ]),
};


DLDropDown.defaultProps = {
  type: 'normal',
  isClearable: false,
  marginBottom: 'sm',
  minWidth:'200px',
  inputHeight:'sm',
  title:'More options',
  showBottomPopUp: false,
  fontColor: 'black',
  fontSize: 'sm',
  isDisabled: false,
  disableHelperMessageHeight: false,
  error: false,
  labelFontColor: 'grayDark',
  labelPlacement: 'inside',
  hideCharLength: true,
  placeholder:'',
  labelFontSize:'xs',
  isRequired: false,
  bgColor: 'white'
};

export default DLDropDown;
