import React from 'react';
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext/TabContext";
import appTheme from "../../../../../assets/appTheme";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";

import {isEmpty} from "../../../../../utils/validations";

import DLLoader from "../../../../../core/components/DLLoader/DLLoader";

import AccountDetails from "../accountDetails/AccountDetails";
import AccountHolder from "../accountHolder/AccountHolder";

const ProfileView = (props) => {
    const {isMobileView, selectedProfileTab, selectedPersonDetails, isApiInProgress, isXSView} = props;

    const renderTabs = () => {
        const {allTabs, selectedProfileTab, handleTabChange} = props;

        return allTabs?.map((item, index) => {
            return (
                <Tab key={'tab_' + index}
                     label={item.label}
                     onClick={() => {
                         handleTabChange(item)
                     }}
                     style={{
                         textTransform: 'none',
                         color: appTheme.appColor.black,
                         fontFamily: appTheme.default.fontFamily,
                         fontWeight: (item?.value === selectedProfileTab?.value) ? 500 : 400
                     }}
                     value={item.value}/>

            );
        })
    };

    const renderTabContentUI = () => {
        const {
            selectedProfileTab, accountDetails, accountType, CRMs, categoryData, occupationData, industryData,
            belongsToData, politicallyExposedData, taxStatuses, isCorporateClient, getStateName, getCountryName
        } = props;

        switch (selectedProfileTab?.value) {
            case 'ACCOUNT_DETAILS':
                return <AccountDetails
                    {...props}
                    accountType={accountType}
                    accountDetails={accountDetails}
                    CRMs={CRMs}
                    categoryData={categoryData}
                    taxStatuses={taxStatuses}
                    occupationData={occupationData}
                    isCorporateClient={isCorporateClient}
                    getCountryName={getCountryName}
                />;

            case ('ACCOUNT_HOLDER_1'):
            case ('ACCOUNT_HOLDER_2'):
            case ('ACCOUNT_HOLDER_3'):
            case ('GUARDIAN'):
                return <AccountHolder
                    {...props}
                    selectedPersonDetails={selectedPersonDetails}
                    occupationData={occupationData}
                    industryData={industryData}
                    belongsToData={belongsToData}
                    politicallyExposedData={politicallyExposedData}
                    taxStatuses={taxStatuses}
                    isCorporateClient={isCorporateClient}
                    getStateName={getStateName}
                    getCountryName={getCountryName}
                />;
        }
    };

    const renderDesktopTabsUI = () => {

        const {allTabs} = props;

        if (isEmpty(allTabs)) {
            return
        }

        return (
            <div style={{flex: 0.7}}>
                <div>
                    <div className="page-container">
                        <Box sx={{width: '100%'}}>
                            <TabContext value={selectedProfileTab?.value}>
                                <Box sx={{borderBottom: 2, borderColor: appTheme.appColor.grayLight}}>
                                    <TabList onChange={() => {
                                    }} aria-label="lab API tabs-example"
                                             variant="scrollable"
                                             scrollButtons={false}>
                                        {renderTabs()}
                                    </TabList>
                                </Box>
                            </TabContext>
                        </Box>
                    </div>
                    <div className={isMobileView ? 'bg-white' : 'bg-light'}
                         style={{
                             minHeight: isMobileView ? 'calc(100dvh - 230px)' : 'calc(100dvh - 206px)',
                         }}>
                        {renderTabContentUI()}
                    </div>
                </div>
            </div>
        )
    };

    const renderErrorUI = () => {
        const {profileResponseErrors, totalErrors} = props;

        if (isEmpty(profileResponseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? '' : 'mb-2'}>
                <div className='pb-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {profileResponseErrors}
                    </div>
                </div>

            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isApiInProgress}/>);
    };

    const renderDesktopUI = () => {
        return (
            <div>
                {renderDesktopTabsUI()}
                {renderErrorUI()}
            </div>
        );
    };

    return (
        <div style={{
            maxHeight: isXSView ? 'calc(100dvh - 210px)' : isMobileView ? 'calc(100dvh - 172px)' : 'calc(100dvh - 152px)',
            overflow: 'auto',
        }}>
            {renderDesktopUI()}
            {renderLoader()}
        </div>
    );
};

export default ProfileView;