import React from 'react';
import {getRandomId, isEmpty} from "../../../../core/components/DLComponentHelper";
import {getTimeFromNowWithDate} from "../../../../utils/helper";
import {renderNFTDetailsStatusBadge} from "../../NFTHelper";
import Menu from "@mui/material/Menu/Menu";
import appTheme from "../../../../assets/appTheme";
import MenuItem from "@mui/material/MenuItem";
import DLText from "../../../../core/components/DLText/DLText";
import DLButton from "../../../../core/components/DLButton/DLButton";
import DLLoader from "../../../../core/components/DLLoader/DLLoader";
import HistoryBlock from "./component/historyBlock/HistoryBlock";
import AddRemark from "./component/addRemark/AddRemark";
import RemoveNFTDetails from "./component/removeModel/RemoveNFTDetails";
import ConfirmationModel from "./component/ConfirmationModel";
import InitiateModel from "./component/InitiateModel";

const NFTDetailsView = (props) => {
    const {
        isMobileView,
        openDate,
        category,
        advisoryUserId,
        IINNumber,
        accountType,
        remark,
        status,
        isShowLoader,
        handleOpenInitiateModal,
        handleOpenAddRemarkModal,
        handleConfirmationModal,
        UIState,
        handleOpenRemoveModal,
        isMarkAsCompleted,
        getAdvisoryUser,
        typeOfTransaction,
        isManagementUser,
        companyName,
        handleItemOpen,
        anchorEl,
        handleItemClose,
        accountHolderDetails,
        corporateDetails
    } = props;

    const renderAddRemarkModal = () => {
        const {isAddRemarkModalOpen} = props;

        if (isAddRemarkModalOpen) {
            return (
                <AddRemark {...props}
                />
            )
        }
    };

    const getFormattedTitle = (date) => {
        const newDate = (date === '-') ? '-' : getTimeFromNowWithDate(date, 'DD-MM-YYYY');
        return 'by ' + getAdvisoryUser(advisoryUserId) +' '+ newDate;
    };


    const renderAccountDetails = () => {
        let allAccountHoldersNames = "";

        if(isEmpty(companyName)){
            accountHolderDetails?.map((applicant) => {
                if (isEmpty(allAccountHoldersNames)) {
                    allAccountHoldersNames = applicant?.fullName;
                } else {
                    allAccountHoldersNames = allAccountHoldersNames + ', ' + applicant?.fullName;
                }
            });
        }else{
            allAccountHoldersNames=companyName;
        }

        return (
            <div key={'accountDetails'}
                 className='d-flex align-items-center '>
                <div>
                    {(!isMobileView) &&
                        <div onClick={(event) => {
                            handleItemOpen(event)
                        }}>
                            <div key={getRandomId()}>
                                <DLText id={'account-holder-name'}
                                        text={allAccountHoldersNames}
                                        marginBottom={"none"}
                                        isClickable={true}
                                />
                            </div>
                        </div>
                    }
                    <Menu id="items-menu"
                          anchorEl={anchorEl}
                          getcontentanchorel={null}
                          anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                          transformOrigin={{vertical: "top", horizontal: "left"}}
                          open={Boolean(anchorEl)}
                          onClose={() => handleItemClose()}
                          className="p-2 w-100"
                          disableAutoFocusItem
                          elevation={0}
                          PaperProps={{
                              style: {
                                  marginTop: '0px',
                                  boxShadow: '0 4px 4px rgba(147, 147, 147, 0.25)',
                                  borderRadius: '8px'
                              }
                          }}
                          MenuListProps={{
                              style: {
                                  color: appTheme.appColor.black,
                                  maxHeight: '400px',
                                  textAlign: 'center',
                                  borderRadius: '8px'
                              },
                          }}>

                        <MenuItem key={'menuItem-'}
                                  className={'py-0 text-center'}
                                  style={{minWidth: 200, height: "max-content", cursor: "auto"}} disableRipple>
                            <div className='text-start'>
                                {renderAccountHoldersDetails(accountHolderDetails)}
                            </div>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        );
    };

    const renderAccountHoldersDetails = (accountHolderDetails) => {
        const {isCorporateUser, corporateDetails} = props;

        if(isCorporateUser){
            return (
                <div className={'mb-1'}>
                    <div className='text-start'>
                        <DLText
                            id={'account-holder-name'}
                            text={corporateDetails?.name}
                            marginBottom={"none"}
                            fontSize={"xs"}
                        />
                        <div>
                            {isEmpty(corporateDetails.email) ? '-' :<a href={'mailto:' + corporateDetails.email}
                                                                       style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                                {corporateDetails.email}
                            </a>}
                        </div>
                        <div>
                            {isEmpty(corporateDetails.phoneNumber) ? '-' :<a href={'tel:' + corporateDetails.phoneNumber}
                                                                             style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                                {corporateDetails.phoneNumber}
                            </a>}
                        </div>
                    </div>
                </div>
            )
        }

        return accountHolderDetails?.map((accountHolder, index) => (
            <div key={'menuItem-' + accountHolder.id} className={(accountHolderDetails?.length === index + 1) ? 'mb-1' : 'border-bottom mb-1 pb-2'}>

                <div className='text-start'>
                    <DLText
                        id={'account-holder-name'}
                        text={'Account Holder ' + (index + 1) + ': ' + accountHolder.fullName}
                        marginBottom={"none"}
                        fontSize={"xs"}
                    />
                    <div>
                        {isEmpty(accountHolder.email) ? '-' : <a href={'mailto:' + accountHolder.email}
                                                                 style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                            {accountHolder.email}
                        </a>}
                    </div>
                    <div>
                        {isEmpty(accountHolder.phoneNumber) ? '-' : <a href={'tel:' + accountHolder.phoneNumber}
                                                                       style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                            {accountHolder.phoneNumber}
                        </a>}
                    </div>
                </div>
            </div>
        ));
    };


    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div className='page-header d-flex justify-content-between'>
                    <div className="d-flex align-items-center">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{width: 10,
                               paddingTop:isMobileView ? '0px' : "3px"}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            Non-Financial Transaction: {typeOfTransaction}
                        </div>
                    </div>

                </div>
                <div className='d-flex align-items-center mb-3 page-container '>
                    <div className='pe-2 border-end-black d-flex '>
                        <DLText id={'ac-details-name'}
                                fontWeight={'semi-bold'}
                                text={accountType + ' - ' + IINNumber}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black cursor'>
                        <DLText id={'category'}
                                text={category}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black'>
                        {renderAccountDetails()}
                    </div>
                    <div className='px-2'>
                        {renderNFTDetailsStatusBadge(status)}
                    </div>
                </div>
            </div>
        )
    };

    const renderModalError = () => {
        const {responseError} = props;
        if (responseError) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            )
        }
    };

    const renderMultilineRemark = (remarks) => {
        if (isEmpty(remarks)) {
            return ('-')
        }
        const lines = remarks.split('\n');
        return lines.map((line,index) => {
            if (isEmpty(line)) {
                return (
                    <br key={'empty-name-'+index}/>
                )
            }
            return (
                <div key={'empty-name-'+index}>
                    <DLText id={'ViewActivity-Summary'}
                            fontSize={'xs'}
                            marginBottom={"none"}
                            text={line}/>
                </div>
            )
        })
    };

    const renderButtons = () => {
        if (isManagementUser) {
            if (status !== 'COMPLETED') {
                return (
                    <div className="sticky-bottom-buttons gap-0">
                        <DLButton id={"Remove-button"}
                                  type={"danger"}
                                  styleType={'outlined'}
                                  sideMargin={'md'}
                                  padding={'none'}
                                  onClick={() => {
                                      handleOpenRemoveModal();
                                  }}
                                  label={'Discard'}
                        />
                        <DLButton id={'add-a_remark-button'}
                                  styleType={"outlined"}
                                  type={"black"}
                                  sideMargin={'md'}
                                  padding={'none'}
                                  onClick={() => {
                                      handleOpenAddRemarkModal();
                                  }}
                                  label={'Add a Remark'}
                        />
                    </div>
                )
            }
            return <></>
        }
        if (status === 'COMPLETED'){
            return <></>
        }
        return (
            <div className="sticky-bottom-buttons gap-0">
                {status !== 'COMPLETED' &&
                    <DLButton id={'add-a_remark-button'}
                              styleType={"outlined"}
                              type={"black"}
                              sideMargin={'md'}
                              padding={'none'}
                              onClick={() => {
                                  handleOpenAddRemarkModal();
                              }}
                              label={'Add a Remark'}
                    />
                }

                {status === 'OPEN' && <DLButton
                    id={"assign-to-CRM-button"}
                    sideMargin={'md'}
                    padding={'none'}
                    onClick={() => {
                        handleOpenInitiateModal();
                    }}
                    label={'Mark as In Progress'}
                />}

                {status === 'IN_PROGRESS' && !isMarkAsCompleted && <DLButton
                    id={'mark-as-complete-button'}
                    sideMargin={'md'}
                    padding={'none'}
                    onClick={() => {
                        handleConfirmationModal(true);
                    }}
                    label={'Mark as Complete'}
                />}

            </div>
        )
    }

    const renderDesktopDetails = () => {
        return (
            <div className="h-100 bg-white border-rounded p-3 "
                 style={{
                     minHeight: status === "COMPLETED" ? "calc(100vh - 175px)" : "calc(100vh - 211px)",
                     maxHeight: status === "COMPLETED" ? "calc(100vh - 175px)" : "calc(100vh - 211px)",
                 }}>
                <div className='pt-1 ms-1'>
                    <DLText
                        id={'NFT-details-title'}
                        type={'normal'}
                        text={'Request'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                {renderModalError()}
                <div className='h-100 d-flex ms-1'>
                    <div className='d-flex flex-column flex-grow-1'>
                        <div className='pt-3'>
                            <div className='d-flex gap-4 py-2'>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'remark'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={getFormattedTitle(openDate)}
                                        fontColor={'grayDark'}
                                    />
                                    {renderMultilineRemark(remark)}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {renderButtons()}
            </div>
        )
    };

    const renderDesktopNFTData = () => {
        return (<div className="pb-4">
            <div className=" row">
                <div className='col-6'>
                    {renderDesktopDetails()}
                </div>
                <div className=' col-6'>
                    <HistoryBlock {...props}/>
                </div>
            </div>
        </div>)
    };

    const renderDesktopUI = () => {
        return (
            <div className='bg-light h-100'>
                {renderDesktopHeader()}
                <div className={'page-container page-container bg-light '}>
                    {renderDesktopNFTData()}
                </div>
            </div>
        );
    };

    const renderMobileDetails = () => {
        return (
            <div>
                <div className='mb-1 p-3 bg-white'>
                    <div className={'mb-3'}>
                        <DLText id={'details'}
                                text={'Request'}
                                fontSize={"lg"}
                                marginBottom={'none'}
                                fontWeight={"semi-bold"}
                                isInline={true}/>
                    </div>
                    <div className=''>
                        <div className='text-start pt-1'>
                            <DLText
                                id={'details-assigned'}
                                type={'normal'}
                                text={getFormattedTitle(openDate)}
                                fontColor={'grayDark'}
                                marginBottom={'sm'}
                            />
                            <div className={'pt-1'}>
                                <DLText
                                    id={'remark-assigned'}
                                    type={'normal'}
                                    text={isEmpty(remark) ? '-' : remark}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='p-3 bg-white'>
                    <HistoryBlock {...props}/>
                </div>
            </div>
        )
    };

    const renderMobileHeader = () => {
        const nameLength = !isEmpty(accountHolderDetails) ? accountHolderDetails?.length : !isEmpty(corporateDetails.name) ? 1 : 0;
        return (
            <div className='page-header mb-1 bg-white'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex '>
                        <i className='fa-solid fa-chevron-left cursor pt-3 fa-2xs'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className='ps-3'>
                            Non-Financial Transaction:
                            {" " + typeOfTransaction}
                        </div>
                    </div>
                </div>
                <div className='ps-4 ms-1'>
                    <div className='pe-2 d-flex'>
                        <DLText id={'ac-details-name'}
                                fontWeight={'semi-bold'}
                                text={accountType + '-' + IINNumber}
                                marginBottom={"none"}
                        />
                        <div className={'d-flex ps-1'} onClick={(event) => {
                            handleItemOpen(event)
                        }}>
                            <DLText id={''}
                                    isClickable={nameLength > 0}
                                    text={'(' + nameLength + ')'}
                                    marginBottom={"none"}
                            />
                        </div>
                    </div>
                    {renderAccountDetails()}
                    <div className='d-flex align-items-center'>
                        <DLText id={'category-email'}
                                text={category}
                                marginBottom={"none"}
                        />
                        <div className='ms-2 d-flex' style={{paddingTop: '6px'}}>
                            {renderNFTDetailsStatusBadge(status)}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileNFTData = () => {
        return (
            <div className="">
                <div className="">
                    {renderMobileDetails()}
                </div>
                {renderButtons()}
            </div>
        );
    };

    const renderRemoveModal = () => {
        const { isRemoveModalOpen } = props;
        if (isRemoveModalOpen) {
            return (
                <RemoveNFTDetails {...props} />
            );
        }
        return null;
    };


    const renderConfirmationModel = () => {
        return (
            <ConfirmationModel
                {...props}
            />
        );
    };

    const renderInitiateModel = () => {
        return (
            <InitiateModel
                {...props}
            />
        );
    };

    const renderMobileUI = () => {
        return (
            <div className={"bg-light"}>
                {renderMobileHeader()}
                {renderMobileNFTData()}
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    if (isEmpty(UIState)) {
        return renderLoader()
    }

    return (
        <div className='h-100'>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            {renderLoader()}
            {renderAddRemarkModal()}
            {renderConfirmationModel()}
            {renderRemoveModal()}
            {renderInitiateModel()}
        </div>
    );
};

export default NFTDetailsView;