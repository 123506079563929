import React from 'react';

import {connect} from "react-redux";

import ApprovedOrRejectedView from "./ApprovedOrRejectedView";

const ApprovedOrRejected = (props) => {

    const closeWindow = () =>{
        window.close()
    }

    return (
        <ApprovedOrRejectedView
            {...props}
            closeWindow={closeWindow}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView
});

export default connect(mapStateToProps, {})(ApprovedOrRejected);

