//noinspection DuplicatedCode,JSUnresolvedVariable,SpellCheckingInspection,JSUnresolvedFunction

import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {
  getBgColor,
  getFontColor,
  getFontFamily,
  getFontSize,
  getRandomId,
  getTheme,
  getThemeColor,
  isEmpty
} from "./../DLComponentHelper";
import {getHeight, muiThemeObj} from "./../DLComponentHelperWeb";
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import '../../../assets/css/dlComponent.css'

const inputMarginBottoms = {
  'none': '',
  'xxs': 'mb-1',
  'xs': 'mb-2',
  'sm': 'mb-3',
  'md': 'mb-4'
};

class DLInputTextFieldView extends Component {

  localOnBlur = () => {
    if (this.props.onBlur) {
      return this.props.onBlur()
    } else {
      return null
    }
  };

  getClasses = () =>{
    const {styleType} = this.props;

    if (styleType === 'normal'){
      return ''
    }
    if (styleType === 'search'){
      return 'searchable'
    }

    return 'dl-input-textfield'
  };

  getInputHeight = () =>{
    const {isMulti, inputHeight, styleType} = this.props;

    if (isMulti){
      return ''
    }

    if (styleType === 'normal'){
      return
    }

    return {
      height: getHeight(inputHeight),
    }

  };

  getStyle = (labelFontColor, labelFontSize, error)  =>{

    return{
      fontFamily: getFontFamily(),
      ...getFontSize(labelFontSize),
      color: (error && this.props.isRequired )? getThemeColor('danger') : getFontColor(labelFontColor)
    }
  };

  render() {
    let {
      id, onChangeValue, minNumber, maxNumber, inputType, styleType, value, isDisabled, rows,
      isRequired, label, error, InputProps, isClearable, placeholder, maxCharLength, inputProps,
      helperMessage, marginBottom, hideCharLength, maxRows, disableHelperMessageHeight, style, onPaste, onCut, isMulti
      , onCopy, autoCompleteOff, autoFocus, onKeyPress, onEnter, isHandleKeypadClick, fontSize, labelFontColor, labelFontSize,
      bgColor
    } = this.props;   // DO NOT REMOVE ANY PROPS

    let theme = getTheme();

    const textFieldTheme = createTheme({
      ...muiThemeObj(theme)
    });

    let customProps = {};
    if (autoCompleteOff) {
      customProps = {
        ...customProps,
        autoComplete: 'new-password'
      }
    }

    return (
      <ThemeProvider theme={textFieldTheme}>
        <div className={inputMarginBottoms[marginBottom]}>
          <div style={{
            lineHeight: '21px',
            marginBottom: isEmpty(label) ? '0px' : '4px',
            ...style
          }}
          >
            {
              <span  style={{...this.getStyle(labelFontColor, labelFontSize, error)}}>{label}</span>
            }
            {/*{*/}
            {/*  isRequired ? (<span style={{color: getThemeColor('danger')}}>*</span>) : null*/}
            {/*}*/}
          </div>
          <TextField
            onSelect={this.props.updateSelectionStart}
            inputRef={this.props.inputRef}
            id={id + '-input'}
            InputLabelProps={{style: {fontFamily: getFontFamily()}}}
            className={this.getClasses()}
            fullWidth
            size="small"
            multiline={isMulti}
            minRows={isEmpty(rows) ? maxRows : rows}
            maxRows={isEmpty(maxRows) ? rows : maxRows}
            disabled={isDisabled}
            value={value}
            variant={(styleType === 'rounded' || styleType === 'outline' || styleType === 'search') ? 'outlined' : 'standard'}
            placeholder={placeholder}
            label={''}
            autoFocus={autoFocus}
            onPaste={(e) => onPaste ? onPaste(e) : null}
            onCopy={(e) => onCopy ? onCopy(e) : null}
            onCut={(e) => onCut ? onCut(e) : null}
            onBlur={this.localOnBlur}
            onChange={(e) => {
              if (inputType === "number") {
                let numericValue = e.target.value.replace(/\D/g, '');
                onChangeValue(numericValue);
                return;
              }
              if (inputType === "decimal") {
                // Regular expression to allow numbers with up to 2 decimal places
                let numericValue = e.target.value.replace(/[^0-9.]/g, '') // Remove non-numeric and non-decimal point characters
                    .replace(/(\..*?)\..*/g, '$1') // Allow only one decimal point
                    .replace(/^(\d+)(\.\d{0,2})?.*/, '$1$2'); // Restrict to 2 digits after decimal
                onChangeValue(numericValue);
                return;
              }
              onChangeValue(e.target.value)
            }}
            onKeyDown={(ev) => {
              if (isHandleKeypadClick){
                if ((ev.key === 'Enter' || ev.key === 'Tab')) {
                    onEnter()
                }}
            }}
            onKeyPress={(e) => onKeyPress ? onKeyPress(e) : null}
            InputProps={{
              endAdornment: isClearable ? (<InputAdornment position="end">
             <span hidden={isEmpty(value) || isDisabled} id={id + '-input-clear'}
                   style={{
                     fontSize: '16px',
                     cursor: 'pointer',
                   }}
                   className={(isDisabled ? '' : 'cursor')}
                   onClick={() => {
                     if (isDisabled) {
                       return;
                     }
                     onChangeValue('');
                   }}>
                 <span>
                    <i className='fa fa-times'/>
                 </span>
            </span>
                {styleType === "search" && <span hidden={!isEmpty(value) || isDisabled} id={id + '-input-clear'}
                                                 style={{
                                                   fontSize: '16px',
                                                   cursor: 'pointer',
                                                 }}
                                                 className={(isDisabled ? '' : 'cursor')}>
                     <span>
                      <i className='fa fa-search default-icon'/>
                     </span>
                  </span>
                }
              </InputAdornment>) : null,
              ...InputProps,
            }}
            inputProps={{
              ...inputProps,
              maxLength: maxCharLength,
              min: minNumber,
              max: maxNumber,
              style: {
                ...getFontSize(fontSize),
                color: theme.appColor.black,
                fontWeight: isEmpty(value) ? 400 : 500,
                fontFamily: getFontFamily(),
                ...this.getInputHeight()
              },
            }}
            error={Boolean(error)}
            helperText={
              (<span className='d-flex justify-content-between' style={{minHeight: disableHelperMessageHeight ? '0' : '19px'}}>
                <span>{helperMessage}</span>
                {
                  ((!isEmpty(maxCharLength) && !hideCharLength) &&
                    <span hidden={hideCharLength}
                           className="d-flex justify-content-end" style={{color:theme.appColor.black}}>({value?.length}/{maxCharLength})</span>)
                }
              </span>)
            }
            type={inputType === 'number' ? 'text' : inputType}
            name={getRandomId(6)}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor : getBgColor(bgColor)
              }
            }}
            {...customProps}
          />
        </div>
      </ThemeProvider>
    );
  }
}


DLInputTextFieldView.propTypes = {};


export default DLInputTextFieldView;
