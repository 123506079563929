import React from 'react';
import ManagementDashboard from "./components/managementDashboard/ManagementDashboard";
import CRMDashboard from "./components/CRMDashboard/CRMDashboard";

const HomePageView = (props) => {
    const {isManagement, refreshButton, isXSView, isMobileView} = props;
    const renderHeader = () => {
        return (
            <div className={'page-header d-flex ' + ((isMobileView && !isXSView) ? ' py-2' : ' ')}>
                <div>Home</div>
                <div className='flex-grow-1  d-flex justify-content-end align-items-center'
                >
                    <i className="cursor fa-solid fa-rotate-right fa-xs me-1"
                       onClick={() => refreshButton()}
                    />
                </div>
            </div>
        )
    };

    const renderCRMTable = () => {
        return (
            <CRMDashboard
                {...props}
            />
        )
    };

    const renderManagementTable = () => {
        return (
            <ManagementDashboard
                {...props}
            />
        )
    };


    return (
        <div className='pb-5 bg-light'>
            <div className='w-100 pb-1'>{renderHeader()}</div>
            <div className='h-100'>
                {(isManagement) ? renderManagementTable() : renderCRMTable()}
            </div>
        </div>
    );
};

export default HomePageView;