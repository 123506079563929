import React from 'react';

import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLText from "../../../../../../core/components/DLText/DLText";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";

const RemoveNFTDetailsView = (props) => {
    const {
        history, isRemoveModalOpen, handleCloseRemoveModal,
        isApiInProgress, removeNFT, selectedName,
    } = props;

    const renderRemoveModalContent = () => (
        <div>
            <div className='text-center'>
                <DLText
                    id={'remove-confirmation'}
                    text={`Are you sure you want to discard this request for ${selectedName}?`}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                />
            </div>
        </div>
    );

    const renderRemoveModal = () => (
        <DLModal
            history={history}
            maxWidth={"sm"}
            onClose={handleCloseRemoveModal}
            open={isRemoveModalOpen}
            id={'remove-nft-modal'}>
            <DLModalTitle
                id={'remove-nft-title'}
                onClose={handleCloseRemoveModal}
                title={'Confirmation'}
            />
            <DLModalContainer id={'remove-nft-container'}>
                {renderRemoveModalContent()}
            </DLModalContainer>
            <DLModalActions id={'remove-nft-actions'}>
                <div className='mb-4' style={{ paddingTop: '7px' }}>
                    <DLButton
                        id={'remove-nft-cancel-btn'}
                        history={history}
                        label={'Cancel'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={handleCloseRemoveModal}
                    />
                    <DLButton
                        id={'remove-nft-discard-btn'}
                        history={history}
                        label={'Discard'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        sideMargin={"sm"}
                        type={"danger"}
                        onClick={removeNFT}
                    />
                </div>
            </DLModalActions>
        </DLModal>
    );

    const renderLoader = () => <DLLoader type={"screen"} isVisible={isApiInProgress} />;

    return (
        <div>
            {renderLoader()}
            {renderRemoveModal()}
        </div>
    );
};

export default RemoveNFTDetailsView;
