import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import appTheme from "../../assets/appTheme";

const MultiselectDropdown = (props) => {
    const {statusFilterOptions, isXSView, handleChange, selectedStatus, bgColor} = props;

    const getInputValue = (selectedStatus) => {
        if (selectedStatus?.length > 0) {
            return selectedStatus.length + ' Selected'
        }
        return 'All'
    }

    const getBgColor = (fontColor) => {
        switch (fontColor) {
            case 'primary':
                return appTheme?.appColor?.primary;
            case 'secondary':
                return appTheme?.appColor?.secondary;
            case 'tertiary':
                return appTheme?.appColor?.tertiary;
            case 'success':
                return appTheme?.appColor?.success;
            case 'successLight':
                return appTheme?.appColor?.successLight;
            case 'danger':
                return appTheme?.appColor?.danger;
            case 'darkBlue':
                return appTheme?.appColor?.darkBlue;
            case 'lightBlue':
                return appTheme?.appColor?.lightBlue;
            case 'grayLight':
                return appTheme?.appColor?.grayLight;
            case 'grayDark':
                return appTheme?.appColor?.grayDark;
            case 'gray':
                return appTheme?.appColor?.gray;
            case 'white':
                return appTheme?.appColor?.white;
            case 'disable':
                return appTheme?.appColor?.disable;
            case 'black':
                return appTheme?.appColor?.black;
            case 'transparent':
                return "";
            default:
                return appTheme?.appColor?.white;
        }
    };

    const fontSize = '14px';
    return (
        <div>
            <FormControl className={"w-100 multi-select-dropdown-input"}>
                <Select
                    id="demo-multiple-checkbox"
                    multiple
                    displayEmpty
                    value={selectedStatus}
                    onChange={handleChange}
                    input={<OutlinedInput label={getInputValue(selectedStatus)}/>}
                    renderValue={() => getInputValue(selectedStatus)}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: isXSView ? 160 : 200,
                                fontFamily: "'Poppins', sans-serif"
                            },
                        }
                    }}
                    size="small"
                    sx={{
                        fontSize,
                        fontFamily: "'Poppins', sans-serif",
                        backgroundColor: getBgColor(bgColor)
                    }}
                >
                    {statusFilterOptions.map(({label, value}) => (
                        <MenuItem
                            key={value}
                            value={value}
                            sx={{
                                fontSize,
                                padding: '0px 16px',
                                fontFamily: "'Poppins', sans-serif",
                            }}
                        >
                            <Checkbox checked={selectedStatus?.includes(value)}/>
                            <ListItemText primary={label} sx={{fontSize, fontFamily: 'Poppins'}}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default MultiselectDropdown;
