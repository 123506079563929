export const statusFilterOptions = [
    {label: 'Open', value: 'OPEN'},
    {label: 'In Progress', value: 'IN_PROGRESS'},
    {label: 'Completed', value: 'COMPLETED'},
];

export const headingData = [
    'Account', 'Category','Ticket Number', 'Status', 'Created Date'
]

export const headingMobileData = [
    'Account', 'Status & Created Date',
]

export const managementHeaderData = [
    'Account', 'Category', 'CRM & Location','Ticket Number', 'Status', 'Created Date',
]

export const cellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    }
];



